import React, { useEffect, useState } from 'react'
import "./phase.less"
import Loader from '../../../../components/Loader'
import CustomAlert from '../../../../components/CustomAlert'
import { Tabs, Tab, Grid } from "@mui/material";
import Select, { components } from "react-select";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation, useParams } from 'react-router-dom';
import { clearMessage, GetStudentPlanId, GetStudentPlanIdStatus, resetPlanIdData, getStudentToStudentDetail, CreateCourseStatus, CreateActivityStatus, activityRejectStatus, EditCourseStatus, getPhasetime, CreateActivityErrorStatus, CreateCourseErrorStatus, EditActivityStatus, DeleteCourseStatus, courseRejectStatus, courseDropConfirmationStatus, courseDropErrorStatus, activityApproveStatus, activityDropConfirmationStatus, DeleteActivityStatus, updatesCount } from "../../../../redux/actions";
import { connect } from "react-redux";
import PlanDetailSection from "./PhaseSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
      {value === index && (
        <Box p={3}>{children}</Box>
      )}
    </Typography>
  );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
  return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
// Select Styles
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
      {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
    </ValueContainer>
  );
};
const Index = (props) => {
  const [loading, setloading] = useState(false);
  const storedTabValue = JSON.parse(sessionStorage?.getItem('tabvalue'));
  const initialTabIndex = storedTabValue !== null
    ? storedTabValue
    : props?.getplanid?.phases?.findIndex(
      (phase) => phase.id === props?.getplanid?.planInfo?.activePhaseId
    );
  const [tabs, setTabs] = useState(initialTabIndex);
  const [selectedOption, setSelectedOption] = useState([]);
  const [Menulists, setMenulists] = useState([]);
  const location = useLocation();
  const id = location?.pathname?.split('/').pop();
  const { studentid } = useParams();

  const goback = (e) => {
    e.preventDefault();
    window?.history?.go(-1);
  }

  useEffect(() => {
    if (id) {
        let plansId = id;
        if (plansId !== "") {
            setloading(true);
            props?.getPhasetime(plansId);

            let payloadCount = {
                studentId: localStorage.getItem("parentViaChild"),
                advisosrId: null,
                milestoneUpdates: null,
                planId: plansId,
            };
            props?.updatesCount(payloadCount);

            if (window.location.pathname.includes("mywork/student")) {
                let payload = {
                    planId: plansId,
                    sourceUserId: localStorage.getItem("parentViaChild"),
                    targetUserId: studentid,
                };
                props?.getStudentToStudentDetail(payload);
            } else {
                props?.GetStudentPlanId(plansId);
            }
        }
    }

    // Cleanup function to reset data
    return () => {
        props?.resetPlanIdData(); // Reset the student plan data
     
    };
}, [id]);


  useEffect(() => {
	  if (props?.getplanidstatus && props?.getplanid?.phases?.length > 0) {
		  setTabs(props?.getplanid?.phases?.findIndex(phase => phase?.id == props?.getplanid?.planInfo?.activePhaseId) >= 0 ? props?.getplanid?.phases?.findIndex(phase => phase?.id == props?.getplanid?.planInfo?.activePhaseId) : 0)
	   
		  props?.GetStudentPlanIdStatus(false);
      setloading(false);
    }
    if (props?.getplanid) {
      const value = props?.getplanid?.phases?.findIndex(
        (phase) => phase.id === props?.getplanid?.planInfo?.activePhaseId
      );
      setTabs(storedTabValue ? storedTabValue : value);
  
      const activePhaseId = props?.getplanid?.planInfo?.activePhaseId;
      const newMenuLists = props.getplanid.phases?.map(phase => ({
        value: phase.id,
        label: phase.title
      }));
      setMenulists(newMenuLists);
  
      const initialSelectedOption = newMenuLists?.find(phase => phase.value === activePhaseId);
      setSelectedOption(initialSelectedOption);
    }    
  }, [props?.getplanidstatus])

  const handleSwitchTabs = (event, newValue) => {
    setTabs(newValue);
    sessionStorage?.setItem('tabvalue', JSON?.stringify(newValue));
  }
  // Tabs Change
  function findOptionByValue(value) {
    return Menulists.find((option) => option.value === value);
  }
  const handleSelectChange = (e) => {
    sessionStorage.setItem('tabvalue', JSON.stringify(e.value));
    setTabs(e);
    setSelectedOption(e);
  }

  useEffect(() => {
    if (props?.isCourseCreated || props?.isEditCourse) {
      props?.GetStudentPlanId(id)
      props?.CreateCourseStatus(false)
      props?.EditCourseStatus(false)
    }
    if (props?.isCourseError) {
      props?.CreateCourseErrorStatus(false)
    }
    if (props?.isCourseApprove) {
      props?.GetStudentPlanId(id)
    }
    if (props?.isCourseRejected) {
      props?.GetStudentPlanId(id)
      props?.courseRejectStatus(false)
    }
    if (props?.isactivityRejected) {
      props?.activityRejectStatus(false)
      props?.GetStudentPlanId(id)

    }
    if (props?.isCourseDeleted) {
      props?.DeleteCourseStatus(false)
      props?.GetStudentPlanId(id)
    }
    if (props?.isActivityDeleted) {
      props?.DeleteActivityStatus(false)
      props?.GetStudentPlanId(id)
    }
    if (props?.courseDropStatus) {
      props?.courseDropConfirmationStatus(false)
      props?.GetStudentPlanId(id)
    }
    if (props?.courseDropError) {
      props?.courseDropErrorStatus(false)
      props?.GetStudentPlanId(id)

    }
    if (props?.isActivityAproved) {
      props?.activityApproveStatus(false)
      props?.GetStudentPlanId(id)
    }
    if (props?.activityDropStatus) {
      props?.activityDropConfirmationStatus(false)
      props?.GetStudentPlanId(id)

    }
    if (props?.booksessionerror) {
      setloading(false)
    }
  }, [props?.isCourseCreated, props?.booksessionerror, props?.isCourseError, props?.isEditCourse, props?.isCourseApprove, props?.isCourseRejected, props?.activityDropStatus, props?.isactivityRejected, props?.isCourseDeleted, props?.isActivityDeleted, props?.courseDropStatus, props?.courseDropError, props?.isActivityAproved])

  useEffect(() => {
    if (props?.isActivityCreated || props?.isEditActivity) {
      props?.GetStudentPlanId(id)
      props?.CreateActivityStatus(false)
      props?.EditActivityStatus(false)
    }
    if (props?.isActivityError) {
      props?.CreateActivityErrorStatus(false)
    }
  }, [props?.isActivityCreated, props?.isActivityError, props?.isEditActivity])

  return (
    <>
      {loading && <Loader />}
      <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
      <div className='phase_page'>
        <div className="phase_page_Top">
          <div className="phase_page_Top_Desktop">
            <div className="phase_page_Top_Desktop_Title">
              <p>
                <span style={{ "marginRight": "20px", "cursor": "pointer" }} onClick={(e) => goback(e)}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8333 12.5002C20.8333 11.9249 20.3669 11.4585 19.7916 11.4585H5.20825C4.63295 11.4585 4.16658 11.9249 4.16658 12.5002C4.16658 13.0755 4.63295 13.5418 5.20825 13.5418H19.7916C20.3669 13.5418 20.8333 13.0755 20.8333 12.5002Z" fill="#1B1C1E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2367 4.4716C12.8299 4.0648 12.1703 4.0648 11.7635 4.4716L4.47184 11.7633C4.06505 12.1701 4.06505 12.8296 4.47184 13.2364L11.7635 20.5281C12.1703 20.9349 12.8299 20.9349 13.2367 20.5281C13.6434 20.1213 13.6434 19.4617 13.2367 19.0549L6.68155 12.4998L13.2367 5.94474C13.6434 5.53794 13.6434 4.8784 13.2367 4.4716Z" fill="#1B1C1E" />
                  </svg>
                </span>
                {props?.getplanid?.planInfo?.planName ? props?.getplanid?.planInfo?.planName : "Your Work"}
              </p>
            </div>
          </div>
          <div className="phase_page_Top_Mobile">
            <div className="phase_page_Top_Mobile__Section">
              <div className="phase_page_Top_Mobile__Section_Title">
                <p>Your Work</p>
              </div>
            </div>
            <div className="phase_page_Top_Mobile_Btncnt" style={{ marginTop: '10rem' }}>
              <p>
                <span style={{ "marginRight": "20px", "cursor": "pointer" }} onClick={(e) => goback(e)}>
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8333 12.5002C20.8333 11.9249 20.3669 11.4585 19.7916 11.4585H5.20825C4.63295 11.4585 4.16658 11.9249 4.16658 12.5002C4.16658 13.0755 4.63295 13.5418 5.20825 13.5418H19.7916C20.3669 13.5418 20.8333 13.0755 20.8333 12.5002Z" fill="#1B1C1E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2367 4.4716C12.8299 4.0648 12.1703 4.0648 11.7635 4.4716L4.47184 11.7633C4.06505 12.1701 4.06505 12.8296 4.47184 13.2364L11.7635 20.5281C12.1703 20.9349 12.8299 20.9349 13.2367 20.5281C13.6434 20.1213 13.6434 19.4617 13.2367 19.0549L6.68155 12.4998L13.2367 5.94474C13.6434 5.53794 13.6434 4.8784 13.2367 4.4716Z" fill="#1B1C1E" />
                  </svg>
                </span>
                {props?.getplanid?.planInfo?.planName ? props?.getplanid?.planInfo?.planName : "Your Work"}
              </p>
            </div>
          </div>
        </div>
		
        {props?.getplanid?.phases?.length > 0 &&
          <>
            <div className="phase_page_Mid">
              <div className="phase_page_Mid_Desktop">
                <div className="phase_page_Mid_Desktop_TabCnt">
                  <Tabs value={tabs} onChange={handleSwitchTabs}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example">
                    {props?.getplanid?.phases?.length > 0 && props?.getplanid?.phases?.map((phase, index) => {
                      if (tabs == index) {
                        return (
                          <Tab wrapped key={phase.id} label={phase?.title} disableRipple className="Tab" />
                        )
                      } else {
                        return (
                          <Tab wrapped key={phase.id} label={phase?.title} disableRipple className="Tab" />
                        )
                      }
                    })}
                  </Tabs>
                </div>
              </div>
              <div className="phase_page_Mid_Mobile" style={{ marginTop: '2rem' }}>
                <div className="input-floating-label">
                  <Select onChange={handleSelectChange}
                    options={props?.getplanid?.phases.map(phase => ({
                      label: phase.title,
                      value: phase.id
                    }))}
                    value={props?.getplanid?.phases[tabs] ? { label: props.getplanid.phases[tabs]?.title, value: props.getplanid.phases[tabs]?.id } : { label: tabs?.label, value: tabs?.value }}
                    getOptionLabel={e => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {e.icon}
                        <span style={{ marginLeft: 10 }}>{e.label}</span>
                      </div>
                    )}
                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                  />
                </div>
              </div>
            </div>
            <div className="phase_page_Btm">
              <TabPanel className="TabPanels">
                <PlanDetailSection phaseData={props.getplanid?.phases?.[tabs]} phasename={props?.getplanid?.phases?.[tabs]?.title} planid={props.getplanid?.planInfo?.[tabs]} subscribedAdvisor={props.getplanid?.subscribedAdvisors} subscribedStudent={props.getplanid?.subscribedStudents} planName={props?.getplanid?.planInfo?.planName} />
              </TabPanel>
            </div>
          </>
        }
      </div>
    </>
  )
}

const mapStateToProps = ({ studentData, commonData, }) => {
  const { booksessionerror } = commonData
  const { getplanid, getplanidstatus, isCourseCreated, isCourseError, isActivityCreated, isEditActivity, isEditCourse, isCourseApprove, activityDropStatus, isActivityAproved, isCourseRejected, isactivityRejected, isCourseDeleted, isActivityDeleted, courseDropStatus, courseDropError } = studentData;
  return {
    getplanid, getplanidstatus, isCourseCreated, isCourseError, isActivityCreated, isEditActivity, isEditCourse, isCourseApprove, isActivityAproved, isCourseRejected, isactivityRejected, isCourseDeleted, isActivityDeleted, courseDropStatus, courseDropError,
    activityDropStatus, booksessionerror
  }
};

export default connect(mapStateToProps, {
  clearMessage, GetStudentPlanId, GetStudentPlanIdStatus, CreateCourseStatus, EditCourseStatus, CreateActivityStatus, getPhasetime, CreateActivityErrorStatus, CreateCourseErrorStatus, EditActivityStatus, getStudentToStudentDetail, DeleteCourseStatus, activityRejectStatus,
  courseRejectStatus, resetPlanIdData, courseDropConfirmationStatus, courseDropErrorStatus, activityApproveStatus, activityDropConfirmationStatus, DeleteActivityStatus, updatesCount
})(Index);
