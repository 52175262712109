import React, { useEffect, useState, useRef } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimeLineCard from "../TimeLineCard";
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { DeleteMileStoneNotes, GetMilestoneByActivity, GetMilestoneByCourse, DeleteMileStoneNotesStatus, replyNotes, ReplyNotesStatus } from "../../../redux/actions"
import Loader from "../../../components/Loader";

const Index = (props) => {
    const params = useParams();
    const { state } = useLocation()
    const [itemsToShow, setItemsToShow] = useState(10);
    const additionalItemsToLoad = 10;
    const timelineContainerRef = useRef(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            const timelineContainer = timelineContainerRef.current;
            if (timelineContainer.scrollTop === 0) {
                setItemsToShow(10); // Reset to show only 10 items when scrolling to the top
            } else if (
                timelineContainer.scrollTop + timelineContainer.clientHeight >=
                timelineContainer.scrollHeight
            ) {
                clearTimeout(timeoutId); // Clear previous timeout to avoid multiple updates
                timeoutId = setTimeout(() => {
                    setItemsToShow(itemsToShow + additionalItemsToLoad);
                }, 600); // Adjust the delay time (in milliseconds) as needed
            }
        };

        const timelineContainer = timelineContainerRef.current;
        timelineContainer.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timeoutId); // Clear the timeout when component unmounts
            timelineContainer.removeEventListener('scroll', handleScroll);
        };
    }, [itemsToShow]);

    useEffect(() => {
        if (props?.isMileStoneCourseStatus) {
            setLoading(false);
        }
        if (props?.isactivityMilestoneStatus) {
            setLoading(false);
        }
        if (props?.isReplyNotes) {
            props?.ReplyNotesStatus(false);
            setLoading(false);
        }
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false);
            setLoading(false);
        }
    }, [props?.isMileStoneCourseStatus, props?.isactivityMilestoneStatus, props?.isUpdateNotes, props?.isReplyNotes,props?.deleteNotes])


    const handleDelete = (event, data) => {
        event.stopPropagation();
        console.log(data, "data");
        let payload = {
            milestoneStatusId: data?.id,
            planId: props?.userProfile?.currentRole == "advisor" ? params?.planId : params.id,
            milestoneId: props?.mileStoneData?.id,
            updateContext: ""
        }
        setLoading(true)
        props?.DeleteMileStoneNotes(payload)
    }

    const handleAddReply = (data) => {
        const datas = {
            ...data,
            studentId: localStorage?.getItem("parentViaChild") 
        };
        console.log(data, "dbfjbfjdbfj")
        props?.replyNotes(datas);
        setLoading(true);
    }

    return (
        <div className="Timelinecontainer" ref={timelineContainerRef}>
            {!loading ? (
                <Timeline position={screenWidth <= 768 ? "right" : "alternate"}>
                    {props?.data?.slice(0, itemsToShow).map((updates, index) => {
                        return (
                            <React.Fragment key={index}>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent className="timeLineContent">
                                        <TimeLineCard id={index} data={updates}
                                            mileStoneData={props?.mileStoneData}
                                            deleteUpdate={(e) => handleDelete(e, updates)}
                                            handleAddReply={handleAddReply}
                                            StudentView={props?.StudentView}
                                        />
                                    </TimelineContent>
                                </TimelineItem>
                                {index === itemsToShow - 1 && itemsToShow < props?.data?.length && (
                                    <div style={{ "display": "flex", "justifyContent": "center" }}>
                                        <CircularProgress size={30} thickness={3} />
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Timeline>
            ) : (
                <Loader />
            )}
        </div>
    );
};

const mapStateToProps = ({ studentData, userProfileData }) => {
    const { userProfile } = userProfileData;
    const { deleteNotes, isMileStoneCourseStatus, isactivityMilestoneStatus, isUpdateNotes, isReplyNotes } = studentData;
    return { userProfile, deleteNotes, isMileStoneCourseStatus, isactivityMilestoneStatus, isUpdateNotes, isReplyNotes };
};

export default connect(mapStateToProps, { DeleteMileStoneNotes, GetMilestoneByActivity, GetMilestoneByCourse, DeleteMileStoneNotesStatus, replyNotes, ReplyNotesStatus })(Index);
