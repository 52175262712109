import React, { useState, useEffect } from "react";
import "../../../../assets/css/editPlan.less"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MileStoneTab from "./MileStoneTab";
import AddMilestone from "../../../../componentsNew/Common/addMilestone"
import { Box, Grid, Tab, Tabs, } from "@mui/material";
import {
    DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus, DeleteMileStoneResourceStatus,
    GetMilestoneByCourse, GetMilestoneByActivity, GetMileStoneByCourseStatus, GetMileStoneByActivityStatus,
    createMilestonforCourseStatus, viewDashboardCount, resetCourseMilestoneData
} from "../../../../redux/actions";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { connect } from "react-redux";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomAlert from "../../../../components/CustomAlert";
import Loader from "../../../../components/Loader"
import Tooltip from '@mui/material/Tooltip';
import "./milesection.less"
import Rating from '@mui/material/Rating';
import FeedBackPopup from "../../../../components/FeedBackPopup";
import Select, { components } from "react-select";
import { Button } from "react-bootstrap";


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            className="Advisor-MyNetwork__tabs-sec"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="card-sec">
                    {children}
                </Box>
            )}
        </div>
    );
}

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const navigate = useNavigate();
    const [showChangeHistory, setshowChangeHistory] = useState(false)
    const [key, SetKey] = useState("Milestone1")
    const [milestoneshow, setmilestoneshow] = useState(false)
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(4);
    const [feedBack, setFeedBack] = useState(false);
    const [studentId, setStudentId] = useState(null);
    const [newMiles, setnewMiles] = useState(false);

    useEffect(() => {
        const Url = window.location.pathname;
        const pathParts = Url.split('/');
        if (pathParts[3] === 'student' && pathParts[4]) {
            setStudentId(pathParts[4]);
        } else {
            setStudentId(null);
        }
        setLoading(true);

    }, []);

    useEffect(() => {
        return () => {
            props?.resetCourseMilestoneData()
        }
    }, [])

    const location = useLocation();

    const params = useParams();
    const { state } = useLocation();

    const changeTab = (event, newValue) => {
        const selectedMilestone = props?.isCourseMileStone?.[newValue];
        const milestoneId = selectedMilestone?.id;
        let payloadCount = {
            // "postedBy": props?.data?.id,
            "updateType": "milestone",
            "courseId": state?.courseData?.id || params?.courseId,
            "planId": params.id,
            "milestoneId": milestoneId
        };
        props?.viewDashboardCount(payloadCount);
        setTab(newValue);
    };
    const PhaseData = state?.courseData;

    useEffect(() => {
        const milesTab = props?.isCourseMileStone.findIndex(milestone => milestone.id === state?.milestoneData?.id);
        const tabIndex = milesTab === -1 ? 0 : milesTab;
        if (!isNaN(tabIndex) && tabIndex >= 0) {
            setTab(tabIndex);
        } else {
            setTab(0);
        }
    }, [state]);

    const showFeedBack = (e) => {
        e.preventDefault();
        setFeedBack(true)
    }

    const handleSelectChange = (selectedOption) => {
        const selectedIndex = props?.isCourseMileStone.findIndex(milestone => milestone.id === selectedOption.value);
        setTab(selectedIndex);
        // setSelectedOption(e);
         const selectedMilestone = props?.isCourseMileStone?.[selectedIndex];
        const milestoneId = selectedMilestone?.id;
        let payloadCount = {
            // "postedBy": props?.data?.id,
            "updateType": "milestone",
            "courseId": state?.courseData?.id || params?.courseId,
            "planId": params.id,
            "milestoneId": milestoneId
        };
        props?.viewDashboardCount(payloadCount);
    }

    useComponentDidMount(() => {
        let payload = {
            "planId": params.id,
            "courseId": state?.courseData?.id || params?.courseId,
            studentId: localStorage?.getItem("parentViaChild")
        }
        props?.GetMilestoneByCourse(payload)
      

    }, [])

    const openChangeHistory = (value) => {
        setshowChangeHistory(value)
    }
    const goback = (e) => {
        e.preventDefault();
        navigate(-1)
    }
    const setKey = (k) => {
        SetKey(k)
    }
    const Openmilestone = () => setmilestoneshow(true);
    const Closemilestone = () => setmilestoneshow(false);
    const handleClick = () => {
        Closemilestone()
    };

    useComponentDidUpdate(() => {
        if (props.createCourseMilestoneStatus) {
            props.createMilestonforCourseStatus(false)

            let payload = {
                "planId": params.id,
                "courseId": state?.courseData?.id || params?.courseId,
                studentId: localStorage?.getItem("parentViaChild")
            }
            props?.GetMilestoneByCourse(payload)
            Closemilestone();
            setnewMiles(true)

        }
    }, [props.createCourseMilestoneStatus])

    useComponentDidMount(() => {
        setLoading(true)
    }, [])

    useComponentDidUpdate(() => {
        if (props?.isCourseMileStone?.length >= 0) {
            setLoading(false)
            let payloadCount = {
                // "postedBy": props?.data?.id,
                "updateType": "milestone",
                "courseId": state?.courseData?.id || params?.courseId,
                "planId": params.id,
                "milestoneId": props?.isCourseMileStone && props?.isCourseMileStone[0]?.id

            };
            props?.viewDashboardCount(payloadCount);
        }
      
    }, [props?.isCourseMileStone])

    useEffect(() => {
        let payload = {
            "planId": location?.state?.courseData?.planId,
            "courseId": location?.state?.courseData?.id,
            studentId: localStorage?.getItem("parentViaChild")
        }
        if (props?.isDeleteResource) {
            props?.DeleteMileStoneResourceStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        // if (props?.isMileStoneCourseStatus) {

        //     setLoading(false);
        //     props?.GetMileStoneByCourseStatus(false);
        // }
        if (props?.isMileStoneCourseStatus && props?.isCourseMileStone) {
            const lastIndex = props?.isCourseMileStone?.length - 1;
            const newMenuLists = props?.isCourseMileStone && props?.isCourseMileStone?.map(course => (
                { value: course.id, label: course.milestoneTitle }
            ));
            // setMenulists(newMenuLists);
            // const initialSelectedOption = newMenuLists[0];
            // setSelectedOption(initialSelectedOption);
            if (newMiles) {
                setTab(lastIndex);
                setnewMiles(false);
            }
            props?.GetMileStoneByCourseStatus(false);
        }
        if (props?.isUpdateNotes) {
            props?.CreateNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isReplyNotes) {
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.viewedUpdate) {
            props?.updateReadUpdateStatus(false)
            props?.GetMilestoneByCourse(payload)
        }

    }, [props?.isMileStoneCourseStatus, props?.isDeleteResource, props?.deleteNotes, props?.isUpdateNotes, props?.isReplyNotes, props?.viewedUpdate])

    useEffect(()=>{
        if(location.state?.milestoneData?.milestoneTitle && location.state?.milestoneData?.id){
            const milestoneIndex = props?.isCourseMileStone?.findIndex(
                milestone => milestone?.id === location.state?.milestoneData?.id
            );
    
            if (milestoneIndex !== -1) {
                setTab(milestoneIndex); // Set the tab to the found index
            }
        }
        },[location.state,props?.isCourseMileStone])

    return (
        <>
            {loading && <Loader />}
            <FeedBackPopup size={"lg"}
                dialogClassName=""
                fullscreen={true}
                centered={false}
                showFeedBack={feedBack}
                // advisorDetail={props?.advisorDetail}
                onHide={() => setFeedBack(false)}
            // onsetFeedBack={(isfeedBack) => this.setFeedBack(isfeedBack)}
            />
            <div>
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
                <div className="mywork_Page">
                    <div className="planviewcnttopsec ">
                        <div className="mywork_Page_Top">
                            <div className="mywork_Page_Top_Desktop">
                                <div className="mywork_Page_Top_Desktop_Title">
                                    <p>  <span className="planbackbtn" onClick={(e) => goback(e)} >
                                        <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 8.00016C13.3333 7.63197 13.0349 7.3335 12.6667 7.3335H3.33334C2.96515 7.3335 2.66668 7.63197 2.66668 8.00016C2.66668 8.36835 2.96515 8.66683 3.33334 8.66683H12.6667C13.0349 8.66683 13.3333 8.36835 13.3333 8.00016Z" fill="#1B1C1E" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.47139 2.86177C8.21104 2.60142 7.78893 2.60142 7.52859 2.86177L2.86192 7.52843C2.60157 7.78878 2.60157 8.21089 2.86192 8.47124L7.52859 13.1379C7.78893 13.3983 8.21104 13.3983 8.47139 13.1379C8.73174 12.8776 8.73174 12.4554 8.47139 12.1951L4.27613 7.99984L8.47139 3.80458C8.73174 3.54423 8.73174 3.12212 8.47139 2.86177Z" fill="#1B1C1E" />
                                        </svg>
                                    </span> {" "}Course:{" "}{PhaseData?.courseName}</p>
                                </div>
                                <div className="mywork_Page_Top_Desktop_Btncnt">
                                    {props?.isCourseMileStone && props?.isCourseMileStone?.length > 0 && (
                                        <div className="milestone_Page_Top_Desktop_Btncnt mb-2">
                                            <Button disabled={true} className="milestone_Page_Top_Desktop_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                                        </div>
                                    )
                                    }
                                </div>

                            </div>
                            <div className="mywork_Page_Top_Mobile">
                                <div className="mywork_Page_Top_Mobile__Section">
                                    <div className="mywork_Page_Top_Mobile__Section_Title">
                                        <p>  <span className="planbackbtn" onClick={(e) => goback(e)} >
                                            <svg width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 8.00016C13.3333 7.63197 13.0349 7.3335 12.6667 7.3335H3.33334C2.96515 7.3335 2.66668 7.63197 2.66668 8.00016C2.66668 8.36835 2.96515 8.66683 3.33334 8.66683H12.6667C13.0349 8.66683 13.3333 8.36835 13.3333 8.00016Z" fill="#1B1C1E" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.47139 2.86177C8.21104 2.60142 7.78893 2.60142 7.52859 2.86177L2.86192 7.52843C2.60157 7.78878 2.60157 8.21089 2.86192 8.47124L7.52859 13.1379C7.78893 13.3983 8.21104 13.3983 8.47139 13.1379C8.73174 12.8776 8.73174 12.4554 8.47139 12.1951L4.27613 7.99984L8.47139 3.80458C8.73174 3.54423 8.73174 3.12212 8.47139 2.86177Z" fill="#1B1C1E" />
                                            </svg>
                                        </span> {" "}Course:{" "}{PhaseData?.courseName}</p>
                                    </div>
                                </div>
                                <div className="milestone_Page_Top_Desktop_Btncnt">
                                    <Button disabled={true} className="milestone_Page_Top_Desktop_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                                </div>

                            </div>
                        </div>

                        {props?.isCourseMileStone && props?.isCourseMileStone?.length > 0 ?
                            <>
                                <div className="mywork_Page">
                                    <div className="mywork_Page_Top_Desktop">
                                        <div className="phase_page_Mid_Desktop_TabCnt">
                                            <Box
                                                className="tab_card"
                                                sx={{
                                                    bgcolor: "#fff",
                                                    borderRadius: "10px",
                                                    border: "1px solid #CDCDCD",
                                                    marginInline: "auto",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Tabs
                                                    value={tab}
                                                    onChange={changeTab}
                                                    variant="scrollable"
                                                    scrollButtons
                                                    allowScrollButtonsMobile
                                                    aria-label="scrollable force tabs example"
                                                >
                                                    {props?.isCourseMileStone && props?.isCourseMileStone?.length > 0 && props?.isCourseMileStone.map((milestone) => (
                                                        <Tab
                                                            key={milestone?.id}
                                                            label={milestone?.milestoneTitle}
                                                            disableRipple
                                                        />
                                                    ))}
                                                </Tabs>
                                                {props?.isCourseMileStone && props?.isCourseMileStone?.length <= 25 && (
                                                    <Tooltip title="Create Milestone" placement="bottom">
                                                        {!studentId &&
                                                            <button className="tab_card__addMileStonebtn" onClick={Openmilestone}>
                                                                <AddOutlinedIcon sx={{ fontSize: "18px" }} />
                                                            </button>
                                                        }
                                                    </Tooltip>
                                                )
                                                }
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="phase_page_Mid_Mobile milestoneTab" style={{ marginTop: '1rem' }}>
                                        <div className="input-floating-label" style={{ marginBottom: '0px' }}>
                                            <Select onChange={handleSelectChange}
                                                options={props?.isCourseMileStone.map((milestone, index) => ({
                                                    label: milestone.milestoneTitle,
                                                    value: milestone.id,
                                                    index: index
                                                }))}
                                                value={props?.isCourseMileStone[tab] ? { label: props?.isCourseMileStone[tab]?.milestoneTitle, value: props?.isCourseMileStone[tab]?.id } : { label: tab?.label, value: tab?.value }}
                                                getOptionLabel={e => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {e.icon}
                                                        <span style={{ marginLeft: 10 }}>{e.label}</span>
                                                    </div>
                                                )}
                                                placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                            />
                                        </div>
                                        <button className="ml-1 mr-1 tab_card__addMileStonebtn" onClick={Openmilestone}>
                                            <AddOutlinedIcon sx={{ fontSize: "18px" }} />
                                        </button>

                                    </div>
                                    <div className="phase_page_Btm">
                                        <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                                            {
                                                props?.isCourseMileStone?.map((milestoneData, index) => {


                                                    return (
                                                        <TabPanel value={tab} index={index}>
                                                            <MileStoneTab data={milestoneData} />
                                                        </TabPanel>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </div>
                                </div>

                            </>
                            : (
                                !loading &&
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10} className="milestone_Page">
                                        <div className='MileStoneEmptyPage' >
                                            <div className='MileStoneEmptyPage__Card'>
                                                <div className='MileStoneEmptyPage__Card__Titlecnt'>
                                                    <p>You do not have created any Milestones for this Course</p>
                                                </div>
                                                {!studentId &&
                                                    <div className='MileStoneEmptyPage__Card__btncnt' onClick={Openmilestone}>
                                                        <p>Create Milestone</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            {milestoneshow &&
                <AddMilestone show={milestoneshow} onHide={() => Closemilestone()} handleClose={handleClick} />
            }

        </>
    );
}
const mapStateToProps = ({ studentData }) => {
    const { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus } = studentData;
    return { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus };
};

export default connect(mapStateToProps, { DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus, DeleteMileStoneResourceStatus, GetMilestoneByCourse, GetMilestoneByActivity, GetMileStoneByCourseStatus, GetMileStoneByActivityStatus, createMilestonforCourseStatus, viewDashboardCount, resetCourseMilestoneData })(Index);

