import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../../../../assets/css/New_Profile.less";
import { Grid, Box, Button } from "@mui/material";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { COUNTRY_NAME_LABEL, EMAIL_LABEL, FIRST_NAME_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, High_SCHOOL_LABEL, LAST_NAME_LABEL, MY_STATUS_LABEL, ZIP_CODE_LABEL } from "../../../../../Constants/commonLabels";
import Autocomplete from '@mui/material/Autocomplete';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../../components/Loader";
import { getUserProfile, updateUserProfile, UserProfileErrorStatus, getUserGoals, getUserGoalsStatus, setIsUserProfileSuccess, clearErrorList, clearMessage, resendVerifyEmail, setResendVerifyEmail } from "../../../../../redux/actions";
import { schoolApi, universityApi, loadZipcode } from "../../../../../utils/AutoComplete";

const validation = Yup.object({
    firstName: Yup.string().trim().required("*Required").min(2, "Minimum 2 characters required").max(50, "Minimum 50 characters Allowed").nullable(),
    lastName: Yup.string().trim().required("*Required").max(50, "Minimum 50 characters Allowed").nullable(),
    email: Yup.string().required("* Required").matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Please enter a valid email"),
    country: Yup.object().required("* Required").nullable(),
    zipCode: Yup.number().when('country', (country) => {
        if (country.value == 'US') {
            return Yup.string().matches(/^[0-9]{5}$/, 'Zipcode should be 5 digit').required("*Required")
        } else {
            return Yup.string().matches(/^[0-9]{6}$/, 'Must contain only 6 digits').required("*Required")
        }
    }),
    gender: Yup.object().required("* Required").nullable(),
    status: Yup.object().required("* Required").nullable(),
    graduationYear: Yup.object().required("* Required").nullable(),
});
const timezonesOptions = [
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Eastern Time', value: 'America/New_York' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Mountain Standard Time', value: 'America/Phoenix' },
    { label: 'Mountain Daylight Time', value: 'America/Denver' },
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
];

const Index = (props) => {
    const [GenderOption, setGenderOption] = useState();
    const [StatusOption, setStatusOption] = useState();
    const [Graduationyear, setGraduationyear] = useState();
    const [CountryOption, setCountryOption] = useState();
    const [SchoolOptions, setSchoolOptions] = useState();
    const [loading, setloading] = useState(false)
    const [highSchoolData, sethighSchoolData] = useState([]);
    const [selectSchoolOption, setselectSchoolOption] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({})
    const [Gpa, setGpa] = useState("");
    const [unweightedGpa, setunweightedGpa] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [statusrole, setStatusrole] = useState("")
    const [zipCodeOption, setZipCodeOption] = useState([])
    const [loadZipcodes, setLoadZipcodes] = useState(false);

    const { register, handleSubmit, watch, control, formState: { errors, isDirty, dirtyFields }, getValues, setValue, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    // Component did Mount
    useEffect(() => {
        setloading(true)
        props.getUserGoals()
    }, [])
    // Component Did Update
    useEffect(() => {
        if (props.isgetusergoalsstatus === true && props?.country != undefined && props?.status != undefined && props?.gender != undefined && props?.userProfile != undefined && props?.graduationyear != undefined) {
            setInitialValues()
        }
        if (props?.isProfileSuccessError === true) {
            setloading(false)
            props.UserProfileErrorStatus(false)
        }
        if (props.isProfileSuccess === true) {
            setloading(false)
            props.setIsUserProfileSuccess(false)
        }
    }, [props.country, props.status, props.gender, props.userProfile, props.graduationyear, props.isProfileSuccessError, props.isgetusergoalsstatus])

    useEffect(() => {
        if (props?.errorList) {
            setErrorList(props?.errorList)
        }
    }, [props?.errorList]);

    useEffect(() => {
        if (props?.isResendEmailSuccess) {
            setloading(false)
            props?.setResendVerifyEmail(false)
            props?.getUserProfile()
        }
    }, [props?.isResendEmailSuccess]);

    const loadZipcodeOptions = (value) => {
        if (value === "") {
            setZipCodeOption([]);
            return;
        }
        if (value) {
            setLoadZipcodes(true);
            let payload = {
                searchString: value,
                pageNo: 1,
                limit: 80,
            };
            loadZipcode(payload)
                .then((res) => {
                    const list = res?.data?.data.map((item) => ({
                        label: item.zip,
                        value: item.zip
                    }));
                    setZipCodeOption(list);
                    setLoadZipcodes(false);
                })
                .catch((error) => {
                    setZipCodeOption([]);
                    setLoadZipcodes(false);
                });
        }
    };
    const GetGpa = (e) => {
        setFieldErrors({})
        let value = e.target.value
        setErrorList([])
        if (e.target.value === "") {
            setGpa(null)
        }
        else {
            setGpa(value)
        }
    }
    const GetUnweightGpa = (e) => {
        setErrorList([])
        setFieldErrors({})
        let value = e.target.value
        if (e.target.value === "") {
            setunweightedGpa(null)
        }
        else {
            setunweightedGpa(value)
        }
    }
    const handleValidation = () => {
        if (!selectSchoolOption) {
            let newfieldErrors = { ...fieldErrors, ['highSchoolName']: "*Required" }
            setFieldErrors(newfieldErrors)
            return false
        }
        return true
    }
    const onValidationChange = (e, value) => {
        clearErrors()
        if (props?.errorList) {
            setErrorList([])
            props.clearErrorList()
            props.clearMessage()
        }
    }
    const handleGpaValidation = () => {
        let isValid = true
        if (props?.isgetusergoals && props?.isgetusergoals?.goals !== undefined) {
            let goalesData = props.isgetusergoals.goals
            if (goalesData && goalesData.length > 0) {
                goalesData && goalesData.map((data, key) => {
                    if (data == "weighted") {
                        if (Gpa === "") {
                            let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "*Required" }
                            setFieldErrors(newfieldErrors)
                            isValid = false
                        }
                        if (Gpa === null) {
                            let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "*Required" }
                            setFieldErrors(newfieldErrors)
                            isValid = false
                        }
                        if (Gpa !== null) {
                            const decimalcaseRegExp = (/^[1-5]\.\d{1,2}$/);
                            const digitcaseRegExp = (/^[1-5]$/);
                            const floatcasenumber = decimalcaseRegExp.test(Gpa);
                            const digitcasenumber = digitcaseRegExp.test(Gpa);
                            if (!digitcasenumber && !floatcasenumber) {
                                let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "Invalid GPA" }
                                setFieldErrors(newfieldErrors)
                                isValid = false
                            }
                        }
                    }
                })
            }
            return isValid
        }
        else {
            isValid = true
        }
    }
    const handleUnweightedGpaValidation = () => {
        let isValid = true
        if (props?.isgetusergoals && props?.isgetusergoals?.goals !== undefined) {
            let goalesData = props.isgetusergoals.goals
            if (goalesData && goalesData.length > 0) {
                goalesData && goalesData.map((data, key) => {
                    if (data == "unweighted") {
                        if (unweightedGpa === "") {
                            let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "*Required" }
                            setFieldErrors(newfieldErrors)
                            isValid = false
                        }
                        if (unweightedGpa === null) {
                            let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "*Required" }
                            setFieldErrors(newfieldErrors)
                            isValid = false
                        }
                        if (unweightedGpa !== null) {
                            const decimalcaseRegExp = (/^[1-4]\.\d{1,2}$/);
                            const digitcaseRegExp = (/^[1-4]$/);
                            const floatcasenumber = decimalcaseRegExp.test(Gpa);
                            const digitcasenumber = digitcaseRegExp.test(Gpa);
                            if (!digitcasenumber && !floatcasenumber) {
                                let newfieldErrors = { ...fieldErrors, ['CurrentGPA']: "Invalid GPA" }
                                setFieldErrors(newfieldErrors)
                                isValid = false
                            }
                        }
                    }
                })
            }
            return isValid
        }
        else {
            isValid = true
        }
    }
    const onSubmit = data => {
        // props.updateUserProfile(value)
        props.clearErrorList()
        let StudentProfile = props?.userProfile?.studentProfile
        let value = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            graduationYear: data.graduationYear.label,
            zipCode: data.zipCode,
            weighted: Gpa || null,
            unweighted: unweightedGpa || null,
            gender: data.gender.label,
            status: data.status.label,
            country: data.country.value,
            highSchoolName: selectSchoolOption,
            studentsCollab: StudentProfile.studentsCollab,
            counselingAllowed: StudentProfile.counselingAllowed,
            subCouncelingServices: StudentProfile.subCouncellingServices,
            helpWithCoursesAndActivities: StudentProfile?.helpWithCoursesAndActivities,
            helpWithMilestones: StudentProfile?.helpWithMilestones,
            timeZone: data.timeZone?.value
        }
        if (handleValidation()) {
            props.updateUserProfile(value, "profile")
            setloading(true)
        }

    }
    const loadOptions = (value, callback) => {
        if (value === "") {
            sethighSchoolData([])
        }
        let list = []
        if (value.trim() !== "") {
            schoolApi(value).then((res) => {
                res?.data?.data.map((item, index) => {
                    list.push({
                        label: item.school,
                        value: item.school
                    });

                });
                sethighSchoolData(list)
            })
        }
    }
    const UniversityloadOptions = (value, callback) => {
        if (value.trim() === "") {
            sethighSchoolData([])
            return
        }
        let list = []
        if (value) {
            universityApi(value).then((res) => {
                res?.data?.data?.map((item, index) => {
                    list.push({
                        label: item.university,
                        value: item.university
                    });
                });
                sethighSchoolData(list)
            })
        }
    }
    const onTagsChange = (event, values) => {
        setFieldErrors({})
        setselectSchoolOption(values)

    }
    const onStatusChange = (selectedOption) => {
        setStatusrole(selectedOption.role)
        setselectSchoolOption(undefined)
    };
    const setInitialValues = () => {
        let genderOptions = [];
        let statusOptions = [];
        let countryOptions = [];
        let graduationOptions = [];
        let SchoolOptions = [];
        let TimezoneOptions = [];

        props?.gender !== undefined && props?.gender?.length > 0 && props?.gender?.map((x, key) => {
            var temp = { label: x, value: key }
            genderOptions.push(temp);
            setGenderOption(genderOptions)
        });
        props?.status !== undefined && props?.status.length > 0 && props?.status.filter((x) => x.role !== "professional").map((x, key) => {
            var temp = { label: x.status, value: x.id, role: x.role }
            statusOptions.push(temp);
            setStatusOption(statusOptions)
        });
        props?.country !== undefined && props?.country?.length > 0 && props.country.map((x, key) => {
            var temp = { label: x.countryName, value: x.isoCode2Digit }
            countryOptions.push(temp);
            setCountryOption(countryOptions)
        })
        props?.graduationyear !== undefined && props?.graduationyear?.length > 0 && props.graduationyear.map((x, key) => {
            var temp = { label: x, value: x }
            graduationOptions.push(temp);
            setGraduationyear(graduationOptions)
        })
        props?.highschoollist !== undefined && props?.highschoollist.length > 0 && props?.highschoollist.map((x, key) => {
            var temp = { label: x.school, value: x.school }
            SchoolOptions.push(temp);
            setSchoolOptions(SchoolOptions)
        })

        let UserProfile = props?.userProfile
        let StudentProfile = props?.userProfile?.studentProfile

        countryOptions = props?.country.map((countryItem) => { return { label: countryItem.countryName, value: countryItem.isoCode2Digit } }
        ).find((x) => x.value === UserProfile.country)

        genderOptions = props?.gender.map((genderItem) => { return { label: genderItem, value: genderItem } }
        ).find((x) => x.value === UserProfile.gender)

        statusOptions = props?.status.map((statusItem) => { return { label: statusItem.status, value: statusItem.status, role: statusItem?.role } }
        ).find((x) => x.value === StudentProfile.myStatus)

        graduationOptions = props?.graduationyear.map((gradItem) => { return { label: gradItem, value: gradItem } }
        ).find((x) => x.value === StudentProfile.graduationYear)

        TimezoneOptions = timezonesOptions.map((zoneItem) => { return { label: zoneItem.label, value: zoneItem.value } }
        ).find((x) => x.value === UserProfile.timezone)

        setValue('firstName', UserProfile.firstName)
        setValue('lastName', UserProfile.lastName)
        if(UserProfile?.temporaryEmail){
            setValue('email',UserProfile?.temporaryEmail)
        }else{
            setValue('email',UserProfile?.email)
        }
        setValue('zipCode', UserProfile.zipCode)
        setValue('timeZone', TimezoneOptions)
        setValue('country', countryOptions)
        setValue('gender', genderOptions)
        setValue('status', statusOptions)
        setValue('graduationYear', graduationOptions)
        setselectSchoolOption(StudentProfile.currrentSchoolName)
        setGpa(StudentProfile.weighted)
        setunweightedGpa(StudentProfile.unweighted)
        props.getUserGoalsStatus(false)
        setloading(false)
        clearErrors()
    }
    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
                {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
            </ValueContainer>
        );
    };
    let goalesData = props?.isgetusergoals?.goals

    const handleResendEmail = (e, data) => {
        setloading(true)
        e.preventDefault()
        props?.resendVerifyEmail()
    }

    return (
        <>
            {loading && <Loader />}
            <div className="General_Form_Card">
                <p className="General_Form_Title">Basic Info</p>
                <div className="General_Forms">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <div className="input-floating-label">
                                    <input refs="firstName" id="firstName" type="text" {...register("firstName")} onChange={(e) => { onValidationChange(e, "firstName"); }} className={"textbox--primary textbox--rounded input"} name="firstName" placeholder=" " />
                                    <label>{FIRST_NAME_LABEL}</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors.firstName?.message}</div>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errorList.firstName}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <div className="input-floating-label">
                                    <input refs="lastName" id="lastName" type="text" {...register("lastName")} onChange={(e) => { onValidationChange(e, "lastName"); }} className={"textbox--primary textbox--rounded input"} name="lastName" placeholder=" " />
                                    <label>{LAST_NAME_LABEL}</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors.lastName?.message}</div>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errorList.lastName}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <div className="input-floating-label">
                                    <input refs="email" id="email" type="email" name="email" {...register('email')} className={"textbox--primary textbox--rounded input"} placeholder=" " />
                                    <label>{EMAIL_LABEL}</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.email?.message}</div>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errorList?.email}</div>
                                    {props?.userProfile?.temporaryEmail &&
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-7 col-lg-9 col-xl-9">
                                                <p className="cta--text mb-2" style={{ "fontSize": "14px", "marginLeft": "15px", "color": "#E64A19", "marginBottom": "0px" }}>
                                                    Please check your email to confirm this address. {props?.userProfile?.email} will be used until confirmed
                                                </p>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                                <a onClick={(e) => handleResendEmail(e, props?.userProfile?.email)} className="profile_pill_btns">Resend Email</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <Controller name="country" control={control}
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={COUNTRY_NAME_LABEL} id="country" {...field} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={CountryOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            <div className="error-text" style={{ "position": "absolute" }} >{errors.country?.message}</div>
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    defaultValue=""
                                    render={({ field, onChange }) => (
                                        <Autocomplete {...field}
                                            className="goals__form__select input-floating-label" id="zipCode" name="zipCode" type='text'
                                            freeSolo clearIcon={false} filterOptions={(options, state) => options}
                                            options={zipCodeOption?.map((option) => {
                                                return { label: option.label, value: option.value }
                                            }) ?? []}
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'object' && option !== null && 'label' in option) {
                                                    return option.label.toString();
                                                } else {
                                                    return option.toString();
                                                }
                                            }}
                                            onChange={(event, option) => field.onChange(option ? option.value : '')}
                                            onInputChange={async (event, value) => { loadZipcodeOptions(value) }}
                                            renderInput={(params) => (
                                                <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                                    <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
                                                    <label>{ZIP_CODE_LABEL}</label>
                                                    <div className="error-text" style={{ "position": "absolute" }} >{errors?.zipCode?.message}</div>
                                                    {errorList?.zipCode && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.zipCode}</div>}
                                                </div>
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={12}>
                                <Controller control={control} name="timeZone"
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={"Time Zone"} {...field} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={timezonesOptions} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={12}>
                                <Controller name="gender" control={control}
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={GENDER_LABEL} id="gender" {...field} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={GenderOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            <div className="error-text" style={{ "position": "absolute" }}>{errors.gender?.message}</div>
                                        </div>
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={11} md={12} lg={12}>
                                <Controller name="status" control={control}
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select isDisabled={props?.userProfile?.studentProfile?.myStatus !== null} id="status" placeholder={"Your current status"} {...register("status", { onChange: (e) => onStatusChange(e.target.value) })} {...field} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={StatusOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            <div className="error-text" style={{ "position": "absolute" }} >{errors.status?.message}</div>
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Controller name="graduationYear" control={control}
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={GRADUATION_YEAR_LABEL} id="graduationYear" {...field} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={Graduationyear} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            <div className="error-text" style={{ "position": "absolute" }} >{errors.graduationYear?.message}</div>
                                        </div>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <div className="input-floating-label">
                                    <input refs="currentGPA" id="weightedGpa" type="text" value={Gpa ? Gpa : ""} onChange={(e) => GetGpa(e)} className={"textbox--primary textbox--rounded input"} name="currentGPA" placeholder={"Weighted GPA"} onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                                    <label>{"Current weighted GPA"}</label>
                                    {!goalesData?.includes('gpa') &&
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                    }
                                    <div className="error-text" style={{ "position": "absolute" }}>{errorList?.weighted}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <div className="input-floating-label">
                                    <input refs="currentGPA" id="unWeightedGpa" type="text" value={unweightedGpa ? unweightedGpa : ""} onChange={(e) => GetUnweightGpa(e)} className={"textbox--primary textbox--rounded input"} name="currentGPA" placeholder={"Unweighted GPA"} onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                                    <label>{"Current unweighted GPA"}</label>
                                    <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                    <div className="error-text" style={{ "position": "absolute" }}>{errorList?.unweighted}</div>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={11} md={12} lg={12}>
                                {watch("status")?.role === "highschool" ? (
                                    <div className="col-md-12 col-sm-12 col-12 p-0 asyncmultiselect multinew"  >
                                        <Autocomplete className="goals__form__select" id="highschoolDropdown" name="highschool" type='text' freeSolo clearOnBlur={false} clearIcon={false}
                                            options={highSchoolData?.length > 0 ? highSchoolData.map((option) => option.label) : []} filterOptions={(options, state) => options}
                                            value={selectSchoolOption?.label || selectSchoolOption} isOptionEqualToValue={(option, value) => option === value}
                                            getOptionLabel={(item) => (item ? `${item} ` : "")} onChange={onTagsChange}
                                            onInputChange={async (event, value) => { loadOptions(value) }}
                                            renderInput={(params) => (
                                                <>
                                                    <div className="input-floating-label" ref={params.InputProps.ref} >
                                                        <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="highSchool" placeholder=" " />
                                                        <label>{"High School Name"}</label>
                                                        <div className="error-text" style={{ "position": "absolute" }}>{fieldErrors?.highSchoolName}</div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <div className="col-md-12 col-sm-12 col-12 p-0 asyncmultiselect multinew"  >
                                        <Autocomplete className="goals__form__select" id="Universitydropdown" name="highschool" type='text' freeSolo clearOnBlur={false} clearIcon={false}
                                            options={highSchoolData?.length > 0 ? highSchoolData.map((option) => option.label) : []} filterOptions={(options, state) => options}
                                            value={selectSchoolOption?.label || selectSchoolOption} isOptionEqualToValue={(option, value) => option === value}
                                            getOptionLabel={(item) => (item ? `${item} ` : "")} onChange={onTagsChange}
                                            onInputChange={async (event, value) => { UniversityloadOptions(value) }}
                                            renderInput={(params) => (
                                                <>
                                                    <div className="input-floating-label" ref={params.InputProps.ref} >
                                                        <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="highSchool" placeholder=" " />
                                                        <label>{"University Name"}</label>
                                                        <div className="error-text" style={{ "position": "absolute" }}>{fieldErrors?.highSchoolName}</div>
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}></Grid>
                            <Grid item xs={12} sm={11} md={12} lg={6}>
                                <div className="Savesection">
                                    <Button type="submit" className="savebtn" id="onSubmitbtn">Save</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData, auth }) => {
    const { message, errorList, status, gender, country, loading, schools, graduationyear } = commonData
    const { isProfileSuccess, userProfile, isProfileSuccessError } = userProfileData;
    const { isgetusergoals, isgetusergoalsstatus } = studentData;
    const { isResendEmailSuccess } = auth;
    return { message, errorList, status, gender, isProfileSuccess, userProfile, country, loading, schools, graduationyear, isProfileSuccessError, isgetusergoals, isgetusergoalsstatus, isResendEmailSuccess };
};
export default connect(mapStateToProps, { getUserProfile, updateUserProfile, UserProfileErrorStatus, getUserGoals, getUserGoalsStatus, setIsUserProfileSuccess, clearErrorList, clearMessage, universityApi, resendVerifyEmail, setResendVerifyEmail })(Index);
