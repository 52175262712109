import React, { useState, useEffect } from "react";
import "../profile.less"
import { Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, LAST_NAME_LABEL, ZIP_CODE_LABEL, EMAIL_LABEL } from "../../../../Constants/commonLabels";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { loadZipcode } from "../../../../utils/AutoComplete";

const validation = Yup.object({
    firstName: Yup.string().required("* Required").nullable().max(50, " Max upto 50 characters").min(2, "First name must contain 2 characters"),
    lastName: Yup.string().required("* Required").nullable().max(50, " Max upto 50 characters"),
    email: Yup.string()
        .required("* Required")
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Please enter a valid email"
        ),
    country: Yup.object().required("* Required").nullable(),
    // zipCode: Yup.string().when('country', {
    //     is: (country) => country?.isoCode2Digit === 'US',
    //     then: Yup.string()
    //         .matches(/^[0-9]{5}$/, 'Must contain only 5 digits')
    //         .required("* Required"),
    //     otherwise: Yup.string()
    //         .matches(/^[0-9]{6}$/, 'Must contain only 6 digits')
    //         .required("* Required")
    // })
});


export const GeneralForm = (props) => {
	const [zipCodeOption, setZipCodeOption] = useState([])
	const [loadZipcodes, setLoadZipcodes] = useState(false);

    const timezonesOptions = [
        { label: 'Pacific Time', value: 'America/Los_Angeles' },
        { label: 'Eastern Time', value: 'America/New_York' },
        { label: 'Central Time', value: 'America/Chicago' },
        { label: 'Mountain Standard Time', value: 'America/Phoenix' },
        { label: 'Mountain Daylight Time', value: 'America/Denver' },
        { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
        { label: 'Alaska Time', value: 'America/Anchorage' },
    ];
    
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });

    const filterObject = (title, options, value) => {
        console.log(title, options, value, "hagsjh")
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.isoCode2Digit?.toString() === value?.toString()
            );
        setValue(title, filtered && filtered[0]);
        console.log(title, filtered && filtered[0], "filteru")
    };

    
    const filterObjectTimeZone = (title, options, value) => {
        console.log(options, value, "chjecjk")
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
            console.log(filtered[0], "filtered")
        if (filtered[0]){

            setValue(title, filtered[0]);
        } else {
            setValue(title, {label: value, value: value})
        }
    };

    useComponentDidUpdate(() => {
        setValue("firstName", props?.profileData?.firstName);
        setValue("lastName", props?.profileData?.lastName);
        setValue("email", props?.profileData?.temporaryEmail ? props?.profileData?.temporaryEmail : props?.profileData?.email);
        setValue("zipCode", props?.profileData?.zipCode);
        filterObject("country", props?.countryOption, props?.profileData?.country);
        filterObjectTimeZone("timeZone", timezonesOptions, props?.profileData?.timezone);

    }, [props]);

    useComponentDidMount(() => {
        setValue("firstName", props?.profileData?.firstName);
        setValue("lastName", props?.profileData?.lastName);
        setValue("email", props?.profileData?.temporaryEmail ? props?.profileData?.temporaryEmail : props?.profileData?.email);
        setValue("zipCode", props?.profileData?.zipCode);
        filterObject("country", props?.countryOption, props?.profileData?.country);
        filterObjectTimeZone("timeZone", timezonesOptions, props?.profileData?.timezone);

    }, [props]);

    const onSubmit = data => {
        console.log(data, "bnjbjbvb")
        props?.handleFormValues(data)
    }

    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
                {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
            </ValueContainer>
        );
    };

    const handleResendEmail = (e, data) => {
        e.preventDefault()
        props?.handleResendApi()
    }



      const loadZipcodeOptions = (value) => {
		if (value === "") {
			setZipCodeOption([]);
			return;
		}
		if (value) {
			setLoadZipcodes(true);
			let payload = {
				searchString: value,
				pageNo: 1,
				limit: 80,
			};
			loadZipcode(payload)
				.then((res) => {
					const list = res?.data?.data.map((item) => ({
						label: item.zip,
						value: item.zip
					}));
					setZipCodeOption(list);
					setLoadZipcodes(false);
				})
				.catch((error) => {
					console.error("Error loading zip code options:", error);
					setZipCodeOption([]);
					setLoadZipcodes(false);
				});
		}
	};

    return (
      
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="miniprofileform">
                    <p className="profile__form__sub-title mb-4 mt-2">General</p>
                    <div className="formsection">
                        <div className="row">
                            <div className=" col-12 ">
                                <div className="input-floating-label">
                                    <input type="text" {...register("firstName")} maxLength={50} className={"textbox--primary textbox--rounded input"}
                                        name="firstName" placeholder="First Name"
                                    />
                                    <label>{FIRST_NAME_LABEL}</label>
                                    <div className="error-text">{errors.firstName?.message}</div>
                                </div>

                            </div>
                            <div className=" col-12 ">
                                <div className="input-floating-label">
                                    <input type="text" {...register("lastName")} maxLength={50} className={"textbox--primary textbox--rounded input"}
                                        name="lastName" placeholder="Last Name"
                                    />
                                    <label>{LAST_NAME_LABEL}</label>
                                    <div className="error-text">{errors.lastName?.message}</div>
                                </div>
                            </div>
                            <div className=" col-12 ">
                                <div className="input-floating-label">
                                    <input type="email" {...register("email")} className={"textbox--primary textbox--rounded input"}
                                        name="email" placeholder="Email"
                                    />
                                    <label>{EMAIL_LABEL}</label>
                                    <div className="error-text">{errors.email?.message}</div>
                                    {props?.profileData?.temporaryEmail && props?.profileData?.temporaryEmail !== null &&
                                        // <div class="cta-container">
                                        //     <p className="cta--text mb-2" style={{ "fontSize": "14px", "marginLeft": "5px", "color": "#E64A19" }}>
                                        //         Please check your email to confirm this address
                                        //         <span class="button-container">
                                        //             <a onClick={(e) => handleResendEmail(e, props?.userProfile?.email)} className="profile_pill_btns">Resend Email</a>
                                        //         </span>
                                        //         <br />
                                        //         {props?.profileData?.email} will be used until confirmed
                                        //     </p>
                                        // </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-7 ">
                                                <p className="cta--text mb-2" style={{ "fontSize": "14px", "marginLeft": "15px", "color": "#E64A19", "marginBottom": "0px" }}>
                                                    Please check your email to confirm this address {props?.profileData?.email} will be used until confirmed
                                                </p>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5">
                                            <span class="button-container">
                                                     <a onClick={(e) => handleResendEmail(e, props?.userProfile?.email)} className="profile_pill_btns ml-0">Resend Email</a>
                                                </span>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div className=" col-12 ">
                                <Controller
                                    control={control}
                                    name="country"
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={COUNTRY_NAME_LABEL} {...field} getOptionLabel={option => option.countryName} getOptionValue={option => option.isoCode2Digit} closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={props?.countryOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            <div className="error-text" style={{ "position": "absolute" }} >{errors.country?.message}</div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className=" col-12 " >
									<Controller
                                        name="zipCode"
                                        control={control}
                                        defaultValue=""
                                        render={({ field, onChange }) => (
											<Autocomplete {...field}
												className="goals__form__select input-floating-label" id="zipCode" name="zipCode" type='text'
												freeSolo clearIcon={false} filterOptions={(options, state) => options} 
												options={zipCodeOption?.map((option) => {
													return { label: option.label, value: option.value }
												}) ?? []}
												getOptionLabel={(option) => {
													if (typeof option === 'object' && option !== null && 'label' in option) {
														return option.label.toString();
													} else {
														return option.toString();
													}
												}}
												onChange={(event, option) => field.onChange(option ? option.value : '')}
												onInputChange={async (event, value) => { loadZipcodeOptions(value) }}
												renderInput={(params) => (
													<div className="input-floating-label" ref={params.InputProps.ref} style={{marginBottom: '4px'}}>
														<input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
														<label>{ZIP_CODE_LABEL}</label>
														<div className="error-text" style={{ "position": "absolute" }} >{errors?.zipCode?.message}</div>
														{ errors?.zipCode && <div className="error-text" style={{ "position": "absolute" }} >{errors?.zipCode}</div> }
													</div>
												)}
											/>
										)}
									/>
								</div>
                            <div className=" col-12 ">
                                <Controller
                                    control={control}
                                    name="timeZone"
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select placeholder={"Time Zone"} {...field}  closeMenuOnSelect={true} isClearable={false}
                                                isSearchable={false} classNamePrefix="mySelect" options={timezonesOptions} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                            {/* <div className="error-text" style={{ "position": "absolute" }} >{errors.timeZone?.message}</div> */}
                                        </div>
                                    )}
                                />
                            </div>
                           
                            <div className=" col-12 ">
                                <Button type="submit" style={{ float: 'right', marginTop: '1rem' }} className="btn mb-3 cta--rounded generalForm--sec__form--sec__dltprofilefcnt__profilesavebtn"> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
    
    )
}
