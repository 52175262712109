import React, { useState, useEffect } from "react";
import ReactQuill, { defaultProps } from 'react-quill';
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { UpdateNotes, UpdateNotesStatus, GetMilestoneByCourse, GetMilestoneByActivity } from "../../../../redux/actions";
import { Box, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px'
};

const validation = Yup.object().shape({
    description: Yup.string().transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").required('*Required'),
    // name: Yup.string().when(['url', 'resourceId'], {
    //     is: (url, resourceId) => resourceId !== null && url && url.trim().length > 0,
    //     then: Yup.string().required('*Required').max(500, 'Name only allows 500 characters'),
    //     otherwise: Yup.string().max(500, 'Name only allows 500 characters'),
    // }).nullable(),
    // url: Yup.string().url('Link must be a valid URL').nullable(),
});

const Index = (props) => {
    const params = useParams();
    const {state} = useLocation();
    const [error, setError] = useState({})

    const { handleSubmit, register, reset, setValue, watch, control,  formState: { errors } }  = useForm({
        resolver: yupResolver(validation),
    });

    let resourceName = watch("name")

    const onSubmit = (data) => {
        let payload = {
            planId: props?.mileStoneData?.planId,
            milestoneId: props?.mileStoneData?.id,
            updateContext: data?.description,
            milestoneStatusId: props?.data?.id,
            resourceName: data?.name,
            resourceUrl: data?.url,
            studentId : localStorage?.getItem("parentViaChild")
        }
        props?.UpdateNotes(payload)

    };


    const handleClose = () => {
        props?.handleClose()
        reset()
    }

    useComponentDidUpdate(() => {
        if (props?.editNotes) {
            handleClose()
            props?.UpdateNotesStatus(false)
            if (state?.activityData?.id) {
                let payload = {
                    "planId": state?.activityData?.planId || params.id,
                    "activityId": state?.activityData?.id
                }
                props?.GetMilestoneByActivity(payload)
            } else {
                let payload = {
                    "planId": state?.courseData?.planId || params.id,
                    "courseId": state.courseData?.id,
                    studentId : localStorage?.getItem("parentViaChild")
                }
                props?.GetMilestoneByCourse(payload)
            }
        }
    }, [props?.editNotes])

    useComponentDidMount(() => {
        setValue('description', props?.data?.update);
    }, [])

    useEffect(() => {
        if (props.data.resourceId && props?.mileStoneData?.resources?.length > 0) {
            const resourceobject = props?.mileStoneData?.resources.find(
                (item) => item.id === props.data.resourceId
            );
            console.log(resourceobject, "resourceobject");
            if (resourceobject) {
                console.log(props?.data?.update, "props?.data?.update");
                setValue('description', props?.data?.update);
                setValue('name', resourceobject.name);
                setValue('url', resourceobject.resourceURL);
            }
        }
    }, [props])
    

    return (
        <Modal
            open={props?.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={style} className="notes-popup">
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <div className="milestoneTitle "> {"Edit Update"} </div>
                        </Grid>
                        <Grid item xs={1} className="text-end" style={{ cursor: 'pointer' }}>
                            <CloseIcon onClick={handleClose} />
                        </Grid>

                    </Grid>
                    <Grid className="mt-5 mb-3">
                    <Controller
                            name="description"
                            control={control}
                            value={props?.data?.update}
                            render={({ field }) => (
                                <ReactQuill
                                    value={field.value}
                                    style={{ height: '210px' }}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    className="textarea--primary textbox--rounded input" theme="snow" {...field}
                                />

                            )}
                        />
                        <div className="error-text">{errors.description?.message}</div>
                    </Grid>
                    {/* <p className='mt-2' style={{ "fontSize": "16px", "color": "#6C6C6C", "fontWeight": "500" }} >Resource</p> */}
                    {/* <Grid item xs={12} className="mt-3 mb-3">
                        <div className="input-floating-label">
                            <textarea {...register("name")} maxLength={500} className={"textarea--primary textbox--rounded input"} name="name"
                                placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" onKeyPress={(event) => { if (!/[A-Za-z0-9\s]/.test(event.key)) { event.preventDefault(); } }} />
                            <label >Description</label>
                            <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": "5px" }}>Optional</p>
                            <div className="error-text mt-1" style={{ "position": "absolute", }} >{errors?.name?.message}</div>
                            <div className="subtext" style={{ float: 'right' }}><span>{resourceName?.length || 0}</span>/500</div>
                            <div className="error-text" style={{ "position": "absolute" }} >{error?.errors?.name}</div>
                        </div>
                    </Grid> */}
                    {/* <Grid item lg={12} md={12} sm={12} xs={12} >
                        <div className="input-floating-label">
                            <input refs="resourceLink" {...register("url")} type="text" className="textbox--primary textbox--rounded input"
                                name="url" placeholder="Link" id="resourceUrl" />
                            <label>Link</label>
                            <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": "5px" }}>Optional</p>
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.url?.message}</div>
                            <div className="error-text" style={{ "position": "absolute" }} >{error?.errors?.url}</div>
                        </div>
                    </Grid> */}
                    <Grid item xs={12} className="text-end" >
                        <Button type="submit" className="btn AddActivitybtn" style={{"marginTop":"30px"}} >Update</Button>
                    </Grid>
                </Box>
            </form>
        </Modal>
    );
};

const mapStateToProps = ({ studentData }) => {
    const { editNotes, isCourseMileStone } = studentData;
    return { editNotes, isCourseMileStone };
};

export default connect(mapStateToProps, { UpdateNotes, UpdateNotesStatus, GetMilestoneByCourse, GetMilestoneByActivity })(Index);

