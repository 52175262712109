import React, { useEffect } from 'react'
import "./connectedStudents.less";
import * as Yup from "yup";
import { Tooltip } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../../PillSection"
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { offerExtraSession, setOfferExtraSessionStatus } from "../../../../../redux/actions";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
	let navigate = useNavigate();

	let { name, isClickable, country, avatarUrl, school, weighted, meetTime, unWeighted, universities,
		professions, recipientUrl, courseAndActivities, subscribedPackages, message, children, reDirectUrl, studentId } = props

	useEffect(() => {
		if (props?.offerExtraSessionStatus) {
			props.setOfferExtraSessionStatus(false);
		}

	}, [props.offerExtraSessionStatus])

	const gpaText = (weighted, unWeigted) => {
		if (weighted && unWeigted) {
			return `Aspiring to achieve unweighted ${unWeigted} and weighted ${weighted} GPA`
		} else if (weighted) {
			return `Aspiring to achieve weighted ${weighted} GPA`
		} else if (unWeigted) {
			return `Aspiring to achieve unweighted ${unWeigted} GPA`
		} else return null;
	}

	const handleNavigate = () => {
		if (props?.userProfile?.currentRole === "parent") {
			navigate(`${reDirectUrl}/${studentId}`);
		} else {
			navigate(`/student/${studentId}/${reDirectUrl}`);
		}
	}

	const imageUrl = useImageUrl(avatarUrl);
	return (
		<div className={`connectedStudentCard ${isClickable ? "clickable" : ""}`}>
			<div className="connectedStudentCard__topSec" onClick={props?.userProfile?.currentRole !== "advisor" ? handleNavigate : null}>
				{avatarUrl ?
					<div className='connectedStudentCard__topSec__avatarImage'>
						<img className="connectedStudentCard__topSec__avatarImage--img"
							src={imageUrl} alt="userprofile"
						/>
					</div>
					:
					<PersonIcon className="connectedStudentCard__topSec__userIcon" color="action" />
				}
				<div className='connectedStudentCard__topSec__userInfo'>
					<span className='connectedStudentCard__topSec__userInfo__nameSec'>
						<p className='connectedStudentCard__topSec__userInfo__nameSec__primaryText'>{name}</p>
						{recipientUrl &&
							<Tooltip title={`Send Message`} placement="top">
								<span className='connectedStudentCard__topSec__userInfo__nameSec__iconBg'>
									<ChatBubbleOutlineOutlinedIcon className='connectedStudentCard__topSec__userInfo__nameSec__iconBg__chatIcon' />
								</span>
							</Tooltip>
						}
					</span>
					<p className='connectedStudentCard__topSec__userInfo__secondaryText'>{country}</p>
					<p className='connectedStudentCard__topSec__userInfo__helperText'>{meetTime}</p>
				</div>
			</div>
			<div className="connectedStudentCard__midSec" onClick={props?.userProfile?.currentRole !== "advisor" ? handleNavigate : null}>
				<div className="connectedStudentCard__midSec__educationSec">
					<SchoolOutlinedIcon className='connectedStudentCard__midSec__educationSec__schoolIcon' />
					<p className='connectedStudentCard__midSec__educationSec__schoolName'>{school}</p>
				</div>
				<div className="connectedStudentCard__midSec__goalsSec">
					{universities?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to attend </p>
							</div>
							<PillSection data={universities || []} maxWidth={190} maxHeight={100} />
						</>
					}
					{professions?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to become  </p>
							</div>
							<PillSection data={professions} maxWidth={187} maxHeight={100} />
						</>
					}
					{courseAndActivities?.length > 0 &&
						<>
						
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec studentCourse">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Courses & activities</p>
							</div>
							<div className='d-flex' style={{flexWrap: 'wrap'}}> 
							<PillSection data={courseAndActivities} maxWidth={250} maxHeight={100} />
							</div>
						</>
					}
					{gpaText(weighted, unWeighted) &&
						<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
							<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
							<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>{gpaText(weighted, unWeighted)}</p>
						</div>
					}
				</div>
				{message &&
					<div className="connectedStudentCard__midSec__otherServices">
						<p className="connectedStudentCard__midSec__otherServices__description" title={message}>
							{message}
						</p>b
					</div>
				}
				{subscribedPackages}

			</div>
			<div className="connectedStudentCard__buttonSec">
				{children}
			</div>
		</div>
	)
}

const mapStateToProps = ({ userProfileData, commonData, advisorData }) => {
	const { status, loading, errorList, scheduledSessionDetails } = commonData;
	const { userProfile } = userProfileData;
	const { offerExtraSessionStatus } = advisorData
	return { status, loading, errorList, userProfile, offerExtraSessionStatus, scheduledSessionDetails };
};
export default connect(mapStateToProps, { offerExtraSession, setOfferExtraSessionStatus })(Index);