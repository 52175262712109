import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    getUserGoals, CreateParentPlan, CreatePlanStatus, GetStudentPlan, GetStudentPlanPhase, sendRespondToRequest, setSendRespondToRequestStatus,
    getAdvisorRecommendedstatus, getAdvisorRecommended, ClearPlanId, getProfessions, EditPlan,
    EditPlanStatus, GetLoadPhase, userConnect, userConnectStatus, userConnectErrorStatus, disconnectUser, CancelRequest, CancelRequestStatus,
    getDetailedUserProfile, settDetailedUserProfileStatus, loadAdvisorReview, getStudentPlanDetails, getStudentDetail, SubScribeAdvisor, SubScribeAdvisorStatus, SubScribeAdvisorErrorStatus,
    sendRespondToParentRequest, respondToParentRequestStatus
} from "../../../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import Review from "../../../../componentsNew/Common/advisorProfile/Review"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkingHours from '../../../../componentsNew/Common/advisorProfile/WorkingHours'
import "../../../../componentsNew/Common/advisorProfile/advisorProfile.less";
import PersonIcon from '@mui/icons-material/Person';
import SelectStudent from "../../../../components/studentSelectionPopup";
import CustomAlert from "../../../../components/CustomAlert";
import Loader from "../../../../components/Loader"
import OwlCarousel from "react-owl-carousel";
import PillSection from "../../../../componentsNew/Common/PillSection"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CourseCard from "../../Cards/Common/CourseCard"
import ActivityCard from "../../Cards/Common/ActivityCard"
import Rating from '@mui/material/Rating';
import PlanAccessModal from "../../../../componentsNew/Common/PlanAccessModal";
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import Alert from 'react-bootstrap/Alert';
import Popover from '@mui/material/Popover';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useImageUrl } from "../../../../utils/UseImageURL";
import dayjs from "dayjs";


const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#f4f5f8',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'auto',
        padding: '20px 60px',
        flexGrow: 1,
    },
};

const Index = (props) => {
    const navigate = useNavigate();
    const [connect, setConnect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openStudent, setOpenStudent] = useState(false);
    const [viewMore, setViewMore] = useState(false)
    const buttonRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [disconnectUserData, setDisconnectUserData] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [rejectData, setRejectData] = useState({});
    const [splitedTime, setSplitedTime] = useState([]);

    const currentProtocol = window.location.protocol;
    const currentHost = window.location.host;
    const baseURL = `${currentProtocol}//${currentHost}`;

    let userAdvisorId = props?.data?.userInfo?.id || props.data?.requestInfo?.userId
    const [userProfileUrl, setUserProfileUrl] = useState(`${baseURL}/userProfile/${userAdvisorId}`);
    const [userdetails, setuserdetails] = useState([]);
    const [link, setLink] = useState('')
    const [alertVisible, setalertVisible] = useState(false)
    const [ShowAlert, setShowAlert] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [descriptions, setDescription] = useState(null);
    const [openConfirmation, setopenConfirmation] = useState(false)
    const [confirmationData,setConfirmationData] = useState({});
    const [rejectConfirmation, setrejectConfirmation] = useState(false)
    const [rejectConfirmationData, setrejectConfirmationData] = useState({})


    const isOnboarding = window?.location?.href?.includes('onboarding');
    
    const handlePopoverOpen = (event, data) => {
        setAnchorEl(event.currentTarget);
        if (data !== undefined) {
            setDescription(data)
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setDescription(null)
    };
    const open = Boolean(anchorEl);

    const handleSelectStudentOpen = () => setOpenStudent(true);
    const handleSelectStudentClose = () => setOpenStudent(false);

    const handleConnectOpen = () => setConnect(true);
    const handleConnectClose = () => {
        setConnect(false);
    }

    let { userInfo, userInfo: { advisorProfile } = {} } = props.data;
    let { profileData, detailedUserData, detailedUserData: { isConnectedWithUser, recentRequest } = {} } = props;

    let { studentProfile } = userInfo || {};
    let buttonState;
    if (props?.userProfile?.currentRole === "parent") {
        if (props?.userProfile?.isOnboarded === false) {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.userId) buttonState = "PENDING";
                else if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        } else {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (localStorage.getItem("parentViaChild") === recentRequest?.userId) buttonState = "PENDING";
                else if (localStorage.getItem("parentViaChild") === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        }

    } else {
        if (!isConnectedWithUser && recentRequest?.status === "pending") {
            if (props?.userProfile?.id === recentRequest?.userId) buttonState = "PENDING";
            else if (props?.userProfile?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
        } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

    }

    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const ConvertUTCtoTimezone = (userTime) => {
        console.log(userTime, "userTime")
        if (userTime) {
            return userTime?.map(timesheet => {
                let newTimesheet = {};
                for (let day in timesheet) {
                    let dayIndex = daysOfWeek?.indexOf(day?.replace("Timesheet", "")?.toLowerCase());
                    let startingTime = timesheet[day][0]?.startingTime;
                    let closingTime = timesheet[day][0]?.closingTime;

                    let startDay = daysOfWeek[dayIndex];
                    let closeDay = daysOfWeek[(dayIndex + 1) % 7];

                    newTimesheet[day] = [{
                        startingTime: `${startingTime} ${startDay}`,
                        closingTime: `${closingTime} ${closeDay}`
                    }];
                }
                if (newTimesheet) {
                    const daysOfWeek = {
                        sunday: 0,
                        monday: 1,
                        tuesday: 2,
                        wednesday: 3,
                        thursday: 4,
                        friday: 5,
                        saturday: 6,
                    };

                    const convertToPacificHonolulu = ({ startingTime, closingTime }) => {
                        const timezone = props?.userProfile?.timezone;
                        const [startHour, startDay] = startingTime?.split(' ');
                        const [closeHour, closeDay] = closingTime?.split(' ');
                    
                        const startDayOfWeek = daysOfWeek[startDay?.toLowerCase()];
                        const closeDayOfWeek = daysOfWeek[closeDay?.toLowerCase()];
                    
                        const startDateTime = dayjs.utc().day(startDayOfWeek)?.set('hour', parseInt(startHour.split(':')[0]))?.set('minute', parseInt(startHour?.split(':')[1]));
                        const closeDateTime = dayjs.utc().day(closeDayOfWeek)?.set('hour', parseInt(closeHour.split(':')[0]))?.set('minute', parseInt(closeHour?.split(':')[1]));
                    
                        // Convert to 24-hour format
                        const pacificStartingTime = startDateTime?.tz(timezone)?.format('HH:mm:ss dddd');
                        const pacificClosingTime = closeDateTime?.tz(timezone)?.format('HH:mm:ss dddd');
                    
                        return { pacificStartingTime, pacificClosingTime };
                    };
                    
                    // Function to process all timesheets in newTimesheet
                    let modifiedNewTimesheet = {};
                    Object?.keys(newTimesheet)?.forEach(day => {
                        modifiedNewTimesheet[day] = newTimesheet[day]?.map(timesheetEntry => {
                            const convertedTimes = convertToPacificHonolulu(timesheetEntry);
                            return {
                                startingTime: convertedTimes?.pacificStartingTime,
                                closingTime: convertedTimes?.pacificClosingTime
                            };
                        });
                    });
                    console.log("modifiedNewTimesheet",modifiedNewTimesheet);
                    
                    if (modifiedNewTimesheet) {
                        const splitTimesheet = {};

                        for (let day in modifiedNewTimesheet) {
                            const times = modifiedNewTimesheet[day];
                            splitTimesheet[day] = [];

                            times.forEach(time => {
                                const [startHour, startMinute, startSecond] = time.startingTime.split(' ')[0].split(':').map(Number);
                                const [closeHour, closeMinute, closeSecond] = time.closingTime.split(' ')[0].split(':').map(Number);

                                const startDay = time.startingTime.split(' ')[1];
                                const closeDay = time.closingTime.split(' ')[1];

                                if (startDay !== closeDay) {
                                    splitTimesheet[day].push({
                                        startingTime: `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}:${startSecond.toString().padStart(2, '0')} ${startDay}`,
                                        closingTime: `00:00:00 ${closeDay}`
                                    });

                                    const nextDay = `00:00:00 ${closeDay}`;
                                    splitTimesheet[day].push({
                                        startingTime: nextDay,
                                        closingTime: `${closeHour.toString().padStart(2, '0')}:${closeMinute.toString().padStart(2, '0')}:${closeSecond.toString().padStart(2, '0')} ${closeDay}`
                                    });
                                } else {
                                    splitTimesheet[day].push(time);
                                }
                            });
                        }
                        console.log("splitTimesheet", splitTimesheet);
                        if (splitTimesheet) {
                            let timesheet = splitTimesheet;
                            const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

                            days?.forEach(day => {
                                const currentDayTimesheet = timesheet[`${day}Timesheet`];
                                let i = 0;

                                while (i < currentDayTimesheet.length) {
                                    const entry = currentDayTimesheet[i];
                                    const startDay = entry.startingTime.split(" ")[1]?.toLowerCase(); // Convert to lowercase
                                    const endDay = entry.closingTime.split(" ")[1]?.toLowerCase(); // Convert to lowercase

                                    // Check if startDay exists in the days array to avoid undefined errors
                                    if (startDay && days.includes(startDay)) {
                                        if (startDay !== day) {
                                            // Ensure the startDay Timesheet exists in the object
                                            if (!timesheet[`${startDay}Timesheet`]) {
                                                timesheet[`${startDay}Timesheet`] = [];
                                            }
                                            timesheet[`${startDay}Timesheet`].unshift(entry);
                                            currentDayTimesheet.splice(i, 1);
                                        } else {
                                            i++;
                                        }
                                    } else {
                                        console.error(`Invalid startDay detected: ${startDay}`);
                                        i++; // Move forward to avoid infinite loops
                                    }
                                }
                            });
                            if (timesheet) {
                                const timesheetArray = [];
                                timesheetArray.push(timesheet);
                                if (timesheetArray && timesheetArray?.length > 0) {
                                    console.log("timesheet", timesheetArray, timesheetArray?.length);
                                    TimeSplitter(timesheetArray)
                                }
                            }
                        }
                    }
                }
            });
        }
    };
    useEffect(() => {
  
        console.log(props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours, "dghsg")
        if (props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours) {
            ConvertUTCtoTimezone([props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours])
        }
        if(props?.isRequestRespond){
            props?.setSendRespondToRequestStatus(false)
            props?.handleClose()
        }
    }, [props?.suggestedAdvisor, props?.userProfile, props?.data?.advisorProfile, props?.data, props?.detailedUserData,props?.isRequestRespond]);


    const handleClose = () => {
        props?.handleClose();

    };
 
    

    const TimeSplitter = (newTime) => {
        console.log("newTime", newTime);

        if (newTime) {
            const advisorWorkingHours = newTime[0];  // Since it's an array of objects, access the first object
            const splitTimesheets = {
                "mondayTimesheet": [],
                "tuesdayTimesheet": [],
                "wednesdayTimesheet": [],
                "thursdayTimesheet": [],
                "fridayTimesheet": [],
                "saturdayTimesheet": [],
                "sundayTimesheet": []
            };

            const dayMapping = {
                "Monday": "mondayTimesheet",
                "Tuesday": "tuesdayTimesheet",
                "Wednesday": "wednesdayTimesheet",
                "Thursday": "thursdayTimesheet",
                "Friday": "fridayTimesheet",
                "Saturday": "saturdayTimesheet",
                "Sunday": "sundayTimesheet"
            };

            function parseTimeAndDay(timeAndDay) {
                const lastSpaceIndex = timeAndDay?.lastIndexOf(' ');
                const time = timeAndDay?.substring(0, lastSpaceIndex)?.trim();
                const day = timeAndDay?.substring(lastSpaceIndex + 1)?.trim();
                return [time, day];
            }

            const convertTimeFormat = (convertedTime) => {
                const [time, day] = convertedTime?.split(' ');
                const converted12HourTime = dayjs(time, 'HH:mm:ss')?.format('hh:mm A');  // Adjusted format to include seconds
                return `${converted12HourTime} ${day}`;
            };

            Object.keys(advisorWorkingHours)?.forEach(dayKey => {
                const dayTimeSheet = advisorWorkingHours[dayKey];

                if (Array.isArray(dayTimeSheet) && dayTimeSheet?.length > 0) {
                    dayTimeSheet.forEach(entry => {

                        const convertedStartTime = convertTimeFormat(entry?.startingTime);
                        const convertedCloseTime = convertTimeFormat(entry?.closingTime);

                        if (convertedStartTime && convertedCloseTime) {
                            const [startTime, startDay] = parseTimeAndDay(convertedStartTime);
                            const [endTime, endDay] = parseTimeAndDay(convertedCloseTime);

                            const startDayKey = dayMapping[startDay];
                            const endDayKey = dayMapping[endDay];

                            if (!startDayKey || !endDayKey) {
                                console.error(`Invalid day key mapping: startDay=${startDay}, endDay=${endDay}`);
                                return;
                            }

                            if (startDayKey === endDayKey) {
                                // Case 1: Same day
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            } else {
                                // Case 2: Different days
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `12:00 AM, ${startDay}`
                                });

                                splitTimesheets[endDayKey].push({
                                    startingTime: `12:00 AM, ${endDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            }
                        }

                    });
                }
            });
            console.log("splitTimesheetssplitTimesheets",splitTimesheets);
            
            if (splitTimesheets) {
                const updatedTimesheets = { ...splitTimesheets };
                Object.keys(updatedTimesheets).forEach(day => {
                    if (updatedTimesheets[day]?.length > 0 && updatedTimesheets[day][0]?.startingTime?.includes('PM')) {
                        const pmEntry = updatedTimesheets[day]?.shift();
                        updatedTimesheets[day]?.push(pmEntry);
                    }
                });
                console.log("updatedTimesheetsupdatedTimesheets",updatedTimesheets);
                
                setSplitedTime(updatedTimesheets);
            }
        }
    };

    const openDisconnectModal = (status, data = {}) => {
        setDisconnectUserData(data)
        setShowDisconnectModal(status);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 700); // Adjust the threshold as needed
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const id = props?.data?.userInfo?.id;
        const data = props?.suggestedAdvisor?.find(obj => obj?.userInfo?.id === id);
        if (data) {
            setuserdetails(data);
        }
    }, [props?.suggestedAdvisor]);

    useEffect(() => {
        setLoading(true)
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId,
            pointOfViewStudentId: props?.userProfile?.currentRole === "parent" && props?.childprofile && props?.childprofile[0]?.id
        }
        props?.getDetailedUserProfile(payload)
        let reviewPayload = {
            advisorId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId,
            "limit": 10,
            "pageNo": 1,
            studentId : localStorage?.getItem("parentViaChild")

        }
        props?.loadAdvisorReview(reviewPayload)
    }, [])

    useEffect(() => {
        if (props.detailedUserDataSuccess) {
            setLoading(false)
            props.settDetailedUserProfileStatus(false)
        }

    }, [props.detailedUserDataSuccess])

    useEffect(() => {
        if (props?.subscribeadvisor) {
            props?.SubScribeAdvisorStatus(false)
            setLoading(false)
        }
        if (props?.subscribeerror) {
            props?.SubScribeAdvisorErrorStatus(false)
            setLoading(false)
        }
    }, [props?.subscribeadvisor, props?.subscribeerror])

    useEffect(() => {
        if (props.userConnectSuccess) {
            props?.handleCallApi()
            props?.userConnectStatus(false)

            setLoading(false)

        }
        if (props.userConnectError) {
            props?.userConnectErrorStatus(false)
            setLoading(false)
            props?.handleCallApi()
        }
    }, [props.userConnectSuccess, props.userConnectError])

    useEffect(() => {
        if (props.feadbackSubmit) {
            props?.handleCallApi()
        }

    }, [props.feadbackSubmit])

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const advisorActivities = advisorProfile?.advisorActivities || [];
    const advisorCourses = advisorProfile?.advisorCourses || [];

    const combinedData = [...advisorActivities, ...advisorCourses];

    const mappedData = combinedData.map(item => {
        if (item.activityName) {
            return item.activityName;
        } else if (item.courseName) {
            return item.courseName;
        }
        return null;
    });

    const ConfirmSubScribe = (data) => {
        let payload = {
            "advisorId": data?.userId,
            "paymentPackageId": data?.id,
            "studentId": props?.userProfile?.currentRole === "parent" ? props?.childprofile && props?.childprofile[0]?.id : props?.userProfile?.id
        }
        props?.SubScribeAdvisor(payload)
        setLoading(true)
    }

    const handleConnect = (e, data) => {
        e.preventDefault();
        setopenConfirmation(true);
        setConfirmationData(data)
    };

    const handleDelete = () => {
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId
        }
        props?.disconnectUser(payload)
    }
    const navigateToGoals = () => {
        if (props?.userProfile?.currentRole === 'parent') {
            if (props?.userProfile?.isOnboarded === false) {
                navigate("/onboarding/goals/child");
            } else {
                navigate("/parent/myprofile");
            }
        }
        if (props?.userProfile?.currentRole === 'student') {
            if (props?.userProfile?.isOnboarded === false) {
                navigate("/onboarding/goals");
            } else {
                navigate("/student/myprofile");
            }
        }
    };

    function myFunction() {
        setShowAlert(true)
        let textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', userProfileUrl)
        document.body.appendChild(textfield)
        let copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove()
        navigator.clipboard.writeText(userProfileUrl)
        setalertVisible(true)
        setTimeout(() => {
            setShowAlert(false);
            setalertVisible(false)
        }, 3000);
    }

    const copyToClipboard = async () => {
        myFunction()
        setLink('')
    };

    const ProfileApi = () => {
        setLoading(true)
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId,
            pointOfViewStudentId: props?.userProfile?.currentRole === "parent" && props?.childprofile && props?.childprofile[0]?.id
        }
        props?.getDetailedUserProfile(payload)
    }

    useEffect(() => {
        if (props?.cancelrequest) {
            ProfileApi()
            setShowCancelModal(false);
            setRejectData({});
            props?.CancelRequestStatus(false);
            setLoading(false)
        }
    }, [props?.cancelrequest])

    const RejectRequest = (e, data) => {
        e.preventDefault();
        setrejectConfirmation(true);
        setrejectConfirmationData(data)
       
    }

    useEffect(() => {
        if (props?.isRequestRespond) {
            props?.setSendRespondToRequestStatus(false)
            setLoading(false)
            props?.HandleCloseApi()
        }
        if (props?.parentRequest) {
            props?.respondToParentRequestStatus(false)
            setLoading(false)
            props?.HandleCloseApi()
        }
    }, [props?.isRequestRespond, props?.parentRequest])

    const CloseConfirmation = () => {
        setConfirmationData({})
        setopenConfirmation(false);
    }

    const RejectClose = () => {
        setrejectConfirmationData({});
        setrejectConfirmation(false);
    }
    
    const ConfirmtionAdd = () => {
        setLoading(true)
        let payload = {
            "requestId": confirmationData?.id,
            "isAccepted": true
        }
        if (props?.userProfile?.currentRole === "parent") {
            props?.sendRespondToParentRequest(localStorage.getItem("parentViaChild"), payload)
        } else {
            props?.sendRespondToRequest(payload)
        }
        CloseConfirmation()
    }

    const RejectConfirmtion = () => {
        setLoading(true)
        let payload = {
            "requestId": rejectConfirmationData?.recentRequest?.id,
            "isAccepted": false
        }
        if (props?.userProfile?.currentRole === "parent") {
            props?.sendRespondToParentRequest(localStorage.getItem("parentViaChild"), payload)
        } else {
            props?.sendRespondToRequest(payload)
        }
        RejectClose()

    }

    const Network = sessionStorage.getItem("netvalue");
    const imgUrl = useImageUrl(props?.data?.userInfo?.avatarPath)
    return (
        <>
        {loading && <Loader />}
            {openConfirmation &&
            <ConfirmationPopup show={openConfirmation} handleSubmit={() => ConfirmtionAdd()} 
                message={"Accepting this request will enable you send messages to this advisor and book your free consultation session"} 
                btnText="confirm" onHide={() => CloseConfirmation()} />
            }
               {rejectConfirmation &&
                <ConfirmationPopup show={rejectConfirmation} handleSubmit={() => RejectConfirmtion()} 
                message={"Please confirm that you would like to reject this connection request from this advisor"} btnText="confirm" onHide={() => RejectClose()} />
            }
            <Modal style={styles.modal} open={props.show} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" className="goalsModal" >
                <div style={styles.paper}>
                    {loading && <Loader />}
                    {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
                    {alertVisible &&
                        <div style={{ position: 'fixed', top: '10px', zIndex: '99999', left: '50%', transform: 'translateX(-50%)' }} >
                            <Alert style={{ position: 'sticky', marginTop: '10px', }}
                                variant="success" onClose={() => { setShowAlert(false); setalertVisible(false) }} dismissible>Link Copied to clipboard</Alert>
                        </div>
                    }
                    <div style={styles.content} className="modal-body">
                        <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                            <Button onClick={handleClose}><CloseIcon sx={{ fill: 'black' }} /></Button>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="advisorProfileCard">
                                    <Grid container className="advisorProfileCard__container">
                                        <div className="advisorProfileCard__info-sec">
                                            {props?.data?.userInfo?.avatarPath ? (
                                                <div class="advisorProfileCard__info-sec__sub-img advisor-sub-img" style={{ width: '110px', height: '130px' }}>
                                                    <img src={imgUrl} alt="user" style={{ borderRadius: '10px', width: "100%", height: '100%',objectFit:'cover' }} />
                                                </div>
                                            ) :
                                                <div class="advisorProfileCard__info-sec__sub-img" style={{ width: '110px', height: '130px' }}>
                                                    <PersonIcon color="action" sx={{ border: '1px solid grey', width: "100%", height: "100% !important", borderRadius: '10px' }} />
                                                </div>
                                            }
                                            <div className="advisorProfileCard__info-sec__profileCont">
                                                <p className="profileName">
                                                    {userInfo?.fullName}
                                                </p>
                                                <div className="advisorProfileCard__info-sec__profileCont__about-sec">

                                                    <span>
                                                        <RoomOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">{userInfo?.country}</p>
                                                    </span>
                                                </div>
                                                <div className="advisorProfileCard__info-sec__profileCont__about-sec mt-2">
                                                    <span>
                                                        <p className="label">{"Ratings :"}</p>
                                                        <Rating name="read-only"  value={userInfo?.advisorProfile?.advisorReviewSummary?.averageRating} precision={0.5} readOnly size="small" sx={{ color: '#AEAEAE' }} />

                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="advisorProfileCard__connect-btn d-grid">
                                            {props?.userProfile?.currentRole === "parent" ? (
                                                <>
                                                    {!props?.detailedUserData?.isConnectedWithUser &&
                                                        <>
                                                            {buttonState === "PENDING" &&
                                                                <button className="advisorProfileCard__connect-btn__secondary-btn">
                                                                    Cancel Request
                                                                </button>
                                                            }
                                                            {buttonState === "RECIEVED_REQUEST" &&
                                                                <div style={{ "display": "flex", "gap": "20px", "flexWrap": "wrap", "marginBottom": "10px", "justifyContent": "center" }} >

                                                                    <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={(e) => RejectRequest(e, props?.detailedUserData)}>
                                                                        Reject
                                                                    </button>


                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => handleConnect(e, props?.detailedUserData?.recentRequest)}>
                                                                        Accept
                                                                    </button>

                                                                </div>
                                                            }
                                                            {buttonState === "CONNECT" &&
                                                                <button className="advisorProfileCard__connect-btn__primary-btn" onClick={props?.parent ? handleSelectStudentOpen : handleConnectOpen}>
                                                                    Connect
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                    {isConnectedWithUser &&
                                                        <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={() => openDisconnectModal(true, userInfo)}>
                                                            Disconnect
                                                        </button>
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {!props?.detailedUserData?.isConnectedWithUser &&
                                                        <>
                                                            <div style={{ "display": "flex", "gap": "20px", "flexWrap": "wrap", "marginBottom": "10px", "justifyContent": "center" }} >
                                                                {props?.detailedUserData?.recentRequest?.status === "pending" &&
                                                                    <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={(e) => RejectRequest(e, props?.detailedUserData)}>
                                                                        Reject
                                                                    </button>
                                                                }
                                                                {props?.userProfile?.id === props?.detailedUserData?.recentRequest?.targetUserId &&
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => handleConnect(e, props?.detailedUserData?.recentRequest)}>
                                                                        Accept
                                                                    </button>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            )}
                                            <div style={{ "display": "flex", "justifyContent": "center" }} >
                                                <Button className="advisorProfileCard__connect-btn__primary-btn" ref={buttonRef} onClick={copyToClipboard} >
                                                    Share
                                                </Button>
                                            </div>
                                        </div>

                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    <p className="advisorCoursesCard__heading">
                                        About {userInfo?.firstName}
                                    </p>
                                </div>
                                <div className="advisorProfileCard">
                                    <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                        {
                                            advisorProfile?.currentlyIn === "university" && (
                                                <>
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Enrolled in {advisorProfile?.universityName}
                                                        </p>
                                                    </span>
                                                    {(advisorProfile?.unWeighted && advisorProfile?.weighted) &&
                                                        <span>
                                                            <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                            <p className="label">
                                                                Has a current unweighted GPA of {advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted}
                                                            </p>
                                                        </span>
                                                    }
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Went to {advisorProfile?.highschoolName}
                                                        </p>
                                                    </span>
                                                </>
                                            )
                                        }
                                        {
                                            advisorProfile?.currentlyIn === "highschool" && (
                                                <>
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            {advisorProfile?.highschoolStatus} in {advisorProfile?.highschoolName}
                                                        </p>
                                                    </span>
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Has a current unweighted GPA of {advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted}
                                                        </p>
                                                    </span>
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Aspiring to attend {advisorProfile?.targetUniversities}
                                                        </p>
                                                    </span>
                                                </>
                                            )
                                        }
                                        {advisorProfile?.currentlyIn === "professional" && (
                                            <span>
                                                <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                <p className="label">
                                                    Went to {advisorProfile?.highschoolName} and {advisorProfile?.universityName}
                                                </p>
                                            </span>
                                        )
                                        }
                                        {advisorProfile?.satScore &&
                                            <span>
                                                <WorkspacePremiumOutlinedIcon color="#515050" fontSize="12px" />
                                                <p className="label">
                                                    Scored {advisorProfile?.satScore} in SAT
                                                </p>
                                            </span>
                                        }
                                        {advisorProfile?.actScore &&
                                            <span>
                                                <WorkspacePremiumOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label">
                                                    Scored {advisorProfile?.actScore} in ACT
                                                </p>
                                            </span>
                                        }
                                        {advisorProfile?.currentlyIn === "professional" ? (
                                            <span>
                                                <WorkOutlineOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label d-flex">
                                                    Operating as <span style={{ color: '#1C84EE' }}> &nbsp; {advisorProfile?.companyName}</span> &nbsp; since {advisorProfile?.operatingSince}
                                                </p>
                                            </span>
                                        ) : (
                                            <span>
                                                <EmojiEventsOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label">
                                                    Aspiring to become {advisorProfile?.aspiringToBecome}
                                                </p>
                                            </span>
                                        )
                                        }
                                        {/* {advisorProfile?.accomplishments &&
                                            <span className="courseFullViewCard__bottomSec">
                                                <p className={`courseFullViewCard__bottomSec${viewMore ? '__description' : '__detailedDescription'}`}>
                                                    {advisorProfile?.accomplishments ? stripHTMLTags(advisorProfile?.accomplishments) : ""}
                                                </p>
                                                <p className='courseFullViewCard__bottomSec__viewCta' onClick={() => setViewMore(!viewMore)}>
                                                    {advisorProfile?.accomplishments ? viewMore ? "view less" : "view more" : ""}
                                                </p>
                                            </span>
                                        } */}
                                        {(advisorProfile?.accomplishments) &&
                                            <span className="courseFullViewCard__bottomSec">
                                                <p className={`courseFullViewCard__bottomSec${viewMore ? '__description' : '__detailedDescription'}`} onClick={() => setViewMore(!viewMore)}>
                                                    {(advisorProfile?.accomplishments) ? stripHTMLTags(advisorProfile?.accomplishments) : ""}
                                                </p>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    <p className="advisorCoursesCard__heading">
                                        Services offered
                                    </p>
                                </div>
                                <div className="advisorProfileCard">
                                    <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                        <span>
                                            <StarBorderIcon color="#515050" fontSize="12px" />
                                            <p className="label">
                                                {userInfo?.firstName} Offers {advisorProfile?.noOfFreeConsultation} free consultation session
                                            </p>
                                        </span>
                                        {
                                            advisorProfile?.helpWithPlan === true && (
                                                <span>
                                                    <StarBorderIcon color="#515050" fontSize="12px" />
                                                    <p className="label d-flex">
                                                        {userInfo?.firstName} can create a plan with courses and activities based on your{" "}
                                                        <span style={{ color: '#1C84EE', cursor: 'pointer', "marginLeft": "4px" }} onClick={navigateToGoals}> goals</span>

                                                    </p>
                                                </span>
                                            )
                                        }
                                        {
                                            advisorProfile?.helpWithSpecificCourseActivity === true && (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <span>
                                                            <StarBorderIcon color="#515050" fontSize="12px" />
                                                            <p className="label d-flex align-items-center" style={{ flexWrap: 'wrap' }}>
                                                                {userInfo?.firstName} can provide mentorship and support for{" "} &nbsp;
                                                                <span>
                                                                    {isSmallScreen ? <PillSection data={mappedData} maxWidth={1000} maxHeight={20} /> : <PillSection data={mappedData} maxWidth={1000} maxHeight={100} />}
                                                                </span>
                                                            </p>

                                                        </span>
                                                    </Grid>


                                                </Grid>

                                            )
                                        }
                                    </div>
                                </div>
                            </Grid>
                            {advisorProfile?.advisorCourses?.length > 0 &&
                                <Grid item xs={12}>
                                    <div className="advisorCoursesCard">
                                        <p className="advisorCoursesCard__heading">Course Details</p>

                                        {advisorProfile?.advisorCourses.map((data, key) => (
                                            <CourseCard data={data} student />
                                        ))}
                                    </div>
                                </Grid>
                            }
                            {advisorProfile?.advisorActivities?.length > 0 &&
                                <Grid item xs={12}>
                                    <div className="advisorCoursesCard">
                                        <p className="advisorCoursesCard__heading">Activity Details</p>
                                        {advisorProfile?.advisorActivities.map((data, key) => (
                                            <ActivityCard data={data} student />
                                        ))}
                                    </div>
                                </Grid>
                            }
                            {props.detailedUserData?.advisorFeePackages?.length > 0 && (
                                <Grid item xs={12}>
                                    <div className="advisorPackageCard">
                                        <p className="advisorPackageCard__heading">Pricing Packages</p>
                                        <div className="advisorPackageCard__packCard-sec">
                                            <div className="packageAdvisor">
                                                {props.detailedUserData?.advisorFeePackages?.map((data, key) => {
                                                    const filteredCourses = advisorProfile?.advisorCourses?.filter(course => data?.courses.includes(course.id));
                                                    const filteredAcyivity = advisorProfile?.advisorActivities?.filter(activity => data?.activities.includes(activity.id));
                                                    const subscriptionCount = props?.sharedData?.packageSubscriptionCount?.[data.id] || 0;
                                                    const monthstatus = data?.paymentType && data.paymentType === "monthly" ? "/Month" : "";
                                                    const advisorActivities = filteredAcyivity || [];
                                                    const advisorCourses = filteredCourses || [];

                                                    const combinedData = [...advisorActivities, ...advisorCourses];

                                                    const mappedPackageData = combinedData.map(item => {
                                                        if (item.activityName) {
                                                            return item.activityName;
                                                        } else if (item.courseName) {
                                                            return item.courseName;
                                                        }
                                                        return null;
                                                    });

                                                    return (
                                                        <>
                                                            <div className="Advisor_PackageCard" key={data?.id}>
                                                                <div className="Advisor_PackageCard_TopSection">
                                                                    <div className="Advisor_PackageCard_TopSection_Title">
                                                                        <p className="Advisor_PackageCard_TopSection_Title_Name">{data?.packageName}</p>
                                                                    </div>
                                                                    <div className="Advisor_PackageCard_TopSection_subText">
                                                                        <p className="Advisor_PackageCard_TopSection_subText_Name">{subscriptionCount} {data.paymentType === "monthly" ? "Subscribed" : "Bought"}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="Advisor_PackageCard_MidSection">
                                                                    <div className="Advisor_PackageCard_MidSection_Price">
                                                                        <p className="Advisor_PackageCard_MidSection_Price_Text">Price<span>{data?.price}${monthstatus}</span></p>
                                                                    </div>
                                                                    <div className="Advisor_PackageCard_MidSection_PackageList">
                                                                        <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                            <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                Includes {data?.noOfPaidSessions} Sessions of {data?.sessionLength} mins duration
                                                                            </p>
                                                                        </div>
                                                                        <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                            <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                Includes {data?.messageLimit === 0 ? "Unlimited" : data?.messageLimit} Chat Messages
                                                                            </p>
                                                                        </div>
                                                                        {data?.helpWithPlan === true &&
                                                                            <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                                <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                    <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                    Suggest courses and activities based on your goals
                                                                                </p>
                                                                            </div>
                                                                        }
                                                                        {data?.helpWithSpecificCourseActivity === true && (
                                                                            <>
                                                                                <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                                    <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                        <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                        Provide mentorship and support for
                                                                                    </p>
                                                                                </div>
                                                                                <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                                    <div className="Advisor_PackageCard_MidSection_PackageList_Section_CourseContainer">
                                                                                        <PillSection data={mappedPackageData} maxWidth={240} maxHeight={80} />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="Advisor_PackageCard_FooterSection" style={{ "marginTop": "30px" }}>
                                                                    <div className="Advisor_PackageCard_FooterSection_Description">
                                                                        <p className="Advisor_PackageCard_FooterSection_Description_Text" aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => handlePopoverOpen(e, data?.description !== "" ? data?.description : "")} onMouseLeave={handlePopoverClose}>{data?.description ? stripHTMLTags(data?.description) : ""}</p>
                                                                    </div>
                                                                    <div className="Advisor_PackageCard_FooterSection_Container">
                                                                        <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" disabled={true} style={{ opacity: 0.5 }}>
                                                                            Purchase
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {descriptions !== null &&
                                                                <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none', width: "60%" }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} onClose={handlePopoverClose} disableRestoreFocus>
                                                                    <Typography sx={{ p: 1 }} >{descriptions ? stripHTMLTags(descriptions) : ""}</Typography>
                                                                </Popover>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                            {(splitedTime) &&
                                <Grid item xs={12}>
                                    <>
                                        {
                                            (splitedTime) ? <>
                                                <p className="advisorPackageCard__heading">Working hours
                                                    <Tooltip arrow placement="top" title="Parents and students can schedule virtual sessions during these hours in your calendar">
                                                        <span style={{ "marginLeft": "10px" }}>
                                                            <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 12.5C7.875 12.5 7.5 12.125 7.5 11.6667V8.33333C7.5 7.875 7.875 7.5 8.33333 7.5C8.79167 7.5 9.16667 7.875 9.16667 8.33333V11.6667C9.16667 12.125 8.79167 12.5 8.33333 12.5ZM9.16667 5.83333H7.5V4.16667H9.16667V5.83333Z" fill="black" fill-opacity="0.54" />
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </p>
                                                <WorkingHours data={splitedTime} />
                                            </>
                                                : <></>
                                        }
                                    </>
                                </Grid>
                            }
                            </Grid>
                            {advisorProfile?.advisorReviewSummary &&
                                <Grid item xs={12} lg={12}>
                                    <Review data={props?.reviewData} />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
            </Modal >
        </>
    );
};

const mapStateToProps = ({ userProfileData, studentData, commonData, advisorData }) => {
    const { message, errorList, loading, universities, professions, userConnectSuccess, userConnectError,
        recommendedAdvisor, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, reviewData, feadbackSubmit, suggestedAdvisor, cancelrequest, isRequestRespond, parentRequest } = commonData
    const { userProfile, childprofile } = userProfileData;
    const { isgetusergoals, isgetusergoalsstatus, iscreateplan, getstudentplan, iseditplanphase, parentPlanId, } = studentData;
    const { subscribeadvisor, subscribeerror } = advisorData
    return {
        message, errorList, loading, universities, professions, userConnectSuccess, isgetusergoals,
        isgetusergoalsstatus, recommendedAdvisor, iscreateplan, getstudentplan, iseditplanphase, parentPlanId,
        userProfile, userConnectError, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, reviewData, feadbackSubmit, childprofile, cancelrequest, isRequestRespond, parentRequest
        , subscribeadvisor, subscribeerror, suggestedAdvisor
    }
};

export default connect(mapStateToProps, {
    getUserGoals, CreateParentPlan, CreatePlanStatus, getAdvisorRecommended, sendRespondToRequest, setSendRespondToRequestStatus,
    userConnectStatus, ClearPlanId, GetStudentPlan, GetStudentPlanPhase, getAdvisorRecommendedstatus, getProfessions,
    EditPlan, EditPlanStatus, GetLoadPhase, userConnect, userConnectErrorStatus, getDetailedUserProfile, disconnectUser, CancelRequest, CancelRequestStatus,
    settDetailedUserProfileStatus, loadAdvisorReview, getStudentPlanDetails, getStudentDetail, SubScribeAdvisor, SubScribeAdvisorStatus, SubScribeAdvisorErrorStatus, sendRespondToParentRequest,
    respondToParentRequestStatus
})(Index);