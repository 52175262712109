import React, { useEffect, useState } from "react";
import "./mywork.less";
import Loader from "../../../components/Loader";
import CustomAlert from "../../../components/CustomAlert";
import { Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import Plans from "./Plans";
import Course from "./Courses";
import Activity from "./Activities";
import Milestone from "./Milestones";
import { clearMessage, GetStudentPlan, GetStudentPlanStatus, getUserGoals, getChildProfile, getUserGoalsStatus, getProfessions, CreatePlan, CreatePlanStatus, ClearPlanId } from "../../../redux/actions";
import Planpopup from "../../../componentsNew/Common/MyworkModal/Plans"
import Phasepopup from "../../../componentsNew/Common/MyworkModal/Phase"
import { useLocation } from "react-router-dom";
// Styles
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>{children}</Box>
            )}
        </Typography>
    );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
// Select Styles
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setloading] = useState(false);
    const [tabs, settabs] = useState(0);
    const [selectedOption, setSelectedOption] = useState({ value: 0, label: "Plans" });
    const Menulists = [{ value: 0, label: "Plans" }, { value: 1, label: "Current Courses" }, { value: 2, label: "Current Activities" }, { value: 3, label: "Current Milestones" }]
    const [showPlans, setshowPlans] = useState(false);
    const [showPhase, setshowPhase] = useState(false);
    const [planIds, setNewplanIds] = useState("")



    useEffect(() => {
        props?.getChildProfile()
        props.clearMessage();
        props?.ClearPlanId()
        sessionStorage.removeItem('tabvalue');
    }, [])

    useEffect(() => {
        if (props?.iscreateplan && props?.newplanid) {
            props?.CreatePlanStatus(false)
            if (props?.newplanid) {
                setNewplanIds(props?.newplanid)
                HandleOpenPhase()
            }
        }
    }, [props?.iscreateplan, props?.newplanid,])


    // Tabs Change
    function findOptionByValue(value) {
        return Menulists.find((option) => option.value === value);
    }
    const handleSelectChange = (e) => {
        sessionStorage.setItem('tabvalue', JSON.stringify(e.value));
        settabs(e.value);
        setSelectedOption(e);
    }
    function handleChange(event, newValue) {
        settabs(newValue);
        setSelectedOption(findOptionByValue(newValue));
        sessionStorage.setItem('tabvalue', JSON.stringify(newValue));
    }

    const HandleCreatePlan = () => {
        setshowPlans(true)
    }
    const ClosePlans = () => {
        setshowPlans(false)
    }

    const HandleOpenPhase = () => {
        setshowPhase(true)
    }
    const ClosePhase = () => {
        ClosePlans()
        setshowPhase(false)
        props?.ClearPlanId()
    }

    return (
        <>
            {loading && <Loader />}
            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            {showPlans && <Planpopup show={showPlans} onHide={ClosePlans} />}
            {(planIds && showPhase) && <Phasepopup show={showPhase} onHide={ClosePhase} PlanId={planIds} />}
            <div className="mywork_Page">
                <div className="mywork_Page_Top">
                    <div className="mywork_Page_Top_Desktop mt-1">
                        <div className="mywork_Page_Top_Desktop_Title">
                            <p>Your Work</p>
                        </div>
                        {!window.location.pathname.includes("mywork/student") && <div className="mywork_Page_Top_Desktop_Btncnt">
                            <Button className="mywork_Page_Top_Desktop_Btncnt_PlanBtn" onClick={() => HandleCreatePlan()}>Create New Plan</Button>
                        </div>}
                    </div>
                    <div className="mywork_Page_Top_Mobile">
                        <div className="mywork_Page_Top_Mobile__Section">
                            <div className="mywork_Page_Top_Mobile__Section_Title">
                                <p>Your Work</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="mywork_Page_Mid">
                    <div className="mywork_Page_Mid_Desktop">
                        <div className="mywork_Page_Mid_Desktop_TabCnt">
                            <Tabs value={tabs} onChange={handleChange} >
                                <Tab disableRipple label="Plans" className="Tab" {...a11yProps(0)} />
                                <Tab disableRipple label="Current Courses" className="Tab" {...a11yProps(1)} />
                                <Tab disableRipple label="Current Activities" className="Tab" {...a11yProps(2)} />
                                <Tab disableRipple label="Current Milestones" className="Tab" {...a11yProps(3)} />
                            </Tabs>
                        </div>
                    </div>
                    <div className="mywork_Page_Mid_Mobile" >
                        <div className="input-floating-label">
                            <Select options={Menulists} onChange={handleSelectChange}
                                // defaultValue={Menulists[0]}
                                value={selectedOption}
                                getOptionLabel={e => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {e.icon}
                                        <span style={{ marginLeft: 10 }}>{e.label}</span>
                                    </div>
                                )}
                                placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                            />
                        </div>

                    </div>
                    <div className="mywork_Page_Top_Mobile mt-0">

                        <div className="mywork_Page_Top_Mobile_Btncnt mt-0">
                            <Button className="mywork_Page_Top_Mobile_Btncnt_PlanBtn mt-0" onClick={() => HandleCreatePlan()}>Create New Plan</Button>
                        </div>
                    </div>
                </div>
                <div className="mywork_Page_Btm">
                    <TabPanel value={tabs} index={0} className="TabPanels">
                        <Plans />
                    </TabPanel>
                    <TabPanel value={tabs} index={1} className="TabPanels">
                        <Course />
                    </TabPanel>
                    <TabPanel value={tabs} index={2} className="TabPanels">
                        <Activity />
                    </TabPanel>
                    <TabPanel value={tabs} index={3} className="TabPanels">
                        <Milestone />
                    </TabPanel>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ studentData, advisorData, commonData }) => {
    const { professions } = commonData
    const { isgetusergoals, isgetusergoalsstatus, studentplanstatus, iscreateplan, newplanid } = studentData;
    const { } = advisorData
    return { isgetusergoals, isgetusergoalsstatus, studentplanstatus, professions, iscreateplan, newplanid }
};

export default connect(mapStateToProps, { clearMessage, GetStudentPlan, GetStudentPlanStatus, getUserGoals, getUserGoalsStatus, getProfessions, CreatePlan, CreatePlanStatus, ClearPlanId, getChildProfile })(Index);
