import React, { useEffect, useState } from "react";
import CourseCard from "../../../../componentsNew/Common/Cards/MyWork/CourseCard"
import { GetCourseList, GetCourseListStatus, courseReject, courseRejectStatus, courseApprove, courseApproveStatus, 
  courseDropConfirmation, courseDropConfirmationStatus, courseDropErrorStatus,getConnectedAdvisors } from "../../../../redux/actions";
import { connect } from 'react-redux';
import ManageAdvisorPopup from '../../../../componentsNew/Common/MyworkModal/ManageAdvisor';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Loader from "../../../../components/Loader";
import Rejectcourse from "../Popups/acceptcourse";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useImageUrl } from "../../../../utils/UseImageURL";

const Index = (props) => {
  const navigate = useNavigate();
	const location = useLocation();
  const [loading, setloading] = useState(false);
  const [showadvisor, setshowadvisor] = useState(false);
  const [plandata, setplandata] = useState();
  const [showreject, setshowreject] = useState(false);
  const Networks = /\/student\/[^\/]+\/mywork\/?$/.test(window?.location?.pathname);
  const [globalAdvisor, setGlobalAdvisor] = useState(null);
  const [detaildata, setDetaildata] = useState('');

  const { id } = useParams();
  useEffect(() => {
    setloading(true)
    let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
    props?.GetCourseList(payload);
    props?.getConnectedAdvisors()
  }, [location.pathname])

  useEffect(() => {
    if (props?.getplancourseliststatus) {
      props?.GetCourseListStatus(false)
      setloading(false)
    }
    if (props?.isCourseRejected) {
      props?.courseRejectStatus(false)
      let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props?.GetCourseList(payload);
    }
    if (props?.isCourseApprove) {
      props?.courseApproveStatus(false)
      let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props?.GetCourseList(payload);
    }
    if (props?.isCourseError) {
      let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props?.GetCourseList(payload);
    }
    if (props?.courseDropStatus) {
      setloading(false)
      props?.courseDropConfirmationStatus(false)
      let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props?.GetCourseList(payload);
    }
    if (props?.courseDropError) {
      setloading(false)
      props?.courseDropErrorStatus(false)
      let payload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: id ? id : null }
      props?.GetCourseList(payload);
    }
  }, [props?.getplancourseliststatus, props?.isCourseRejected, props?.isCourseApprove, props?.isCourseError, props?.courseDropStatus, props?.courseDropError])

  const OpenMangeAdvisor = (e, data, value,accessibleGlobalAdvisors) => {
    e.preventDefault();
    setplandata(data)
    setGlobalAdvisor(accessibleGlobalAdvisors ?? null)
    setshowadvisor(true)
    setDetaildata(value)
  }
  const CloseMangeAdvisor = () => {
    setshowadvisor(false)
    setplandata({})
  }
  const getSuggestionDetail = (isApproved, isDropSuggested, type, data) => {
    if (isApproved && isDropSuggested) {
      return (
        <p className='myWork-courseCard__suggestionSec__dangerText'>
          Advisor suggested to drop this {type}. can now review and accept or reject your proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    } else if (!isApproved) {
      return (
        <p className='myWork-courseCard__suggestionSec__successText'>
          You can now review and accept or reject Advisor proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    }
  }
  const OpenRejectpopup = (e, data) => {
    e.preventDefault();
    setplandata(data)
    setshowreject(true)
  }
  const acceptCourse = (e, data) => {
    e.preventDefault();
    if (data?.planInfo?.isApproved) {
      setloading(true)
      let payload = {
        phaseId: data?.courseInfo?.phaseId,
        planId: data?.courseInfo?.planId
      }
      props?.courseApprove(data?.courseInfo?.id, payload)
    }
  }
  const Cloaseplanrejectpopup = () => {
    setshowreject(false)
  }

  const RejectCourse = (data) => {
    if (data?.planInfo?.isApproved) {
      setloading(true)
      let payload = {
        phaseId: data?.courseInfo?.phaseId,
        planId: data?.courseInfo?.planId
      }
      props?.courseReject(data?.courseInfo?.id, payload)
    }
  }
  const acceptDropCourse = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "courseId": data?.courseInfo?.id,
      "phaseId": data?.courseInfo?.phaseId,
      "planId": data?.courseInfo?.planId,
      "status": true
    }
    props?.courseDropConfirmation(payload)
  }

  const rejectDropCourse = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "courseId": data?.courseInfo?.id,
      "phaseId": data?.courseInfo?.phaseId,
      "planId": data?.courseInfo?.planId,
      "status": false
    }
    props?.courseDropConfirmation(payload)
  }
  const handleNavigate = (e) => {
    e.preventDefault();
    navigate('/student/mynetwork/exploreAdvisor')
  }
  const handleNavigateMilestone = (e, data) => {
    e.preventDefault();
    console.log(data,"datadatadata");
    
    if(Networks){
      navigate(`plan/${data?.courseInfo?.planId}/courseMilestone/${data?.courseInfo?.id}`, { state: { courseData: data?.courseInfo,studentView: true } })
    }
    else{
      navigate(`plan/${data?.courseInfo?.planId}/courseMilestone/${data?.courseInfo?.id}`, { state: { courseData: data?.courseInfo } })

    }
  }
  const AvatarWithUrl = ({ avatarUrl, fullName, onClick }) => {
    const imageUrl = useImageUrl(avatarUrl);
    return (
      <Avatar
        alt={fullName}
        src={imageUrl}
        onClick={onClick}
      >
        {!imageUrl ? fullName?.charAt(0) : null}
      </Avatar>
    );
  };
  return (
    <>
      {loading && <Loader />}
      {showadvisor && <ManageAdvisorPopup show={showadvisor} onHide={CloseMangeAdvisor} data={plandata} text={detaildata} globalAdvisor={globalAdvisor??null} />}
      {showreject && <Rejectcourse show={showreject} onHide={Cloaseplanrejectpopup} data={plandata} title={"Reject Course"} AcceptPlans={(data) => RejectCourse(data)} />}
      {props?.getplancourselist?.length > 0 ? (
        <div className="" style={{ "display": "flex", "flexWrap": "wrap", "gap": "25px" }}>
          {props?.getplancourselist?.map(data => {
            
            const subscribedAdvisors = data?.subscribedAdvisors || [];
            let accessibleGlobalAdvisors = subscribedAdvisors?.filter(access => access?.accessLevel === "global");
            console.log("subscribedAdvisors",subscribedAdvisors);
            
            // const avatars = subscribedAdvisors?.map(subscribedAdvisor => {
            //   const connectedAdvisor = props?.connectedAdvisors?.find(advisor => advisor?.userInfo.id === subscribedAdvisor?.userId);
            //   return connectedAdvisor?.userInfo?.avatarPath;
            // }); 
			const avatars = subscribedAdvisors?.map(subscribedAdvisor => {
				const connectedAdvisor = props?.connectedAdvisors?.find(advisor => advisor?.userInfo.id === subscribedAdvisor?.userId);

				const avatarUrl = connectedAdvisor?.userInfo?.avatarPath;
				const fullName = connectedAdvisor?.userInfo?.fullName;
				const subscriptionDetails = connectedAdvisor?.subscriptiondetails;
				const userInfo = connectedAdvisor?.userInfo;
				const userdata = connectedAdvisor;
				const displayValue = {
				  avatarUrl: avatarUrl || null,
				  fullName: fullName || null,
				  subscriptionDetails: subscriptionDetails || null,
				  userInfo: userInfo || null,
				  userdata: userdata || null,
				};
				console.log(displayValue, "displayValue")
				return displayValue;
			  });
			
            return (
              <>
                <CourseCard key={data?.courseInfo?.id}
                  courseName={data?.courseInfo?.courseName}
                  courseType={data?.courseInfo?.courseType}
                  description={data?.courseInfo?.description}
                  semester={data?.courseInfo?.planPhase?.title}
                  isApproved={data?.courseInfo?.isApproved}
                  isDropsuggested={data?.courseInfo?.isDropsuggested}
                  planName={(data?.courseInfo?.planId === data?.planInfo?.id) && data?.planInfo?.planName}
                  suggestionData={getSuggestionDetail(data?.courseInfo?.isApproved, data?.courseInfo?.isDropsuggested, 'Course', data)}
                  isArchived={data?.courseInfo?.isArchived ?? false}
                >
                  {!Networks &&
                    <>
                      <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                        {(data?.courseInfo?.isApproved && data?.courseInfo?.isDropsuggested || !data?.courseInfo?.isApproved) ? (
                          <></>
                        ) : (
                          <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flatBtn" onClick={(e) => handleNavigateMilestone(e, data)} >
                            View Milestones
                          </div>
                        )}
                        {(data?.courseInfo?.isApproved && data?.courseInfo?.isDropsuggested) &&
                          <div className="myWork-planCard__btnSection">
                            <div className="myWork-planCard__btnSection__Cnt">
                              <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => rejectDropCourse(e, data)}>
                                Reject
                              </div>
                              <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptDropCourse(e, data)}>
                                Accept
                              </div>
                            </div>
                          </div>
                        }
                        {!data?.courseInfo?.isApproved &&
                          <div className="myWork-planCard__btnSection">
                            <div className="myWork-planCard__btnSection__Cnt">
                              <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => OpenRejectpopup(e, data)}>
                                Reject
                              </div>
                              <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptCourse(e, data)}>
                                Accept
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      {(data?.courseInfo?.isApproved && !data?.courseInfo?.isDropsuggested) &&
                        <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                          {!data?.courseInfo?.isArchived &&
                            <div className="mt-3">
                              <div style={avatars.length > 0 ? { "display": "flex", "gap": "10px", "width": "100%", "alignItems": "center" } : { "width": "100%" }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                  <AvatarGroup max={2}>
                                    {console.log(avatars,"avatars")
                                    }
                                    {avatars.map((avatar, index) => (
                                       <AvatarWithUrl key={index} avatarUrl={avatar?.avatarUrl} fullName={avatar?.fullName} onClick={avatars?.length === 1 ? (e) => OpenMangeAdvisor(e, data, "Course",accessibleGlobalAdvisors) : undefined} />
                                    ))}
                                  </AvatarGroup>
                                  {avatars.length === 1 &&
                                    <ChatBubbleOutlineOutlinedIcon style={{ marginLeft: 'auto', marginTop: '0px', fontSize: "12px", "cursor": "pointer" }} />
                                  }
                                </div>
                                <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__outlineBtn" onClick={(e) => OpenMangeAdvisor(e, data, "Course",accessibleGlobalAdvisors)} style={{ width: avatars.length > 0 ? "100%" : "100%", "fontSize": avatars.length > 0 ? "16px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                                  Manage Advisors
                                </div>
                              </div>
                            </div>
                          }
                          <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flat-btn" onClick={(e) => handleNavigate(e)}>
                            <span>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="11" r="6" stroke="#222222" />
                                <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                              </svg>
                            </span>
                            Explore Advisors
                          </div>
                        </div>
                      }
                    </>
                  }
                  {Networks &&
                    <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec">
                      <div className="myWork-courseCard__ctaSec__CourseManageAdvisorBtnSec__flatBtn" onClick={(e) => handleNavigateMilestone(e, data)} >
                        View Milestones
                      </div>
                    </div>
                  }
                </CourseCard>
              </>
            )
          })}
        </div>
      ) : (
        <>
          <div className="semesterCourseSec__noDataSec">
            <div className="semesterCourseSec__noDataSec__noDataCard">
              <p className="semesterCourseSec__noDataSec__noDataCard__textContent">
                No Courses Available
              </p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ studentData, commonData, advisorData, userProfileData }) => {
  const { } = userProfileData;
  const { message, errorList, connectedAdvisors, connectedAdvisorsStatus } = commonData;
  const { } = advisorData;
  const { getplancourselist, getplancourseliststatus, isCourseRejected, isCourseApprove, isCourseError, courseDropStatus, courseDropError } = studentData;
  return { message, errorList, getplancourselist, getplancourseliststatus, connectedAdvisors, connectedAdvisorsStatus, isCourseRejected, isCourseApprove, isCourseError, courseDropStatus, courseDropError }
}
export default connect(mapStateToProps, { GetCourseList, GetCourseListStatus, courseReject, courseRejectStatus,
   courseApprove, courseApproveStatus, courseDropConfirmation, courseDropConfirmationStatus, courseDropErrorStatus ,
   getConnectedAdvisors})(Index);