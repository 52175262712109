import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GetActivityList } from '../../../../redux/actions';
import ActivityCard from "../../../../componentsNew/Common/Cards/MyWork/ActivityCard";
import { useLocation, useNavigate } from 'react-router-dom';
import "./currentActivities.less";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const Index = (props) => {
	const [loading, setLoading] = useState(false);
    const [loadMoreActivities, setLoadMoreActivities] = useState(false);
    const [page, setPage] = useState(1);
	const { state } = useLocation();
    let navigate = useNavigate();

	useEffect(() => {
		let value = { 
            searchString: "", 
            pageNo: 1, 
            limit: 10, 
            planIds: [],
            studentId: state?.id
        }
        props.GetActivityList(value)
	
	}, [])
	const getSuggestionDetail = (isApproved, isDropSuggested, type) => {
        if (isApproved && isDropSuggested) {
            return (
                <p className='myWork-courseCard__suggestionSec__dangerText'>
                    You suggested to drop this {type}. {state?.studentFirstName} can now review and accept or reject your proposal
                    <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon'/>
                </p>
            );
        } else if (!isApproved) {
            return (
                <p className='myWork-courseCard__suggestionSec__successText'>
                    You suggested this {type}. {state?.studentFirstName} can now review and accept or reject your proposal
                    <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon'/>
                </p>
            );
        }
    }

	const handleNavigateActivity = (data) => {
        navigate(`plan/${data?.planInfo?.id}/activityMilestone`, { state: { activityData: data?.activityInfo } })
    }
	const handleScroll = (e) => {
        e.stopPropagation();
        // if((props?.pagination?.totalrecords > props?.getstudentplan?.length) && !loadMorePlan){
		const bottom = Math.ceil(e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight;
		if(bottom){
			setLoadMoreActivities(true);
			setPage(page => page + 1)
		}
        // }
    }
    return (
      	<div className='myWork-studentActivitiesSec' onScrollCapture={handleScroll}>
			{props?.getactivitylist?.map(data => {
				return(
					<ActivityCard key={data?.activityInfo?.id} 
						planName={data?.planInfo?.planName}
						activityName= {data?.activityInfo?.activityName}
						activityType={data?.activityInfo?.activityType}
						description={data?.activityInfo?.description}
						semester= {data?.activityInfo?.planPhase?.title}
						isApproved={data?.activityInfo?.isApproved}
						isDropsuggested={data?.activityInfo?.isDropsuggested}
						suggestionData={ !props?.semData?.isPassed && getSuggestionDetail(data?.activityInfo?.isApproved, data?.activityInfo?.isDropsuggested, 'Activity')}
					>
						<div className="myWork-courseCard__ctaSec__primaryBtnSec">
							{!getSuggestionDetail(data?.activityInfo?.isApproved, data?.activityInfo?.isDropsuggested, 'Activity') &&
								<>
									{/* <p className='myWork-courseCard__ctaSec__primaryBtnSec__helperText'>3 Milestones, 11 milestone updates</p> */}
									<div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={() => handleNavigateActivity(data)}>
										View Milestone
									</div>
								</>
							}
						</div>
					</ActivityCard>
				)
			})}
			{props?.getactivitylist?.length === 0 &&
				<div className='myWork-studentActivitiesSec__noDataSec'>
					<p className='myWork-studentActivitiesSec__noDataSec__textBlack'>You don't have any Activities currently</p>
				</div>
			}
		</div>
    )
}
const mapStateToProps = ({ studentData }) => {
    const { getactivitylist, getstudentplan } = studentData;
    return { getactivitylist, getstudentplan }
};

export default connect(mapStateToProps, { GetActivityList })(Index);