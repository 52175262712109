// BookNowComponent.js
import React from 'react';
import { Card, Button } from '@mui/material';
import './scheduleRemaining.less';
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";

const BookNowComponent = (props) => {
    const sessionClass = props?.session === 'free' ? 'free' : '';

    const navigate = useNavigate();

    const handleNavigateToSchedule = () => {
        if (props.userProfile?.currentRole === "parent") {
            navigate('/parent/schedule');

        } else {
            navigate('/student/schedule');

        }
    }

    return (
        <Card className="styled-card sessionRemainingCard"  >
            <div className="number-circle">{props?.remainingSession}</div>
            <div className="text-container scheduleTextContainer">
                <span className={`free-text ${sessionClass}`}>{props?.session}</span>
                <span className='session-text'>session with {props?.advisorName}</span>
            </div>

            <Button
                className="btn cta--rounded cta-primary scheduleDashBtn"
                style={{
                    borderRadius: '10px',
                    marginLeft: 'auto',
                    textTransform: 'none',
                    backgroundColor: '#1e88e5',
                    color: 'white',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        flexDirection: 'row'
                    }}
                    onClick={() => handleNavigateToSchedule()}
                >
                    <span className="button-text-dashboard">Book now</span>
                    <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" strokeWidth="2" />
                        <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                    </svg>
                </span>
            </Button>
        </Card>
    );
};

const mapStateToProps = ({ userProfileData, }) => {
    const { childprofile, userProfile } = userProfileData;

    return { childprofile, userProfile };
};
export default connect(mapStateToProps, {})(BookNowComponent);

