import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../assets/css/dashboard.less";
// import Sidebar from "../layouts/Block/Sidebar";
import Sidebar from "../componentsNew/Advisor/Sidebar";
import ChatBox from "../components/ChatBox";
import Calendar from "../components/Calendar";
import { connect } from "react-redux";
import axios from 'axios';
import { getUserData } from "../utils/AuthUtils";
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry, getStatus,
    getActivity, getCourse, getStudentGrades, clearMessage, getChildProfile, getPhasetime, getnotification,
    manageProfileServices, setManageProfileServicesStatus, getDashboardGoalsCount, listScheduledEvents, getConnectedStudents, getEarningsCount, getAdvisorDashboardCounts, getAdvisorState
} from "../redux/actions";
import { withRouter } from "../redux/store/navigate";
import UpcommingSession from "../components/UpCommingSession";

let style = {
    zIndex: 99,
    position: "-webkit-sticky",
    position: "sticky",
    top: 10,
    backgroundColor: '#EF6C00E5',
}
class DashboardLayout extends React.Component {
    componentDidMount() {
        this.props.clearMessage()
        if (getUserData('accessToken')) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            this.props.getUserRoles();
            this.props.getUserProfile();

            let payload = {
                "pageNo": 1,
                "limit": 50
            }
            this.props.getnotification(payload)
            this.props?.getDashboardGoalsCount()
            this.props?.listScheduledEvents()
            this.props?.getConnectedStudents()
            this.props?.getEarningsCount()
            this.props?.getAdvisorDashboardCounts()
            this.props?.getAdvisorState()
        }
        window.scrollTo(0, 0);   // Scrolls to the top left corner
    }
    componentDidUpdate() {
        if (this.props.userProfile?.isOnboarded !== undefined && !this.props.userProfile.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
        // if(this.props?.manageServiceStatus){
        //     console.log("setter Called");

        //     this.props.getUserProfile();
        //     this.props.setManageProfileServicesStatus(false);
        // }
    }
    convertTimeFormat(timeStr) {
        let parts = timeStr.split(':');
        let newTime = parts.slice(0, 2).join(':');
        return newTime;
    }
    turnServicesOn() {
        let { mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet } = this?.props?.userProfile?.advisorProfile?.advisorWorkingHours;

        let payLoad = {
            isServicesEnabled: !this?.props?.userProfile?.advisorProfile?.isServicesEnabled
        }
        payLoad.mondayAvailableTimes = mondayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.tuesdayAvailableTimes = tuesdayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.wednesdayAvailableTimes = wednesdayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.thursdayAvailableTimes = thursdayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.fridayAvailableTimes = fridayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.saturdayAvailableTimes = saturdayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })
        payLoad.sundayAvailableTimes = sundayTimesheet?.map(date => { return { startTime: this.convertTimeFormat(date?.startingTime), endTime: this.convertTimeFormat(date?.closingTime) } })

        console.log(payLoad);
        this.props?.manageProfileServices(payLoad);
    }

    handleNavigateToProfile = () => {
        this.props.navigate('/advisor/myprofile', { state: { type: "pricingPackages" } });
    }

    render() {
        const Alert = React.forwardRef(function Alert(props, ref) {
            return <MuiAlert style={style} elevation={8} ref={ref} variant="filled" {...props} />;
        });

        const action = (
            <>
            {this.props?.getAdvisorCurrentState?.state === 1 && 
                <Button style={{ color: "#fff", border: '1px solid #fff', textTransform: "capitalize" }} 
                variant="outlined" size="small" onClick={() => this.handleNavigateToProfile()}
                >
                    Set up
                </Button>
            }
            {this.props?.getAdvisorCurrentState?.state === 2 &&
            <Button style={{ color: "#fff", border: '1px solid #fff', textTransform: "capitalize" }} variant="outlined" size="small" onClick={() => this.turnServicesOn()}>
                Turn On
            </Button>
            }
            {this.props?.getAdvisorCurrentState?.state === 3 &&
                <Button style={{ color: "#fff", border: '1px solid #fff', textTransform: "capitalize" }} variant="outlined" size="small" onClick={() => this.turnServicesOn()}>
                Turn OFF
            </Button>
            }
            </>
        );
        return (
            <div>
                {console.log(this.props?.getAdvisorCurrentState, "this.props?.getAdvisorCurrentState")}
                <div id="dashboard-layout">
                    <Sidebar notification={this.props.notificationdata} />
                    <div className="center-wrap">
                        {this?.props?.userProfile && this?.props?.userProfile?.advisorProfile?.isServicesEnabled === false &&
                            <Alert className="serviceInfoNotification" severity="warning" action={action}>
                                {this.props?.getAdvisorCurrentState?.state === 1 && "Set up your office hours and pricing packages to turn on services"}
                                {this.props?.getAdvisorCurrentState?.state === 2 && "Your services are currently OFF and you are not visible to new users"}
                                {this.props?.getAdvisorCurrentState?.state === 3 && "Your services are currently ON and you are visible to new users"}
                            </Alert>
                        }
                        {this.props.userProfile && <Outlet />}
                    </div>
                    <div className="right-wrap">
                        <div className="minicnt">
                            <div class="" style={{ backgroundColor: "#F4F5F8", borderRadius: "1px solid #DBDBDB", width: '100%', height: '350px' }}></div>
                        </div>
                        <div className="upcomecnt">
                            <h5>Notifications</h5>
                            <UpcommingSession notification={this?.props?.notificationdata} />
                        </div>
                    </div>
                    <Calendar />
                    <ChatBox />
                </div>
            </div>
        )
    }
}
//export default DashboardLayout;
const mapStateToProps = ({ userProfileData, commonData, advisorData }) => {
    const { isUserOnboarded, userProfile } = userProfileData
    const { notificationdata } = commonData;
    const { manageServiceStatus, getAdvisorCurrentState } = advisorData;
    return { isUserOnboarded, userProfile, notificationdata, manageServiceStatus, getAdvisorCurrentState }
};

export default connect(mapStateToProps, {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getActivity, getCourse, getStudentGrades, getStatus, clearMessage, getChildProfile, getPhasetime, getnotification,
    manageProfileServices, setManageProfileServicesStatus, getDashboardGoalsCount, listScheduledEvents, getConnectedStudents, getEarningsCount, getAdvisorDashboardCounts, getAdvisorState
})(withRouter(DashboardLayout));