import React, { useState, useEffect } from "react";
import Tags from "../Tags";
import Popover from '@mui/material/Popover';
import { Typography, Grid } from '@mui/material';
import { wrap } from "lodash";

const Index = ({ data, maxWidth, maxHeight, close, totalData, ids, activityDelete }) => {
    const [visibleData, setVisibleData] = useState([]);
    const [remainingData, setRemainingData] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        showPills();
    }, []);

    useEffect(() => {
        showPills();
    }, [data]);

    const createPill = (text, id) => {
        return <Tags bold text={text} close={close} totalData={totalData} deleteId={id} activityDelete={activityDelete} />;
    };

    const createMorePill = (count) => {
        return <Tags bold text={`+ ${count}`} />;
    };
    const showPills = () => {
        const containerWidth = maxWidth - 2; // Subtracting 2 to account for border width
        const containerHeight = maxHeight - 2;
        let totalWidth = 0;
        let totalHeight = 0;
        let visiblePills = [];
        let remainingPills = [];


        if (close && ids) {
            for (let i = 0; i < ids.length; i++) {
                const pill = createPill(ids[i]?.courseName, ids[i]?.courseId);
                if (pill.props?.text?.length < 30) {
                    totalWidth += pill.props?.text?.length * 7 + 20; // Assuming an average character width of 10px and adding padding
                } else {
                    totalWidth += 29 * 7 + 20; // Assuming an average character width of 10px and adding padding
                }
                totalHeight += 20; // Assuming a pill height of 30px

                if (
                    totalWidth <= containerWidth &&
                    totalHeight / 2 <= containerHeight
                ) {
                    visiblePills.push(pill);
                } else {
                    remainingPills.push(ids[i]?.courseName);
                }
            }
        } else if (!close && data) {
            if (data?.length === 1) {
                // If there is only one id, display the full pill without truncation
                const pill = createPill(data[0]);
                visiblePills.push(pill);
            } else {
                for (let i = 0; i < data.length; i++) {
                    const pill = createPill(data[i]);
                    if (pill.props?.text?.length < 30) {
                        totalWidth += pill.props?.text?.length * 7 + 20; // Assuming an average character width of 10px and adding padding
                    } else {
                        totalWidth += 29 * 7 + 20; // Assuming an average character width of 10px and adding padding
                    }
                    totalHeight += 20; // Assuming a pill height of 30px

                    if (
                        totalWidth <= containerWidth &&
                        totalHeight / 2 <= containerHeight
                    ) {
                        visiblePills.push(pill);
                    } else {
                        remainingPills.push(data[i]);
                    }
                }
            }
        }

        setVisibleData(visiblePills);
        setRemainingData(remainingPills);
    };


    return (
        <div className="pkgCardTag-Sec d-flex" style={{  maxHeight: maxHeight,flexWrap:"wrap" ,marginBottom:"10px"}}>
            {visibleData}
            {remainingData.length > 0 && 
                <div
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}> {createMorePill(remainingData.length)} 
                </div>
            }
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
                    {remainingData.map((item, index) => (
                        <Grid item key={index}>
                            <Typography>{item}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
            {/* </div> */}
        </div>
    );
};

export default Index;
