import React, { useState, useEffect } from "react";
import { Modal, Typography } from '@mui/material';
import { ACTIVITY_NAME_LABEL, ACTIVITY_TYPE_LABEL } from "../../../../Constants/commonLabels"
import * as Yup from "yup";
import { useForm, Controller, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../components/Loader";
import CustomAlert from "../../../../components/Alert";
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import { withRouter } from "../../../../redux/store/navigate";
import { getActivities, getActivity, getPhasetime } from "../../../../redux/actions";
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}><Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder} </Placeholder>
            {React.Children.map(children, (child) => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const stylesCourse = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#FBFBFB ',
        // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '26%',
        maxHeight: "80%",
        minHeight: "70%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        border: "1px solid #efefef",

    },
    header: {
        height: '20px',
        backgroundColor: '#FBFBFB ',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        boxSizing: 'border-box',

        paddingBottom: '15px',
        paddingTop: '31px',
    },
    content: {
        overflow: 'auto',
        padding: '20px',
        flexGrow: 1,
    },
    footer: {
        borderTop: "1px solid #efefef",
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        paddingBottom: '15px',
        paddingTop: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#FBFBFB ',
    },
    modalbtn: {
        borderRadius: '10px',
        width: '50%',
        backgroundColor: "#1C84EE"
    }
};

const validation = Yup.object({
    activityname: Yup.object().required("*Required").nullable(),
    activitytype: Yup.object().required("*Required").nullable(),
    semester: Yup.object().required("*Required").nullable(),
    // gradeachieved: Yup.object().required("*Required").nullable(),
    // helpwith: Yup.string().required("*Required").nullable(),
});

const Index = (props) => {
    const [loading, setloading] = useState(false)
    const [activityTypeOpts, setactivityTypeOpts] = useState([]);
    const [activityNameOpts, setactivityNameOpts] = useState([]);
    const [semesterOpts, setSemester] = useState();
    const [GradesOpts, setGrades] = useState();
    const [showAlert, setshowAlert] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [hidesemester, setHidesemester] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        props?.getActivities();
        props?.getActivity();
    }, []);


    const filterObject = (title, options, value, id) => {
        if (value === "" || value === undefined) return;
        let filtered = options && options?.filter((options) => options?.label?.toString() === value?.toString());
        if (filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: id, })
        }
    };

    const handleClose = () => {
        props?.handleClose()
    }
    const { handleSubmit, reset, clearErrors, watch, control, formState: { errors }, setValue } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(validation),
    });
    const { dirtyFields } = useFormState({
        control
    });
    let activityTypeObserver = watch("activitytype")
    let activityNameObserver = watch("activityname")
    const Description = watch("helpwith");
    useEffect(() => {
        setInitialValue();
        if (activityTypeObserver && dirtyFields.activityType && activityNameObserver) {
            setValue('activityName', null)
        }
    }, [activityTypeObserver])

    useEffect(() => {
        if (props?.activityNames && props?.activity && props?.getphasetime && props?.studentGrades) {
            setInitialValue();
        }
    }, [props?.activityNames, props?.activity, props?.getphasetime, props?.studentGrades])

    const selectedSemester = watch("semester");
    let currentSemester = props?.getstudentplan && props?.getstudentplan[0]?.planInfo?.activePhaseId === selectedSemester?.value

    const onSubmit = (data) => {
        setButtonDisabled(true);
        props?.handleSubmit(data, props?.EditData)
    };
    const setInitialValue = () => {
        let activityNameOptions = [];
        let activityTypeOptions = [];
        let phasetime = [];
        let grades = [];

        props?.activityNames?.length > 0 && props?.activityNames?.filter(x => x?.activityTypeId === activityTypeObserver?.id).map((x, key) => {
            let temp = { label: x.activityName, value: x.activityName }
            activityNameOptions.push(temp);
        })
        setactivityNameOpts(activityNameOptions)

        props?.activity?.length > 0 && props?.activity?.map((x) => {
            let temp = { label: x.activityType, value: x.activityType, id: x.id }
            activityTypeOptions.push(temp);
        })
        setactivityTypeOpts(activityTypeOptions)

        props?.getphasetime !== undefined && props?.getphasetime.length > 0 && props?.getphasetime.map((x) => {
            var temp = { label: x.phaseTitle, value: x.phaseId, isPassed: x.isPassed }
            phasetime.push(temp);
        })
        setSemester(phasetime)

        props?.studentGrades !== undefined && props?.studentGrades.length > 0 && props?.studentGrades.map((x) => {
            var temp = { label: x.grade, value: x.grade }
            grades.push(temp);
        })
        setGrades(grades)
        setloading(false)
        setButtonDisabled(false)

    }

    useEffect(() => {
        if (semesterOpts != undefined) {
            filterObject("semester", semesterOpts, props?.semesterData?.title, props?.semesterData?.id);
        }
    }, [semesterOpts, props?.semesterData])

    useEffect(() => {
        if (props?.isActivityError) {
            setshowAlert(true)
            setButtonDisabled(false)

        }
    }, [props?.isActivityError])

    const filterObjectCourse = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.activityName?.toString() === value?.toString()
            );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.activityName, value: filtered[0]?.activityName });
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const filterObjectType = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
            (options) => options?.activityType?.toString() === value?.toString()
        );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.activityType, id: filtered[0]?.id });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };

    const filterObjectGrade = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
            (options) => options?.grade?.toString() === value?.toString()
        );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.grade, value: filtered[0]?.id });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };

    useEffect(() => {
        if (props?.EditData) {
            filterObjectCourse("activityname", props?.activityNames, props?.EditData?.activityName);
            filterObjectType("activitytype", props?.activity, props?.EditData?.activityType);
            filterObjectGrade("gradeachieved", props?.studentGrades, props?.EditData?.grade);
            setValue("helpwith", props?.EditData?.description)

            let activityNameOptions = [];
            let activityTypeObserver = watch("activitytype")

            props?.activityNames?.length > 0 && props?.activityNames?.filter(x => x?.activityTypeId === activityTypeObserver?.id).map((x, key) => {
                let temp = { label: x.activityName, value: x.activityName }
                activityNameOptions.push(temp);
            })
            setactivityNameOpts(activityNameOptions)

        }
    }, [props?.EditData])

    return (
        <Modal style={stylesCourse.modal} open={props?.show} onClose={handleClose}>
            <div style={stylesCourse.paper} className="paperStyle-popup">
                {loading && <Loader />}
                <div style={stylesCourse.header}>
                    <Typography variant="h6" className="ModalName">{props?.EditData ? "Edit Activity" : "Add Activity"}</Typography>
                    <div style={{ "position": "absolute", "right": "10px" }} onClick={handleClose}>
                        <CloseIcon sx={{ fill: 'grey', fontSize: '2rem', cursor: 'pointer' }} />
                    </div>
                </div>
                {showAlert && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
                <div style={stylesCourse.content}>
                    <form id='my-course' onSubmit={handleSubmit(onSubmit)}>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="activitytype"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <Select id="activitytype" name="advisor"{...field} options={activityTypeOpts} 
                                        menuPortalTarget={document.body} // Renders menu at the root level maxMenuHeight={200}
                                            onChange={(selectedOption) => {
                                                setValue('activityname', '');
                                                field.onChange(selectedOption);
                                            }}
                                            placeholder={ACTIVITY_TYPE_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                menu: (provided, state) => ({ ...provided, minHeight: '50px', overflowY: 'auto', zIndex: "999999999999999999999999999999" }),
                                                menuPortal: (base) => ({ ...base, zIndex: "999999999999999999999999999999" }), 

                                            }}
                                        />
                                        {errors?.activitytype && <div className="error-text" >{errors?.activitytype?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="activityname"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <CreatableSelect options={activityNameOpts}
                                        menuPortalTarget={document.body} // Renders menu at the root level maxMenuHeight={200}
                                        {...field} closeMenuOnSelect={true}
                                            isClearable={false} maxMenuHeight={200}
                                            placeholder={ACTIVITY_NAME_LABEL} id="activityname" classNamePrefix="mySelect" components={{
                                                ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, Input: (props) => (
                                                    <components.Input {...props} maxLength={50} />),
                                            }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', maxWidth: '380px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                menu: (provided, state) => ({...provided, minHeight: '50px',overflowY: 'auto',zIndex:"99999999999" }),
                                                menuPortal: (base) => ({ ...base, zIndex: "999999999999999999999999999999" }), 
                                            }} />
                                        {errors?.activityname && <div className="error-text" >{errors?.activityname?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="semester"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <Select name="semester" id="semester" options={semesterOpts} placeholder="Semester"  {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false} isDisabled={props?.EditData || props?.SemsterAdd} maxMenuHeight={130}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                menu: (provided, state) => ({ ...provided, overflowY: 'auto', zIndex: "99999999999" }),

                                            }}
                                        />
                                        {errors?.semester && <div className="error-text" >{errors?.semester?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        {(watch("semester")?.isPassed === true || currentSemester === false) && (
                            <>
                                <div className="row m-0 mt-3">
                                    <Controller control={control} name="gradeachieved"
                                        render={({ field }) => (
                                            <div className="input-floating-label">
                                                <Select name="gradeachieved" id="gradeachieved" options={GradesOpts} placeholder="Grade Achieved" {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                                    closeMenuOnSelect={true} isClearable={true}
                                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                        menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
                                                    }}
                                                />
                                                <p className="gradeLabel mt-1 mb-0" >Optional. Visible only to advisors you have hired</p>
                                                {errors?.gradeachieved && <div className="error-text" >{errors?.gradeachieved?.message}</div>}
                                            </div>
                                        )} />
                                </div>
                            </>
                        )}
                        {(watch("semester")?.isPassed !== true || currentSemester) && (
                            <div className="row m-0 mt-3">
                                <Controller control={control} name="helpwith"
                                    render={({ field }) => (
                                        <div className={screenWidth < 320 ? 'describer' : 'input-floating-label'}>
                                            <textarea refs="helpwith" {...field} maxLength={250} className={"textarea--primary textbox--rounded input"} name="Description"
                                                placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                            <label >What do you need help with ?</label>
                                            <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <p className="gradeLabel mt-1 mb-0" >Optional. Visible only to connected advisors</p>
                                                <p className="gradeLabel mt-1 mb-0" >{Description?.length ? Description?.length : 0} / 250</p>
                                            </div>
                                            {errors?.helpwith && <div className="error-text" >{errors?.helpwith?.message}</div>}
                                        </div>
                                    )} />
                            </div>
                        )}
                    </form>
                </div>
                <div style={stylesCourse.footer}>
                    <Button type="submit" form="my-course" style={stylesCourse.modalbtn} disabled={isButtonDisabled} className="popup-btn" id="submitactivity"  >{props?.EditData ? "Edit Activity" : "Add Activity"}</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { activity, activityNames, getphasetime, studentGrades } = commonData;
    const { } = userProfileData;
    const { isActivityError, getstudentplanphase } = studentData;
    return { activity, activityNames, getphasetime, studentGrades, isActivityError, getstudentplanphase };
};

export default connect(mapStateToProps, { getActivities, getActivity, getPhasetime })(withRouter(Index));
