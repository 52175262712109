import React, { useEffect, useState } from 'react'
import '../milestone.less'
import { Button } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';
import Select, { components } from "react-select";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import {
    DeleteMileStoneNotesStatus, CreateNotesStatus, updateReadUpdateStatus, GetCourseId, GetCourseIdStatus,
    DeleteMileStoneResourceStatus, GetMilestoneByCourse, resetCourseMilestoneData, GetMilestoneByActivity,
    GetMileStoneByCourseStatus, GetMileStoneByActivityStatus, createMilestonforCourseStatus, viewDashboardCount, DeleteMileStoneStatus
} from "../../../../../redux/actions";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Loader from '../../../../../components/Loader';
import CourseDetails from './courseDetails'
import AddMilestone from '../../../../../componentsNew/Common/addMilestone';
import CustomAlert from '../../../../../components/CustomAlert';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>{children}</Box>
            )}
        </Typography>
    );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
// Select Styles
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const params = useParams();
    const location = useLocation();
    const PhaseData = location?.state?.courseData;
    const CourseData = location?.state?.PhaseData;
    const StudentView = location?.state?.studentView ?? false;
    const [loading, setloading] = useState(false);
    const [tabs, setTabs] = useState(0);
    const [selectedOption, setSelectedOption] = useState([]);
    const [newMiles, setnewMiles] = useState(false);
    const [Menulists, setMenulists] = useState([]);
    const [milestoneshow, setmilestoneshow] = useState(false)
    const navigate = useNavigate();
    const Openmilestone = () => setmilestoneshow(true);
    const Closemilestone = () => setmilestoneshow(false);
    const handleClick = () => {
        Closemilestone()
    };

    useEffect(() => {
        return () => {
            props.resetCourseMilestoneData()
        }
    }, [])

    useEffect(() => {
        setloading(true);
        let payload = {
            "planId": params.id,
            "courseId": location?.pathname?.split('/').pop()
        };
        props?.GetMilestoneByCourse(payload);

        if (CourseData?.planId && CourseData?.phaseId && CourseData?.courseId) {
            setloading(true);
            let payload = {
                "planId": CourseData?.planId,
                "phaseId": CourseData?.phaseId
            }
            props?.GetCourseId(payload, CourseData?.courseId)
        }
        let payloadCount = {
            // "postedBy": props?.data?.id,
            "updateType": "milestone",
            "courseId": location?.pathname?.split('/').pop(),
            "planId": params.id,
        };
        props?.viewDashboardCount(payloadCount);
    }, []);

    useEffect(() => {
        if (props.createCourseMilestoneStatus) {
            props.createMilestonforCourseStatus(false)
            let payload = {
                "planId": params.id,
                "courseId": location?.pathname?.split('/').pop()
            };
            props?.GetMilestoneByCourse(payload);
            Closemilestone()
            setnewMiles(true)
        }
    }, [props.createCourseMilestoneStatus])

    useEffect(() => {
        if (props?.getphasecourse && props?.getphasecoursestatus) {
            setloading(true)
            let payload = {
                "planId": props?.getphasecourse?.planId,
                "courseId": props?.getphasecourse?.id
            }
            props?.GetMilestoneByCourse(payload)
            props?.GetCourseIdStatus(false)
        }
    }, [props?.getphasecourse, props?.getphasecoursestatus])

    useEffect(() => {
        if (PhaseData?.courseName) {
            setloading(true)
            let payload = {
                "planId": params.id,
                "courseId": location?.pathname?.split('/').pop()
            }
            props?.GetMilestoneByCourse(payload)
        }
    }, [props?.isactivityMilestoneStatus, props?.getphasecourse, props?.getphasecoursestatus])

    useEffect(() => {
        let payload = {
            "planId": location?.state?.courseData?.planId,
            "courseId": location?.state?.courseData?.id
        }
        if (props?.isDeleteResource) {
            props?.DeleteMileStoneResourceStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isMileStoneCourseStatus && props?.isCourseMileStone) {
            const lastIndex = props?.isCourseMileStone?.length - 1;
            const newMenuLists = props?.isCourseMileStone && props?.isCourseMileStone?.map(course => (
                { value: course.id, label: course.milestoneTitle }
            ));
            setMenulists(newMenuLists);
            const initialSelectedOption = newMenuLists[0];
            setSelectedOption(initialSelectedOption);
            if (newMiles) {
                setTabs(lastIndex);
                setnewMiles(false);
            }
            props?.GetMileStoneByCourseStatus(false);
        }
        if (props?.isMileStoneCourseStatus) {
            setloading(false);
            props?.GetMileStoneByCourseStatus(false);
        }
        if (props?.isUpdateNotes) {
            props?.CreateNotesStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isReplyNotes) {
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.viewedUpdate) {
            props?.updateReadUpdateStatus(false)
            props?.GetMilestoneByCourse(payload)
        }
        if (props?.isMilestoneDeleted) {
            setTabs(0);
            props?.GetMilestoneByCourse(payload)
            props?.DeleteMileStoneStatus(false)
        }

    }, [props?.isCourseMileStone, props?.isMileStoneCourseStatus, props?.isMilestoneDeleted, props?.isDeleteResource, props?.deleteNotes, props?.isUpdateNotes, props?.isReplyNotes, props?.viewedUpdate])


    useEffect(()=>{
        if(location?.state?.milestoneData?.milestoneTitle && location?.state?.milestoneData?.id){
            const milestoneIndex = props?.isCourseMileStone?.findIndex(
                milestone => milestone?.id === location?.state?.milestoneData?.id
            );
            console.log(milestoneIndex,"milestoneIndex");
    
            if (milestoneIndex !== -1) {
                setTabs(milestoneIndex); // Set the tab to the found index
            }
        }
      },[location?.state,props?.isCourseMileStone])

    // Tabs Change
    const handleSwitchTabs = (event, newValue) => {
        const selectedMilestone = props?.isCourseMileStone?.[newValue];
        const milestoneId = selectedMilestone?.id;
        setTabs(newValue);
        setSelectedOption(Menulists[newValue]);
        let payloadCount = {
            // "postedBy": props?.data?.id,
            "updateType": "milestone",
            "courseId": location?.pathname?.split('/').pop(),
            "planId": params.id,
            milestoneId : milestoneId

        };
        props?.viewDashboardCount(payloadCount);
    }
    // Tabs Change
    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        const newIndex = Menulists?.findIndex(item => item?.value === selectedOption?.value);
        setTabs(newIndex);
    };

    const handlegoBack = (e) => {
        e.preventDefault();
        navigate(-1)
    }
    const courseName = location?.state?.courseData?.courseName ||PhaseData?.courseName || props?.getphasecoursecourse?.courseName || location?.state?.data?.courseName;

    return (
        <>
            {loading && <Loader />}
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            {milestoneshow &&
                <AddMilestone show={milestoneshow} onHide={() => Closemilestone()} handleClose={handleClick} />
            }
            <div className="milestone_Page">
                <div className="milestone_Page_Top">
                    <div className="milestone_Page_Top_Desktop">
                        <div className="milestone_Page_Top_Desktop_Title">
                            <p><span id="gobackbtn" onClick={(e) => handlegoBack(e)} ><KeyboardBackspaceIcon /></span>Course:{" "}{courseName || ""}</p>
                        </div>
                        <div className="milestone_Page_Top_Desktop_Btncnt">
                            <Button disabled={true} className="milestone_Page_Top_Desktop_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                        </div>
                    </div>
                    <div className="milestone_Page_Top_Mobile">
                        <div className="milestone_Page_Top_Mobile__Section">
                            <div className="milestone_Page_Top_Mobile__Section_Title">
                                <p>My Work</p>
                            </div>
                        </div>
                        <div className="milestone_Page_Top_Mobile_Btncnt">
                            <Button disabled={true} className="milestone_Page_Top_Mobile_Btncnt_PlanBtn">Enable for puchase for $4.99</Button>
                        </div>
                    </div>
                </div>
                {props?.isCourseMileStone?.length > 0 &&
                    <div className="milestone_Page_Mid">
                        <div className="milestone_Page_Mid_Desktop">
                            <div className="milestone_Page_Mid_Desktop_TabCnt">
                                <Tabs value={tabs} onChange={handleSwitchTabs} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example">
                                    {props?.isCourseMileStone && props?.isCourseMileStone?.map((milestone) => {
                                        return (
                                            <Tab wrapped key={milestone?.id} label={milestone?.milestoneTitle} disableRipple className="Tab" />
                                        )
                                    })}
                                </Tabs>
                                {!StudentView &&
                                    <span onClick={Openmilestone} style={{ "cursor": "pointer" }} id="createmilestone">
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="25" height="25" rx="5" fill="#1C84EE" />
                                            <path d="M12.5 6.25C12.7072 6.25 12.9059 6.33231 13.0524 6.47882C13.1989 6.62534 13.2812 6.82405 13.2812 7.03125V11.7188H17.9688C18.176 11.7188 18.3747 11.8011 18.5212 11.9476C18.6677 12.0941 18.75 12.2928 18.75 12.5C18.75 12.7072 18.6677 12.9059 18.5212 13.0524C18.3747 13.1989 18.176 13.2812 17.9688 13.2812H13.2812V17.9688C13.2812 18.176 13.1989 18.3747 13.0524 18.5212C12.9059 18.6677 12.7072 18.75 12.5 18.75C12.2928 18.75 12.0941 18.6677 11.9476 18.5212C11.8011 18.3747 11.7188 18.176 11.7188 17.9688V13.2812H7.03125C6.82405 13.2812 6.62534 13.1989 6.47882 13.0524C6.33231 12.9059 6.25 12.7072 6.25 12.5C6.25 12.2928 6.33231 12.0941 6.47882 11.9476C6.62534 11.8011 6.82405 11.7188 7.03125 11.7188H11.7188V7.03125C11.7188 6.82405 11.8011 6.62534 11.9476 6.47882C12.0941 6.33231 12.2928 6.25 12.5 6.25Z" fill="white" />
                                        </svg>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="milestone_Page_Mid_Mobile">
                            <div className="input-floating-label">
                                <Select options={Menulists} onChange={handleSelectChange}
                                    defaultValue={Menulists[0]}
                                    value={selectedOption}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}
                                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                                        </div>
                                    )}
                                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                />
                            </div>
                            <Button className="milestone_Page_Top_Mobile_Btncnt_PlanBtn" id="createmilestone" onClick={Openmilestone} >Create Milestone</Button>
                        </div>
                    </div>
                }
                {props?.isCourseMileStone?.length > 0 ? (
                    <div className="mywork_Page_Btm">
                        {props?.isCourseMileStone?.map((milestoneData, index) => {
                            return (
                                <TabPanel value={tabs} index={index}>
                                    <CourseDetails data={milestoneData} StudentView={StudentView} />
                                </TabPanel>
                            )
                        })}
                    </div>
                ) : (
                    <>
                        {StudentView ?
                            <div className='MileStoneEmptyPage' >
                                <div className='MileStoneEmptyPage__Card'>
                                    <div className='MileStoneEmptyPage__Card__Titlecnt'>
                                        <p>You do not have  any Milestones for this course</p>
                                    </div>
                                </div>
                            </div>
                            :
                            !loading &&
                            <div className='MileStoneEmptyPage' >
                                <div className='MileStoneEmptyPage__Card'>
                                    <div className='MileStoneEmptyPage__Card__Titlecnt'>
                                        <p>You do not have created any Milestones for this course</p>
                                    </div>
                                    <div className='MileStoneEmptyPage__Card__btncnt' id="createmilestone" onClick={Openmilestone}>
                                        <p>Create Milestone</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    )
}
const mapStateToProps = ({ studentData }) => {
    const { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus, getphasecourse, getphasecoursestatus, isMilestoneDeleted } = studentData;
    return { isDeleteResource, deleteNotes, isUpdateNotes, isReplyNotes, isCourseMileStone, viewedUpdate, isMileStoneCourseStatus, isactivityMilestoneStatus, createCourseMilestoneStatus, getphasecourse, getphasecoursestatus, isMilestoneDeleted };
};

export default connect(mapStateToProps, { DeleteMileStoneNotesStatus, CreateNotesStatus, GetCourseId, DeleteMileStoneStatus, GetCourseIdStatus, updateReadUpdateStatus, DeleteMileStoneResourceStatus, GetMilestoneByCourse, GetMilestoneByActivity, GetMileStoneByCourseStatus, GetMileStoneByActivityStatus, createMilestonforCourseStatus, viewDashboardCount, resetCourseMilestoneData })(Index);     
