import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS, CUSTOM_ALERT_SHOW, CUSTOM_ALERT_CLOSE, ALERT_SHOW, ALERT_HIDE
} from "../constants/CommonTypes";
import {
  SET_ISGOAL_SUCCESS_STATUS, CREATE_PLAN_STATUS, CREATE_PLAN_PHASE_STATUS, GET_STUDENT_PLAN, EDIT_PLAN_COURSE_ERROR, GET_STUDENT_PLAN_STATUS,
  GET_STUDENT_PLAN_PHASE, Edit_PLAN_STATUS, EDIT_PLAN_COURSE, GET_STUDENT_PLAN_PHASE_STATUS, GET_STUDENT_PLAN_DATA_ID, CREATE_PLAN_COURSE,
  GET_STUDENT_PLAN_DATA_ID_STATUS, GET_STUDENT_PLAN_LOAD, GET_STUDENT_PLAN_LOAD_STATUS,PLAN_ACESS_STUDENT_ERROR,PLAN_ACESS_STUDENT,PLAN_ACESS_STUDENT_STATUS,
  GET_PHASE_COURSE_ID, GET_PHASE_COURSE_ID_STATUS, GET_STUDENT_PLAN_DATA_ID_NEXT, GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS,
  GET_COURSE_LIST, GET_COURSE_LIST_STATUS, GET_MILESTONE_LIST, GET_MILESTONE_STATUS, GET_USER_GOALS, GET_USER_GOALS_STATUS,
  GET_ACTIVITY_LIST_STATUS, GET_ACTIVITY_LIST, CREATE_PLAN_ACTIVITY, EDIT_PLAN_ACTIVITY, EDIT_PLAN_PHASE, GET_INVITE_ADVISOR, EDIT_PLAN_PHASE_ERROR, DELETE_PLAN_PHASE, DELETE_PLAN_COURSE, DELETE_PLAN_ACTIVITY, INVITE_ADVISOR_BY, GET_INVITE_ADVISOR_STATUS,
  INVITE_ADVISOR_ERROR, CREATE_PLAN_PHASE_STATUS_ERROR, GET_CONNECTED_PARENT, NEW_PLAN_ID, NEW_PARENT_PLAN_ID, GET_PHASE_ACTIVITY_ID, GET_PHASE_ACTIVITY_ID_STATUS, GET_MILESTONE_BY_COURSE, GET_MILESTONE_BY_COURSE_STATUS, CREATE_COURSE_MILESTONE, UPDATE_MILESTONE_NOTES, GET_MILESTONE_TYPE, CREATE_COURSE_MILESTONE_STATUS, UPDATE_COURSE_MILESTONE, UPDATE_COURSE_MILESTONE_STATUS
  , DELETE_MILESTONE, EDIT_MILESTONE_NOTES, DELETE_MILESTONE_NOTES, GET_MILESTONE_BY_ACTIVITY, GET_MILESTONE_BY_ACTIVITY_STATUS, CREATE_ACTIVITY_MILESTONE, UPDATE_MILESTONE_RESOURCE, DELETE_MILESTONE_RESOURCE, ADD_NEW_MILESTONE_RESOURCE, GET_COURSE_BY_ID, GET_COURSE_BY_ID_STATUS, READ_UPDATE_STATUS, CREATE_PLAN_COURSE_ERROR
  , CREATE_PLAN_ACTIVITY_ERROR, ADD_RESOURCE_ERROR, GET_PLAN_STATUS, DELETE_PLAN, ERROR_DELETE_PLAN, UPDATE_PLAN_STATUS, UPDATE_PHASE_STATUS, MILESTONE_STATUS_UPDATE, PHASE_ERROR, UPDATE_PLAN_ERROR_STATUS, REPLY_MILESTONE_NOTES, UPDATE_MILESTONE_ERROR, PLAN_ADVISOR_STATUS, PLAN_ADVISOR_DATA, STUDENT_COURSE_DATA
  ,STUDENT_COURSE_STATUS,PLAN_APPROVE_STATUS,PLAN_APPROVE_ERROR, COURSE_APPROVE_STATUS, PLAN_REJECT_STATUS, PLAN_REJECT_ERROR, COURSE_APPROVE_ERROR,PLAN_ACESS_ADVISOR_ERROR,PLAN_ACESS_ADVISOR,ADD_PLAN_ADVISOR_ERROR,ADD_PLAN_ADVISOR , REMOVE_PLAN_ADVISOR , REMOVE_PLAN_ADVISOR_ERROR,
   PLAN_CONFIRM_DROP_STATUS, PLAN_CONFIRM_DROP_ERROR, ACTIVITIES_APPROVE_STATUS, ACTIVITIES_APPROVE_ERROR, COURSE_REJECT_STATUS, ACTIVITY_REJECT_STATUS,COURSE_DROP_STATUS,COURSE_DROP_ERROR_STATUS,ACTIVITY_DROP_STATUS,ACTIVITY_DROP_ERROR_STATUS, RESET_STUDENT_PLAN, GET_STUDENT_PLAN_PAGINATED,
   GET_COURSE_LIST_PAGINATED,GET_DASHBOARD_COUNT,GET_DASHBOARD_COUNT_STATUS,GET_DASHBOARD_COUNT_ERROR_STATUS,GET_STUDENT_PLAN_ERROR_STATUS,GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT,
   GET_RESET_COUNTS_STUDENT,UPDATES_VIEW_STUDENT_COUNT
  } from "../constants/StudentActionTypes";
import axios from 'axios'
import { getUserProfile } from "../actions/Profile";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserGoals = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.get(BASE_URL + '/user-service/v1/student/goals/load').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_USER_GOALS, payload: data.recordInfo });
        dispatch({ type: GET_USER_GOALS_STATUS, payload: true });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
};

export const getUserGoalsStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_USER_GOALS_STATUS, payload: status });
  }
};

export const setUserGoals = (requestParams, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START })
    axios.post(BASE_URL + '/user-service/v1/student/goals/update', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SET_ISGOAL_SUCCESS_STATUS, payload: true });
        if (value === "profile") {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 6000)
        }

      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
};

export const setIsGoalSuccess = status => {
  return (dispatch) => {
    dispatch({ type: SET_ISGOAL_SUCCESS_STATUS, payload: status });
  }
};

export const CreateParentPlan = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/parent/plans/create', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_PLAN_STATUS, payload: true })
        dispatch({ type: NEW_PLAN_ID, payload: data.planId })

        let value = {
          "searchString": "",
          "pageNo": 1,
          "limit": 10,
          "status": [],
          "sortOrder": "latest",
          "studentId": localStorage?.getItem("parentViaChild")
        }
        // dispatch(GetStudentPlan(value));
        dispatch(GetStudentPlanOfParent(value));
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}

export const CreatePlan = (requestParams, studentId = null) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/student/plans/create', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_PLAN_STATUS, payload: true })
        dispatch({ type: NEW_PLAN_ID, payload: data.planId })
        let value = {
          "searchString": "",
          "pageNo": 1,
          "limit": 10,
          "status": [],
          "sortOrder": "latest",
          "studentId": studentId
        }
        dispatch(GetStudentPlan(value));
        dispatch(GetStudentPlanOfParent(value));
        let values = { "planId": data.planId }
        dispatch(GetStudentPlanPhase(values))
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const CreatePlanStatus = params => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_STATUS, payload: params });
  }
};
export const ClearPlanId = () => {
  return (dispatch) => {
    dispatch({ type: NEW_PLAN_ID, payload: "" });
    dispatch({ type: NEW_PARENT_PLAN_ID, payload: "" })

  }
};
export const EditPlan = (requestParams, id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/plans/update/' + id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: Edit_PLAN_STATUS, payload: true })
        let value = {
          "searchString": "",
          "pageNo": 1,
          "limit": 10,
          "status": [],
          "sortOrder": "latest",
          "studentId": localStorage?.getItem("parentViaChild")
        }
        dispatch(GetStudentPlan(value));
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const EditPlanStatus = status => {
  return (dispatch) => {
    dispatch({ type: Edit_PLAN_STATUS, payload: status });
  }
};
export const CreatePhase = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/phases/create', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_PLAN_PHASE_STATUS, payload: true })
      } else {
        dispatch({ type: CREATE_PLAN_PHASE_STATUS, payload: false })
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: CREATE_PLAN_PHASE_STATUS_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const CreatePhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_PHASE_STATUS, payload: status });
  }
};
export const CreatePhaseStatuserror = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_PHASE_STATUS_ERROR, payload: status });
  }
};
export const GetStudentPlan = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/plans', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        if(data?.currentpage == 1){
          dispatch({ type: GET_STUDENT_PLAN, payload: data })
        } else {
          dispatch({ type: GET_STUDENT_PLAN_PAGINATED, payload: data })
        }
        dispatch({ type: GET_STUDENT_PLAN_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}

export const GetStudentPlanOfParent = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/network/plans', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        if(data?.currentpage == 1){
          dispatch({ type: GET_STUDENT_PLAN, payload: data })
        } else {
          dispatch({ type: GET_STUDENT_PLAN_PAGINATED, payload: data })
        }
        dispatch({ type: GET_STUDENT_PLAN_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}

export const resetStudentPlanData = data => {
  return (dispatch) => {
    dispatch({ type: RESET_STUDENT_PLAN });
  }
};
export const GetStudentPlanStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_STATUS, payload: status });
  }
};
export const StudentPlanErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: status });
  }
};
export const GetStudentPlanPhase = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/phases', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_STUDENT_PLAN_PHASE, payload: data.data })
        dispatch({ type: GET_STUDENT_PLAN_PHASE_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const resetStudentPlanPhaseData = data => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_PHASE, payload: data });
  }
};
export const GetStudentPlanPhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_PHASE_STATUS, payload: status });
  }
};
export const GetStudentPlanId = (requestParams, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await axios.get(BASE_URL + '/student-plans/v1/plans/load/' + requestParams);
      const { headers: { statusCode, message } } = data;

      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        if (value === "nextpage") {
          dispatch({ type: GET_STUDENT_PLAN_DATA_ID_NEXT, payload: data.recordInfo });
          dispatch({ type: GET_STUDENT_PLAN_DATA_ID_STATUS, payload: true });
        }
        dispatch({ type: GET_STUDENT_PLAN_DATA_ID, payload: data.recordInfo });
        dispatch({ type: GET_STUDENT_PLAN_DATA_ID_STATUS, payload: true });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    } catch (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        dispatch({ type: GET_STUDENT_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }
  }
}
export const GetStudentPlanIdStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_DATA_ID_STATUS, payload: status });
  }
};
export const GetStudentPlanIdNextStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS, payload: status });
  }
};
export const resetPlanIdData = () => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_DATA_ID, payload: [] });
  }
};
export const GetLoadPhase = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/phases', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_STUDENT_PLAN_LOAD, payload: data.data })
        dispatch({ type: GET_STUDENT_PLAN_LOAD_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const GetLoadPhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_STUDENT_PLAN_LOAD_STATUS, payload: status });
  }
};
export const GetCourseId = (requestParams, Id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/load/'+Id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PHASE_COURSE_ID, payload: data.recordInfo })
        dispatch({ type: GET_PHASE_COURSE_ID_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const GetCourseIdStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_PHASE_COURSE_ID_STATUS, payload: status });
  }
};
export const getSpecificCoursebyId = (requestParams, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/load/' + value, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_COURSE_BY_ID, payload: data.data })
        dispatch({ type: GET_COURSE_BY_ID_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const updateGetSpecificCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_COURSE_BY_ID_STATUS, payload: status });
  }
};
export const GetActivityId = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/activities/load', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PHASE_ACTIVITY_ID, payload: data.data })
        dispatch({ type: GET_PHASE_ACTIVITY_ID_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const GetActivityIdStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_PHASE_ACTIVITY_ID_STATUS, payload: status });
  }
};
export const GetCourseList = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/search', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_COURSE_LIST_STATUS, payload: true })

        // if(data?.currentpage == 1){
          dispatch({ type: GET_COURSE_LIST, payload: data })
        // } else {
          dispatch({ type: GET_COURSE_LIST_PAGINATED, payload: data })
        // }
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const GetCourseListStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_COURSE_LIST_STATUS, payload: status });
  }
};

export const GetParentCourseList = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/network/course/access', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_COURSE_LIST_STATUS, payload: true })

        // if(data?.currentpage == 1){
          dispatch({ type: GET_COURSE_LIST, payload: data })
        // } else {
          dispatch({ type: GET_COURSE_LIST_PAGINATED, payload: data })
        // }
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const GetMilestoneList = requestParams => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/milestones/search', requestParams);
      const { headers: { statusCode, message } } = data;

      if (statusCode === "200") {
        console.log(data);
        
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MILESTONE_LIST, payload: data.data });
        dispatch({ type: GET_MILESTONE_STATUS, payload: true });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    } catch (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    }
  }
}
export const GetMilestoneListStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_MILESTONE_STATUS, payload: status });
  }
};
export const CurrentMilestoneParent = requestParams => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/network/milestones', requestParams);
      const { headers: { statusCode, message } } = data;

      if (statusCode === "200") {
        console.log(data);
        
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MILESTONE_LIST, payload: data.data });
        dispatch({ type: GET_MILESTONE_STATUS, payload: true });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    } catch (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    }
  }
}

export const GetActivityList = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/activities/search', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_ACTIVITY_LIST, payload: data.data })
        dispatch({ type: GET_ACTIVITY_LIST_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const GetActivityParentList = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/network/activities', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_ACTIVITY_LIST, payload: data.data })
        dispatch({ type: GET_ACTIVITY_LIST_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const GetActivityListStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_ACTIVITY_LIST_STATUS, payload: status });
  }
};
export const CreateCourse = (requestParams, showSpecificAlert) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/create', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_PLAN_COURSE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)

      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: CREATE_PLAN_COURSE_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        if(showSpecificAlert) {
          dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
          setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 3000)
        } else {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const CreateCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_COURSE, payload: status });
  }
};
export const CreateCourseErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_COURSE_ERROR, payload: status });
  }
};
export const EditCourse = (requestParams, id, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/update/' + id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: EDIT_PLAN_COURSE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: EDIT_PLAN_COURSE_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error?.response?.data?.headers?.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error?.response?.data?.headers?.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }

    });
  }
}
export const EditCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_COURSE, payload: status });
  }
};
export const ERROREditCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_COURSE_ERROR, payload: status });
  }
};
export const CreateActivity = (requestParams, showSpecificAlert) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/activities/create', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_PLAN_ACTIVITY, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: CREATE_PLAN_ACTIVITY_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        if(showSpecificAlert){
          dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
          setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 3000)
        } else {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const CreateActivityStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_ACTIVITY, payload: status });
  }
};
export const CreateActivityErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_PLAN_ACTIVITY_ERROR, payload: status });
  }
};
export const EditActivity = (requestParams, id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/activities/update/' + id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: EDIT_PLAN_ACTIVITY, payload: true })
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: CREATE_PLAN_ACTIVITY_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const EditActivityStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_ACTIVITY, payload: status });
  }
};
export const EditPhase = (requestParams) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/phases/update', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: EDIT_PLAN_PHASE, payload: true })
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      console.log("function error", error)
      dispatch({ type: EDIT_PLAN_PHASE, payload: false })
      dispatch({ type: EDIT_PLAN_PHASE_ERROR, payload: true })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error?.response?.data?.headers?.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error?.response?.data?.headers?.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }

    });
  }
}
export const EditPhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_PHASE, payload: status });
  }
};
export const ErrorEditPhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_PLAN_PHASE_ERROR, payload: status });
  }
};
export const DeletePhase = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/phases/delete', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_PLAN_PHASE, payload: true })
        // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: DELETE_PLAN_PHASE, payload: false })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const DeletePhaseStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_PLAN_PHASE, payload: status });
  }
};
export const DeleteCourse = (requestParams, id, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/courses/delete/' + id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_PLAN_COURSE, payload: true })
        if (value === "dashboard") {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: DELETE_PLAN_COURSE, payload: false })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}
export const DeleteCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_PLAN_COURSE, payload: status });
  }
};

export const DeleteActivity = (requestParams, id, value) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/student-plans/v1/activities/delete/' + id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_PLAN_ACTIVITY, payload: true })
        if (value === "dashboard") {
          dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
          setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: DELETE_PLAN_ACTIVITY, payload: false })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}

export const DeleteActivityStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_PLAN_ACTIVITY, payload: status });
  }
};


export const AdvisorInvite = requestParams => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(BASE_URL + '/user-service/v1/user-invite/advisor', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: INVITE_ADVISOR_BY, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
    }).catch(function (error) {
      dispatch({ type: INVITE_ADVISOR_BY, payload: false })
      if (error.response) {
        const { headers: { message, statusCode }, errorlist, } = error.response.data;
        dispatch({ type: INVITE_ADVISOR_ERROR, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      }
      console.log("Error****:", error.message);
    });
  }
}

export const AdvisorInviteStatus = status => {
  return (dispatch) => {
    dispatch({ type: INVITE_ADVISOR_BY, payload: status });
  }
};
export const AdvisorInviteErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: INVITE_ADVISOR_ERROR, payload: status });
  }
};

export const getInviteAdvisor = () => {
  return (dispatch) => {
    axios.get(BASE_URL + '/user-service/v1/user-invite/advisor').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_INVITE_ADVISOR, payload: data.data });
        dispatch({ type: GET_INVITE_ADVISOR_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
    });
  }
};

export const getInviteAdvisorStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_INVITE_ADVISOR_STATUS, payload: status });
  }
};

export const ConnectParent = () => {
  return (dispatch) => {
    axios.get(BASE_URL + '/user-service/v1/student/profile/accounts/viewparents').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_CONNECTED_PARENT, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const createMilestonforCourse = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/create/forcourse', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_COURSE_MILESTONE, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const createMilestonforCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_COURSE_MILESTONE_STATUS, payload: status });
  }
};

export const createMilestonforActivity = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/create/foractivity', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_ACTIVITY_MILESTONE, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const createMilestonforActivityStatus = status => {
  return (dispatch) => {
    dispatch({ type: CREATE_ACTIVITY_MILESTONE, payload: status });
  }
};

export const updateCourseMilestone = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_COURSE_MILESTONE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const updateCourseMileStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_COURSE_MILESTONE_STATUS, payload: status });
  }
};

export const GetMilestoneByCourse = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/loadbycourse', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MILESTONE_BY_COURSE, payload: data.data })
        dispatch({ type: GET_MILESTONE_BY_COURSE_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const resetCourseMilestoneData = () => {
  return (dispatch) => {
    dispatch({ type: GET_MILESTONE_BY_COURSE, payload: [] })
  }
};
export const GetMileStoneByCourseStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_MILESTONE_BY_COURSE_STATUS, payload: status });
  }
};

export const GetMilestoneByActivity = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/loadbyactivity', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MILESTONE_BY_ACTIVITY, payload: data.data })
        dispatch({ type: GET_MILESTONE_BY_ACTIVITY_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const resetActivityMilestoneData = data => {
  return (dispatch) => {
    dispatch({ type: GET_MILESTONE_BY_ACTIVITY, payload: data });
  }
};

export const GetMileStoneByActivityStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_MILESTONE_BY_ACTIVITY_STATUS, payload: status });
  }
};

export const CreateNotes = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/newupdate', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_MILESTONE_NOTES, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });


      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        dispatch({ type: UPDATE_MILESTONE_ERROR, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const CreateNotesStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MILESTONE_NOTES, payload: status });
  }
};

export const CreateNotesError = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MILESTONE_ERROR, payload: status });
  }
};

export const UpdateNotes = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/editupdate', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: EDIT_MILESTONE_NOTES, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const UpdateNotesStatus = status => {
  return (dispatch) => {
    dispatch({ type: EDIT_MILESTONE_NOTES, payload: status });
  }
};

export const updateReadUpdate = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/statusreadcount', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: READ_UPDATE_STATUS, payload: true })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const updateReadUpdateStatus = status => {
  return (dispatch) => {
    dispatch({ type: READ_UPDATE_STATUS, payload: status });
  }
};

export const getMilestoneTypes = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.get(BASE_URL + '/student-plans/v1/milestones/types').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MILESTONE_TYPE, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      console.log(error, "error");
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
    })
  }
}

export const DeleteMileStone = (requestParams, value) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/milestones/delete', requestParams);
      const { headers: { statusCode, message } } = data;
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_MILESTONE, payload: true });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    } catch (error) {
      dispatch({ type: DELETE_MILESTONE, payload: false });

      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    }
  }
}

export const DeleteMileStoneStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_MILESTONE, payload: status });
  }
};

export const DeleteMileStoneNotes = (requestParams, value) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/milestones/delete/milestoneupdate', requestParams);
      const { headers: { statusCode, message } } = data;
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_MILESTONE_NOTES, payload: true });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    } catch (error) {
      dispatch({ type: DELETE_MILESTONE_NOTES, payload: false });

      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    }
  }
}

export const DeleteMileStoneNotesStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_MILESTONE_NOTES, payload: status });
  }
};

export const addNewResource = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/newresource', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: ADD_NEW_MILESTONE_RESOURCE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      const { headers: { message, statusCode }, errorlist } = error.response.data;
      dispatch({ type: ADD_RESOURCE_ERROR, payload: true });
      dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
      dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
      setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      console.log("Error****:", error.message);
    });
  }
}

export const addNewResourceStatus = status => {
  return (dispatch) => {
    dispatch({ type: ADD_NEW_MILESTONE_RESOURCE, payload: status });
  }
};

export const addNewResourceerrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: ADD_RESOURCE_ERROR, payload: status });
  }
};

export const updateResource = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/editresource', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_MILESTONE_RESOURCE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        console.log(message);
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        dispatch({ type: ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
        setTimeout(() => { dispatch({ type: ALERT_HIDE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        
      }
      console.log("Error****:", error.message);
    });
  }
}

export const updateResourceStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MILESTONE_RESOURCE, payload: status });
  }
};

export const deleteResource = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/milestones/delete/milestoneresource', requestParams);
      const { headers: { statusCode, message } } = data;
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_MILESTONE_RESOURCE, payload: true });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    } catch (error) {
      dispatch({ type: DELETE_MILESTONE_RESOURCE, payload: false });

      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
      }
    }
  }
}

export const DeleteMileStoneResourceStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_MILESTONE_RESOURCE, payload: status });
  }
};

export const getPlanStatus = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.get(BASE_URL + '/student-plans/v1/plans/status').then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PLAN_STATUS, payload: data.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    })
  }
}

export const DeletePlan = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/plans/delete/' + id);
      const { headers: { statusCode, message } } = data;

      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DELETE_PLAN, payload: true });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    } catch (error) {
      dispatch({ type: ERROR_DELETE_PLAN, payload: true });
      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    }
  };
};

export const DeletePlanStatus = status => {
  return (dispatch) => {
    dispatch({ type: DELETE_PLAN, payload: status });
  }
};

export const ErrorDeletePlanStatus = status => {
  return (dispatch) => {
    dispatch({ type: ERROR_DELETE_PLAN, payload: status });
  }
};

export const planStatusUpdate = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/plans/update/status', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_PLAN_STATUS, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: UPDATE_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: UPDATE_PLAN_ERROR_STATUS, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    });
  }
}
export const planStatusUpdateStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PLAN_STATUS, payload: status });
  }
};
export const planStatusErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PLAN_ERROR_STATUS, payload: status });
  }
};
export const phaseStatusUpdate = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/phases/update/status', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: UPDATE_PHASE_STATUS, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: PHASE_ERROR, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: PHASE_ERROR, payload: true })
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    });
  }
}

export const phaseStatusUpdateStatus = status => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PHASE_STATUS, payload: status });
  }
};

export const phaseStatusErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: PHASE_ERROR, payload: status });
  }
};

export const milesStatusUpdate = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/status', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: MILESTONE_STATUS_UPDATE, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message, statusCode }, errorlist } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } });
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }); }, 3000);
      }
    });
  }
}
export const milesStatusUpdateStatus = status => {
  return (dispatch) => {
    dispatch({ type: MILESTONE_STATUS_UPDATE, payload: status });
  }
};

export const replyNotes = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/milestones/update/newupdate', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: REPLY_MILESTONE_NOTES, payload: true })
        dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
        setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const ReplyNotesStatus = status => {
  return (dispatch) => {
    dispatch({ type: REPLY_MILESTONE_NOTES, payload: status });
  }
};


export const getplanAdvisor = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/user-service/v1/network/loadprofiles', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_ADVISOR_STATUS, payload: true })
        dispatch({ type: PLAN_ADVISOR_DATA, payload: data.data })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const getplanAdvisorststus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_ADVISOR_STATUS, payload: status });
  }
};

export const getStudentDetail = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/network/plans/load/courseactivitydetails', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: STUDENT_COURSE_STATUS, payload: true })
        dispatch({ type: STUDENT_COURSE_DATA, payload: data })
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const resetStudentDetails = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT_COURSE_DATA, payload: [] });
  }
};
export const getStudentDetailstatus = status => {
  return (dispatch) => {
    dispatch({ type: STUDENT_COURSE_STATUS, payload: status });
  }
};

// export const getStudentToStudentDetail = requestParams => {
//   return async (dispatch) => {
//     dispatch({ type: FETCH_START });
//     await axios.post(BASE_URL + '/student-plans/v1/plans/loadaccessableplansofcurrentuser', requestParams).then(({ data }) => {
//       const { headers: { statusCode, message } } = data
//       if (statusCode === "200") {
//         dispatch({ type: FETCH_SUCCESS });
//         dispatch({ type: STUDENT_COURSE_STATUS, payload: true })
//         dispatch({ type: STUDENT_COURSE_DATA, payload: data })
//       } else {
//         dispatch({ type: FETCH_ERROR, payload: message });
//       }
//     }).catch(function (error) {
//       if (error.response) {
//         const { headers: { message } } = error.response.data;
//         dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
//       } else {
//         dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
//       }
//       console.log("Error****:", error.message);
//     });
//   }
// }

export const getStudentToStudentDetail = (requestParams, value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await axios.post(BASE_URL + '/student-plans/v1/plans/loadaccessableplansofcurrentuser' , requestParams);
      const { headers: { statusCode, message } } = data;

      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        if (value === "nextpage") {
          dispatch({ type: GET_STUDENT_PLAN_DATA_ID_NEXT, payload: data.recordInfo });
          dispatch({ type: GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS, payload: true });
        }
        dispatch({ type: GET_STUDENT_PLAN_DATA_ID, payload: data.recordInfo });
        dispatch({ type: GET_STUDENT_PLAN_DATA_ID_STATUS, payload: true });
      } else {
        dispatch({ type: FETCH_ERROR, payload: message });
      }
    } catch (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
    }
  }
}


export const planApprove = requestParams => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/plans/approve/'+ requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_APPROVE_STATUS, payload: true })
      } else {
        dispatch({ type: PLAN_APPROVE_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: PLAN_APPROVE_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const planApproveStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_APPROVE_STATUS, payload: status });
  }
};
export const planApproveErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_APPROVE_ERROR, payload: status });
  }
};

export const courseApprove = (id, requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/courses/approve/'+  id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: COURSE_APPROVE_STATUS, payload: true })
      } else {
        dispatch({ type: COURSE_APPROVE_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: COURSE_APPROVE_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const courseApproveStatus = status => {
  return (dispatch) => {
    dispatch({ type: COURSE_APPROVE_STATUS, payload: status });
  }
};

export const planAcessAdvisors = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/planaccess/advisors/load' ,requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_ACESS_ADVISOR, payload: data.data, status: true })
      } else {
        dispatch({ type: PLAN_ACESS_ADVISOR_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: PLAN_ACESS_ADVISOR_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const planAcessAdvisorsStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_ACESS_ADVISOR, payload: status });
  }
};
export const ResetPlanAccess = () => {
  return (dispatch) => {
    dispatch({ type: PLAN_ACESS_ADVISOR, payload: [] }); 
  };
};

export const planAcessAdvisorsError = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_ACESS_ADVISOR_ERROR, payload: status });
  }
};
export const addAdvisortoplan = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/planaccess/advisors/provideaccess' ,requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: ADD_PLAN_ADVISOR, payload: true })
      } else {
        dispatch({ type: ADD_PLAN_ADVISOR_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: ADD_PLAN_ADVISOR_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: ADD_PLAN_ADVISOR_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const addAdvisortoplanStatus = status => {
  return (dispatch) => {
    dispatch({ type: ADD_PLAN_ADVISOR, payload: status });
  }
};
export const addAdvisortoplanError = status => {
  return (dispatch) => {
    dispatch({ type: ADD_PLAN_ADVISOR_ERROR, payload: status });
  }
};
export const removeAdvisortoplan = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/planaccess/advisors/removeaccess' ,requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: REMOVE_PLAN_ADVISOR, payload: true })
      } else {
        dispatch({ type: REMOVE_PLAN_ADVISOR_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: REMOVE_PLAN_ADVISOR_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const removeAdvisortoplanStatus = status => {
  return (dispatch) => {
    dispatch({ type: REMOVE_PLAN_ADVISOR, payload: status });
  }
};
export const removeAdvisortoplanError = status => {
  return (dispatch) => {
    dispatch({ type: REMOVE_PLAN_ADVISOR_ERROR, payload: status });
  }
};
export const courseAcessStudent = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/planaccess/student/load' ,requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_ACESS_STUDENT, payload: data.data })
        dispatch({ type: PLAN_ACESS_STUDENT_STATUS, payload: true })
      } else {
        dispatch({ type: PLAN_ACESS_STUDENT_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const courseAcessStudentStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_ACESS_STUDENT_STATUS, payload: status });
  }
};
export const courseAcessStudentError = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_ACESS_STUDENT_ERROR, payload: status });
  }
};

export const planReject = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/plans/reject/'+ id).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_REJECT_STATUS, payload: true })
      } else {
        dispatch({ type: PLAN_REJECT_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: PLAN_REJECT_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const planRejectStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_REJECT_STATUS, payload: status });
  }
};
export const planRejectErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_REJECT_ERROR, payload: status });
  }
};

export const planDropConfirm = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/plans/confirmdrop' ,requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: PLAN_CONFIRM_DROP_STATUS, payload: true })
      } else {
        dispatch({ type: PLAN_CONFIRM_DROP_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}

export const dropPlanStatus = status => {
  return (dispatch) => {
    dispatch({ type: PLAN_CONFIRM_DROP_STATUS, payload: status });
  }
};


export const activitiesApprove = (id, requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/activities/approve/'+  id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: ACTIVITIES_APPROVE_STATUS, payload: true })
      } else {
        dispatch({ type: ACTIVITIES_APPROVE_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: ACTIVITIES_APPROVE_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const activityApproveStatus = status => {
  return (dispatch) => {
    dispatch({ type: ACTIVITIES_APPROVE_STATUS, payload: status });
  }
};
export const activityApproveErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: ACTIVITIES_APPROVE_ERROR, payload: status });
  }
};
export const courseReject = (id, requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/courses/reject/'+ id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: COURSE_REJECT_STATUS, payload: true })
      } else {
        // dispatch({ type: PLAN_REJECT_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        // dispatch({ type: PLAN_REJECT_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const courseRejectStatus = status => {
  return (dispatch) => {
    dispatch({ type: COURSE_REJECT_STATUS, payload: status });
  }
};

export const activitiesReject = (id, requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.put(BASE_URL + '/student-plans/v1/activities/reject/'+ id, requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: ACTIVITY_REJECT_STATUS, payload: true })
      } else {
        // dispatch({ type: PLAN_REJECT_ERROR, payload: true });
      }
    }).catch(function (error) {
      if (error.response) {
        const { headers: { message } } = error.response.data;
        // dispatch({ type: PLAN_REJECT_ERROR, payload: true });
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const activityRejectStatus = status => {
  return (dispatch) => {
    dispatch({ type: ACTIVITY_REJECT_STATUS, payload: status });
  }
};

export const courseDropConfirmation = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/courses/confirmdrop', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: COURSE_DROP_STATUS, payload: true })
      } else {
        dispatch({ type: COURSE_DROP_ERROR_STATUS, payload: true })
      }
    }).catch(function (error) {
      dispatch({ type: COURSE_DROP_ERROR_STATUS, payload: true })
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const courseDropConfirmationStatus = status => {
  return (dispatch) => {
    dispatch({ type: COURSE_DROP_STATUS, payload: status });
  }
};
export const courseDropErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: COURSE_DROP_ERROR_STATUS, payload: status });
  }
};
export const activityDropConfirmation = (requestParams) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post(BASE_URL + '/student-plans/v1/activities/confirmdrop', requestParams).then(({ data }) => {
      const { headers: { statusCode, message } } = data
      if (statusCode === "200") {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: ACTIVITY_DROP_STATUS, payload: true })
      } else {
        dispatch({ type: ACTIVITY_DROP_ERROR_STATUS, payload: true })
      }
    }).catch(function (error) {
      dispatch({ type: ACTIVITY_DROP_ERROR_STATUS, payload: true })
      if (error.response) {
        const { headers: { message } } = error.response.data;
        dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
      } else {
        dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
      }
      console.log("Error****:", error.message);
    });
  }
}
export const activityDropConfirmationStatus = status => {
  return (dispatch) => {
    dispatch({ type: ACTIVITY_DROP_STATUS, payload: status });
  }
};
export const activityDropErrorStatus = status => {
  return (dispatch) => {
    dispatch({ type: ACTIVITY_DROP_ERROR_STATUS, payload: status });
  }
};
export const getDashboardCount = () => {
  return async(dispatch) => {
    dispatch({ type: FETCH_START });
      await axios.get(BASE_URL + "/user-service/v1/dashboard/counts").then(({data}) => {
        const { headers: { statusCode, message } } = data
        if (statusCode === "200") {
          console.log(data,"datadata");
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DASHBOARD_COUNT, payload: data });
          dispatch({ type: GET_DASHBOARD_COUNT_STATUS, payload: true });
        } else {
          dispatch({ type: GET_DASHBOARD_COUNT_ERROR_STATUS, payload: true })
        }
      }).catch(function (error) {
        dispatch({ type: GET_DASHBOARD_COUNT_ERROR_STATUS, payload: true })
        if (error.response) {
          const { headers: { message } } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message } });
        } else {
          dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
        }
        console.log("Error****:", error.message);
      });
  }
}
export const getDashboardCountStatus = status => {
  return (dispatch) => {
    dispatch({ type: GET_DASHBOARD_COUNT, payload: status });
  }
};
export const getExploreAdvisorCountForStudent = () => {
	console.log("working");
	return (dispatch) => {
	  dispatch({ type: FETCH_START })
	  axios.get(BASE_URL + '/user-service/v1/dashboard/goals/counts'  ).then(({ data }) => {
		const { headers: { statusCode, message } } = data
		if (statusCode === "200") {
		  dispatch({ type: FETCH_SUCCESS });
		  dispatch({ type: GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT, payload: data });
  
		} else {
		  dispatch({ type: FETCH_ERROR, payload: message });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		}
	  }).catch(function (error) {
		if (error.response) {
		  const { headers: { message, statusCode }, errorlist, } = error.response.data;
		  dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		} else {
		  dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		}
		console.log("Error****:", error.message);
	  });
	}
  };
export const getResetCounts = () => {
	return (dispatch) => {
	  dispatch({ type: FETCH_START })
	  axios.post(BASE_URL + '/user-service/v1/dashboard/resetcounts'  ).then(({ data }) => {
		const { headers: { statusCode, message } } = data
		if (statusCode === "200") {
		  dispatch({ type: FETCH_SUCCESS });
		  dispatch({ type: GET_RESET_COUNTS_STUDENT, payload: data });
  
		} else {
		  dispatch({ type: FETCH_ERROR, payload: message });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		}
	  }).catch(function (error) {
		if (error.response) {
		  const { headers: { message, statusCode }, errorlist, } = error.response.data;
		  dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		} else {
		  dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
		  dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
		  setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		}
		console.log("Error****:", error.message);
	  });
	}
  };

  export const resetLogoutStatus = status => {
    return (dispatch) => {
      dispatch({ type: GET_RESET_COUNTS_STUDENT, payload: status });
    }
  };
  export const updatesStudentsCount = (payload) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await axios.post(BASE_URL + '/user-service/v1/dashboard/milestoneupdatecounts' , payload).then(({ data }) => {
        const { headers: { statusCode, message } } = data
        console.log(data, "actionData")
        if (statusCode === "200") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UPDATES_VIEW_STUDENT_COUNT, payload: data })
          // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } });
          // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000);
        } else {
          dispatch({ type: FETCH_ERROR, payload: message });
          // dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
          // setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
        }
      }).catch(function (error) {
        if (error.response) {
  
          const { headers: { message, statusCode }, errorlist } = error.response.data;
          dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
        } else {
  
          dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
        }
      });
    }
  }

