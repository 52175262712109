import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Typography, CardContent, Card } from '@mui/material';
import { connect } from "react-redux";
import "./count.less";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, getAdvisorCountUpdateStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus, viewDashboardCount } from '../../../redux/actions'


const Index = (props) => {
    const navigate = useNavigate();
    let buttonState;
    let navigatePath = {};

    let advisorName = props?.connectedAdvisors?.filter((data) => data?.userInfo?.id === props?.data?.id)
    let studentName = props?.connectedStudents?.filter((data) => data?.id === props?.data?.id)

    console.log(studentName, "studentName")
    console.log(advisorName, "advisorName")


    if (props?.userProfile?.currentRole === "parent") {
        const { data } = props;
        const advisorFirstName = advisorName && advisorName[0]?.userInfo?.fullName || '';
        const milestones = data?.milestone || [];

        // Set common paths for advisor and student connection requests
        if (data?.type === "advisor" || data?.type === "student") {
            buttonState = `new ${data?.type} connection requests`;
            navigatePath = { pathname: '/parent/mynetwork', state: { type: "recieveRequest" } };
        }
        // Handle milestone updates
        else if (data?.type === "milestone updates") {
            const uniqueCourseIds = [...new Set(milestones.map(m => m.courseId))];
            const uniquePlanIds = [...new Set(milestones.map(m => m.planId))];
            const isCourses = milestones.every(m => m.activityId === null);
            const isActivities = milestones.every(m => m.courseId === null);

            if (isCourses) {
                navigatePath = milestones.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}/milestones/${milestones[0]?.courseId}`
                    : uniqueCourseIds.length === 1 && uniquePlanIds.length === 1
                        ? `/parent/mywork/plan/${milestones[0]?.planId}`
                        : `/parent/mywork`;
            } else if (isActivities) {
                const uniqueActivityIds = [...new Set(milestones.map(m => m.activityId))];
                navigatePath = milestones.length === 1
                    ? `/parent/mywork/plan/${milestones[0]?.planId}/activities/${milestones[0]?.activityId}`
                    : uniqueActivityIds.length === 1 && uniquePlanIds.length === 1
                        ? `/parent/mywork/plan/${milestones[0]?.planId}`
                        : `/parent/mywork`;
            } else {
                navigatePath = `/parent/mywork`;
            }

            buttonState = `new updates from ${advisorFirstName}`;
            console.log(advisorFirstName, "advisorFirstName");

        }
        // Handle course and activity updates
        else if (data?.type === "course updates" || data?.type === "activity updates") {
            const uniquePlanIds = [...new Set(data?.courses?.map(m => m.planId))];
            if (uniquePlanIds?.length === 1) {
                navigatePath = `/parent/mywork/plan/${milestones[0]?.planId}`;
            } else {
                navigatePath = `/parent/mywork`;
            }
            buttonState = `new ${data?.type.replace(" updates", "")} updates from ${advisorFirstName}`;
        }
    }
    else if (props?.userProfile?.currentRole === "student") {

        if (props?.data?.type === "advisor") {
            buttonState = "new advisor connection requests"
            navigatePath = { pathname: '/student/mynetwork', state: { type: "recieveRequest" } };
        }
        else if (props?.data?.type === "student") {
            buttonState = "new student connection requests"
            navigatePath = { pathname: '/student/mynetwork', state: { type: "recieveRequest" } };
        }
        else if (props?.data?.type === "milestone updates") {
            const milestones = props?.data?.milestone;

            // Get the unique courseId and planId values from the milestone array
            const uniqueCourseIds = [...new Set(milestones.map(milestone => milestone.courseId))];
            const uniquePlanIds = [...new Set(milestones.map(milestone => milestone.planId))];

            if (milestones.length === 1) {
                const isCourses = milestones.every(m => m.activityId === null);
                const isActivities = milestones.every(m => m.courseId === null);
                // Corrected: create a navigatePath object with both pathname and state


                if (isCourses) {
                    navigatePath = {
                        pathname: `/student/mywork/plan/${milestones[0]?.planId}/courseMilestone/${milestones[0]?.courseId}`,
                        state: { type: "milestone updates", courseData: milestones[0] }
                    };
                } else if (isActivities) {
                    const uniqueActivityIds = [...new Set(milestones.map(m => m.activityId))];
                    navigatePath = {
                        pathname: `/student/mywork/plan/${milestones[0]?.planId}/activityMilestone/${milestones[0]?.activityId}`,
                        state: { type: "milestone updates", activityData: milestones[0], activityId: milestones[0]?.activityId }
                    };
                } else {
                    navigatePath = `/parent/mywork`;
                }

            } else if (uniqueCourseIds.length === 1 && uniquePlanIds.length === 1) {
                navigatePath = {
                    pathname: `/student/mywork/plan/${milestones[0]?.planId}`,
                    state: { type: "milestone updates" }
                };
            } else {
                navigatePath = {
                    pathname: `/student/mywork`,
                    state: { type: "milestone updates" }
                };
            }
            buttonState = `new updates from ${advisorName && advisorName[0]?.userInfo?.fullName || ''}`;
        }

        else if (props?.data?.type === "course updates") {
            buttonState = `new course updates from ${advisorName && advisorName[0]?.userInfo?.fullName || ''}`;
            // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
        }
        else if (props?.data?.type === "activity updates") {
            buttonState = `new activity updates from ${advisorName && advisorName[0]?.userInfo?.fullName || ''}`;
            // navigatePath = `/parent/mywork/plan/${props?.data?.milestone[0]?.planId}/milestones/${props?.data?.milestone[0]?.courseId}`;
        }
    }
    else if (props?.userProfile?.currentRole === "advisor") {
        if (props?.data?.type === "advisor") {
            buttonState = "new advisor connection requests"
            navigatePath = { pathname: '/advisor/mynetwork', state: { type: "recieveRequest" } };
        }
        else if (props?.data?.type === "student") {
            buttonState = "new student connection requests"
            navigatePath = { pathname: '/advisor/mynetwork', state: { type: "recieveRequest" } };
        }
        else if (props?.data?.type === "milestone updates") {
            const milestones = props?.data?.milestone;
            console.log(props?.data?.milestone, "sdfdfv")
            // Get the unique courseId and planId values from the milestone array
            const uniqueCourseIds = [...new Set(milestones.map(milestone => milestone.courseId))];
            const uniquePlanIds = [...new Set(milestones.map(milestone => milestone.planId))];
            if (milestones.length === 1) {
                const isCourses = milestones.every(m => m.activityId === null);
                const isActivities = milestones.every(m => m.courseId === null);
                // Corrected: create a navigatePath object with both pathname and state


                if (isCourses) {
                    navigatePath = {
                        pathname: `/advisor/myStudents/${props?.data?.id}/plan/${props?.data?.milestone[0]?.planId}/courseMilestone`, state: { courseData: milestones[0] },
                        state: { type: "milestone updates", courseData: milestones[0] }
                    };
                } else if (isActivities) {
                    navigatePath = {
                        pathname: `/advisor/myStudents/${props?.data?.id}/plan/${props?.data?.milestone[0]?.planId}/activityMilestone`, state: { courseData: milestones[0] },
                        state: { type: "milestone updates", activityData: milestones[0] }
                    };
                }
                // navigatePath = { pathname: `/advisor/myStudents/${props?.data?.id}/plan/${props?.data?.milestone[0]?.planId}/courseMilestone`, state: { activityData: milestones[0] } };

            }

            // Condition 2: If multiple milestones exist with the same courseId and planId, include courseId and planId
            else if (uniqueCourseIds.length === 1 && uniquePlanIds.length === 1) {
                console.log(props?.data?.milestone, "props?.data?.milestonesdffsdf")
                // let planData = {
                //     activePhaseId: props?.data?.milestone?.activePhaseId,
                //     studentFullName: studentName,
                //     planName: props?.data?.milestone?.planName
                // }
                navigatePath = { pathname: `/advisor/myStudents/${props?.data?.id}/plan/${props?.data?.milestone[0]?.planId} `, state: { courseData: milestones[0] }, state: { activePhaseId: props?.data?.milestone[0]?.activePhaseId, planName :props?.data?.milestone[0]?.planName  } };

            }
            // Condition 3: If multiple milestones have different courseIds but the same planId, only include planId
            else {
                navigatePath = { pathname: `/advisor/myStudents/${props?.data?.id}`, state: { id: props?.data?.id, studentName: studentName && studentName[0]?.fullName }, };

                // navigate(data?.id, {state: {id: data?.id, studentName: data?.fullName, studentFirstName: data?.firstName}})
            }
            buttonState = `new updates from ${studentName && studentName[0]?.fullName || ''}`;
        }

    }

    const handleCardClick = () => {
        if (props?.data?.type === "milestone updates") {
            if (props?.data?.milestone?.length > 0) {
                // Extract milestone data
                const milestones = props?.data?.milestone;

                // Get the unique courseId and planId values from the milestone array
                const uniqueCourseIds = [...new Set(milestones.map(milestone => milestone.courseId))];
                const uniquePlanIds = [...new Set(milestones.map(milestone => milestone.planId))];

                // Initialize the payload with default values
                let payload = {
                    "postedBy": props?.data?.id,
                    "updateType": "milestone"
                };

                // Condition 1: If only one milestone exists, include courseId, milestoneId, and planId
                if (milestones.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.courseId = milestones[0].courseId;
                    payload.milestoneId = milestones[0].milestoneId;
                    payload.planId = milestones[0].planId;
                    props?.viewDashboardCount(payload);
                }
                // Condition 2: If multiple milestones exist with the same courseId and planId, include courseId and planId
                else if (uniqueCourseIds.length === 1 && uniquePlanIds.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.courseId = uniqueCourseIds[0];
                    payload.planId = uniquePlanIds[0];
                    props?.viewDashboardCount(payload);
                }
                // Condition 3: If multiple milestones have different courseIds but the same planId, only include planId
                else if (uniquePlanIds.length === 1) {
                    console.log(uniquePlanIds, "uniquePlanIds");
                    payload.planId = uniquePlanIds[0];
                    props?.viewDashboardCount(payload);
                }
            }
        }

        if (navigatePath) {
            navigate(navigatePath, { state: navigatePath.state });
        }
        if (props?.advisor) {
            navigate('/student/mynetwork/exploreAdvisor', { state: props?.data?.goalName })
        } else if (props?.student) {
            navigate('/student/mynetwork/exploreStudent', { state: props?.data?.goalName })
        }
        else if (props?.advisorProfile) {
            navigate('/advisor/mynetwork/exploreStudents', { state: props?.data?.goalName })
        }
        else if (props?.parentAdvisor) {
            navigate('/parent/mynetwork/exploreAdvisor', { state: props?.data?.goalName })
        }
        else if (props?.parentStudents) {
            navigate('/parent/mynetwork/exploreStudents', { state: props?.data?.goalName })
        }
    };

    console.log(props?.data?.goalName, "gyagygygyg");

    return (
        <>
            <div className="dashboard-top-parent" onClick={handleCardClick} >
                <Card className="dashCountCard" variant="outlined" >
                    <CardContent>
                        <Typography variant="h6" component="div" className="totalcount"> {props?.data?.count}</Typography>
                        <Typography className="countFrom">{props?.data?.description ? props?.data?.description : buttonState}</Typography>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, studentData, parentData, userProfileData }) => {
    const { isgetusergoals, } = studentData;
    const { parentGoal, } = parentData;
    const { userProfile } = userProfileData;
    const { message, errorList, connectedAdvisors, connectedStudents } = commonData;
    return { message, errorList, connectedAdvisors, connectedStudents, isgetusergoals, parentGoal, userProfile };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, getChildProfile, getAdvisorCountUpdateStatus, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus, viewDashboardCount })(Index);
