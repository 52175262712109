import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import MileStoneCard from "../../../../componentsNew/Common/Cards/MyWork/MilestoneCard"
import { Avatar, AvatarGroup } from "@mui/material";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Badge, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { GetMilestoneList, GetMilestoneListStatus } from "../../../../redux/actions";
import { withRouter } from '../../../../redux/store/navigate';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Index = (props) => {
  const [loading, setloading] = useState(false);
  let navigate = useNavigate();
  const Networks = /\/student\/[^\/]+\/mywork\/?$/.test(window?.location?.pathname);
  const { id } = useParams();
	const location = useLocation();

  useEffect(() => {
    setloading(true)
    let value = {
      searchString: '',
      pageNo: 0,
      limit: 40,
      milestoneStatus: [],
      planIds: [],
      sortOrder: "latest",
      studentId: id ? id : null
    }
    props?.GetMilestoneList(value)
  }, [location.pathname])



  useEffect(() => {
    if (props?.getmilestoneliststatus) {
      props?.GetMilestoneListStatus(false)
      setloading(false)
    }
  }, [props?.getmilestoneliststatus, props?.getmilestoneData])

  const handleNavigate = (e, data) => {
    if (data?.courseInfo !== null) {
      if (Networks) {
        navigate(`/student/mywork/plan/${data?.planInfo?.id}/courseMilestone/${data?.courseInfo?.id}`, { state: { courseData: data?.courseInfo, milestoneData: data?.milestone, studentView: true } })
      }
      else {
        navigate(`plan/${data?.planInfo?.id}/courseMilestone/${data?.courseInfo?.id}`, { state: { courseData: data?.courseInfo, milestoneData: data?.milestone } })
      }
    } else {
      if (Networks) {
        navigate(`/student/mywork/plan/${data?.planInfo?.id}/activityMilestone/${data?.activityInfo?.id}`, { state: { activityData: data?.activityInfo, milestoneData: data?.milestone, studentView: true } })
      }
      else {
        navigate(`plan/${data?.planInfo?.id}/activityMilestone/${data?.activityInfo?.id}`, { state: { activityData: data?.activityInfo, milestoneData: data?.milestone } })
      }
    }
  }

  return (
    <>
      {loading && <Loader />}
      <div style={{ "display": "flex", "flexDirection": "row", "flexWrap": "wrap", "gap": "20px" }}>
        {props?.getmilestoneData?.map(data => {
          return (
            <MileStoneCard
              key={data?.milestone?.id}
              planName={data?.planInfo?.planName}
              milestoneName={data?.milestone?.milestoneTitle}
              course={data?.courseInfo?.courseName}
              activity={data?.activityInfo?.activityName}
              type={data?.milestone?.milestoneType}
              date={`${data?.milestone?.startingDate?.split(' ')?.[0]} - ${data?.milestone?.closingDate?.split(' ')?.[0]}`}
              semester={data?.courseInfo?.planPhase?.title ?? data?.activityInfo?.planPhase?.title}
              status={data?.milestone?.status}
            >
              <div>
                <div className="AvatarSection">
                  {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <AvatarGroup max={3}>
                {avatars.map((avatar, index) => (
                  <Avatar key={index} alt={avatar} src={avatar} />
                ))}
              </AvatarGroup>
              {avatars.length === 1 &&
                <ChatBubbleOutlineOutlinedIcon style={{ marginLeft: 'auto', marginTop: '0px', fontSize: "12px", "cursor": "pointer" }} />
              }
            </div> */}
                </div>
                <div className="MilestoneButtonSection">
                  
                  {/* <Button className="filledbtn" >View Updates  */}
                  {/* <Badge bg="danger" pill className="badge">4</Badge> */}
                  {/* </Button> */}
                  <Button className="outlinedbtn" onClick={(e) => handleNavigate(e, data)} >View Update</Button>
                </div>
              </div>
            </MileStoneCard>
          )
        })}
        {props?.getmilestoneData?.length === 0 &&
          <div className="semesterCourseSec__noDataSec" style={{ "width": "100%" }}>
            <div className="semesterCourseSec__noDataSec__noDataCard">
              <p className="semesterCourseSec__noDataSec__noDataCard__textContent">
                No Milestones Available
              </p>
            </div>
          </div>
        }
      </div>
    </>
  )
}

const mapStateToProps = ({ studentData, commonData }) => {
  const { getmilestoneData, getmilestoneliststatus } = studentData;
  return { getmilestoneData, getmilestoneliststatus }
}

export default connect(mapStateToProps, { GetMilestoneList, GetMilestoneListStatus })(withRouter(Index));