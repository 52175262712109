import {
  FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE,
  SHOW_MESSAGE, SET_PAGINATION, GET_USER_ROLES, GET_UNIVERSITIES, GET_COUNTRY,
  GET_PROFESSIONS, GET_GENDER, GET_STATUS, GET_GRADUATION_YEAR
  , FORGET_PASSWORD_STATUS, CUSTOM_ALERT_SHOW, CUSTOM_ALERT_CLOSE, CLEAR_MESSAGE, GET_RECOMMENDED_STUDENTS_ADVISOR, GET_RECOMMENDED_STUDENTS_STATUS,
  CLEAR_ERROR_MESSAGE, GET_ACTIVITY, GET_COURSE, GET_SCHOOLS, GET_ACTIVITIES, GET_COURSES,
  GET_STUDENT_GRADES, GET_PHASE_TIME, ALERT_SHOW, ALERT_HIDE, SET_COPY_FLAG,
  GET_ADVISOR_RECOMMENDED, GET_ADVISOR_RECOMMENDED_STATUS, GET_RECOMMENDED_STUDENTS,SET_CONNECTED_ADVISORS_STATUS,
  USER_CONNECT_STATUS, USER_CONNECT_ERROR_STATUS, GET_PENDING_REQUESTS, GET_DETAILED_USER_PROFILE,
  SET_DETAILED_USER_PROFILE_STATUS, SEND_RESPOND_TO_REQUEST, GET_CONNECTED_PARENTS, GET_CONNECTED_STUDENTS, GET_CONNECTED_ADVISORS, SET_CONNECTED_FRIEND_STATUS, SET_CONNECTED_STUDENTS_STATUS, SET_PENDING_REQUESTS_STATUS, GET_CONNECTED_FRIENDS,
  GET_NOTIFICATION, VIEW_NOTIFICATION, BOOK_SESSION, BOOK_PAID_SESSION, LIST_SCHEDULED_EVENTS, GET_CONNECTED_ADVISORS_SWITCH, SET_CONNECTED_ADVISORS_SWITCH_STATUS, GET_CONNECTED_FRIEND_SWITCH, SET_CONNECTED_CHILD_STATUS, RESCHEDULE_SESSION,RESCHEDULE_SESSION_ERROR,
  BOOK_SESSION_ERROR,BOOK_PAID_SESSION_ERROR,GET_SENT_REQUESTS, GET_CONNECTED_PARENT_SWITCH, LIST_SCHEDULED_EVENTS_SWITCH, GET_ADVISOR_SUGGESTED, PARENT_PLANS_DETAIL, GET_ADVISOR_SUGGESTED_STATUS, STUDENT_PLANS_INFO, STUDENT_PLANS_INFO_STATUS, PARENT_PLANS_INFO_STATUS
  ,ERROR_CANCEL_REQUEST,CANCEL_REQUEST, ADVISOR_FEADBACK_SUBMIT, STUDENT_PLANS_INFO_RESET,ADD_ADVISOR_STATUS_ERROR,ADD_ADVISOR_STATUS, LOAD_ADVISOR_REVIEW, RESET_PENDING_REQUESTS,GET_SENT_REQUESTS_STATUS,GET_PENDING_REQUESTS_STATUS, SCHEDULED_SESSION_DETAILS, SCHEDULED_SESSION_DETAILS_STATUS,
  CANCEL_SCHEDULE_SESSION, CANCEL_SCHEDULE_SESSION_ERROR, RESCHEDULE_PAID_SESSION, SCHEDULED_FREESESSION_DETAILS, LIST_SCHEDULED_EVENTS_STATUS, GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS, HIDE_CONNECTION_REQUEST,INVITATION_REVOKE_ERROR,INVITATION_REVOKE, FREE_SESSION_REMAINING,ADD_STUDENT_STATUS_ERROR,ADD_STUDENT_STATUS,
  REMOVE_STUDENT_STATUS_ERROR,REMOVE_STUDENT_STATUS,   SEND_RESPOND_TO_PARENT_REQUEST,  GET_EXPLORE_STUDENT, ADVISOR_PROFILE_SAVE, GET_SAVED_ADVISOR,ADVISOR_PROFILE_SAVE_ERROR, LOAD_SAVED_USER_PROFILE, LOAD_SAVED_USER_PROFILE_STATUS, SAVE_USER_PROFILE, 
  EXPLORE_ADVISOR_PAGINATION_STATUS, EXPLORE_ADVISOR_PAGINATION, GET_USER_COUNT, SET_UNARCHIVE_PLAN
  ,GET_ADVISOR_AVAILABILITY,GENERIC_SEARCH_ADVISOR, GENERIC_SEARCH_STUDENT, GENERIC_SEARCH_STUDENT_STATUS, UNSUBSCRIBE_ADVISOR, GET_EXPLORE_STUDENT_STATUS,
  GET_ZIPCODES,SCHEDULED_FREESESSION_DETAILS_STATUS,  ADVISOR_FEADBACK_EDIT, ADVISOR_FEADBACK_DELETE,GET_NETWORK_USER_COUNT_STATUS,GET_NETWORK_USER_COUNT,SET_ARCHIVE_PLAN_ERROR,SET_ARCHIVE_PLAN,TOTAL_ADVISOR_REVIEW,
  LOAD_ADVISOR_REVIEW_STATUS,RESET_REVIEW,SET_PHASE_TIME_STATUS,SET_ARCHIVE_COURSE,SET_ARCHIVE_COURSE_ERROR, GET_EXPLORE_ADVISOR_COUNT, GET_UPDATES_VIEWED_COUNT,
  SET_UNARCHIVE_COURSE,SET_UNARCHIVE_COURSE_ERROR,SET_UNARCHIVE_ACTIVITY_ERROR,SET_UNARCHIVE_ACTIVITY,SET_ARCHIVE_ACTIVITY, CURRENT_COURSES_DASHBOARD, USER_INVITE_ADVISOR, USER_INVITE_STUDENT, CURRENT_ACTIVITY_DASHBOARD,
  USER_INVITE_STUDENT_ERROR,
  GET_ALL_DATA_PROFILE} from '../constants/CommonTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  errorList: {},
  pagination: {
    totalpages: 1,
    currentpage: 1,
    recordlimit: 10,
    totalrecords: 0
  },
  customAlertMsg: "",
  customAlertShow: false,
  customAlertSuccess: true,
  alertShow: false,
  copyInviteFlag: true,
  zipcodes: [],
  recommendedStudents: [],
  recommendedAdvisorstatus: false,
  userConnectSuccess: false,
  userConnectError: false,
  pendingList: [],
  courseNames: [],
  pendingRequestStatus: false,
  getRecommendedData: [],
  getRecommendationStatus: false,
  phaseTimeStatus: false,
  getRecommendedStatus: false,
  detailedUserData: {},
  detailedUserDataSuccess: false,
  isRequestRespond: false,
  connectedParents: [],
  connectedParentsStatus: false,
  connectedStudents: [],
  connectedStudentsStatus: false,
  connectedAdvisors: [],
  connectedAdvisorsStatus: false,
  connectedFriends: [],
  connectedFriendsStatus: false,
  notificationdata: [],
  notificationSuccess: false,
  viewednotfication: false,
  sessionBooked: true,
  paidSessionBooked: true,
  listScheduledEventsStatus: false,
  scheduledEvents: [],
  scheduledSessionDetails: [],
  connectedSwitchAdvisor: [],
  connectedAdvSwitchStatus: false,
  connectedStudentSwitch: [],
  connectedFriendStatus: false, 
  reScheduledEvents : false,
  reScheduledError: false,
  booksessionerror: false,
  bookPaidSessionError: false,
  parentconnectedSwitch: [],
  eventSwitchList : [],
  suggestedAdvisor : [],
  getSuggestedStatus: false,
  studentPlanData: [],
  loadStudentPlanDataStatus: false,
  parentPlanData: [],
  loadParentPlanDataStatus : false,
  sendedRequestList : [],
  receivedRequestStatus: false,
  errorcancelrequest: false,
  cancelrequest: false,
  addadvisoracesserror: false,
  addadvisoracess: false,
  reviewData: [],
  feadbackSubmit : false,
  sendRequestStatus: false,
  cancelSession : false,
  cancelErrorSession : false,
  freeSessionStatus : false,
  invitationRevokeError: false,
  invitationRevoke: false,
  getRemainingFreeSession : [],
  addStudentError: false,
  addStudentStatus: false,
  scheduleupdatestatus: false,
  removeStudentError: false,
  removeStudentStatus: false,
  isUserProfileSaved : false,
  savedAdvisorError: false,
  loadSavedUsers : [],
  loadSavedUserStatus: false,
  loadExploreStudents: [],
  advisorTime: [],
  parentRequest : false,
  genericSearchAdvisor: [],
  genericSearchStudentData: [],
  genericSearchStudentStatus: false,
  unsubscribeStatus : false,
  exploreStudentStatus : false,
  paginationData : [],
  paginationDataStatus :  false,
  totalUserCount : [],
  editFeedBack : false,
  deleteFeedBack : false,
  networkcountstatus: false,
  networkcount: [],
  archieveplanstatus: false,
  archieveplanerror: false,
  unArchivePlanstatus : false,
  reviewDataStatus: false,
  archievedCourse: false,
  archievedCourseError: false,
  unarchievedCourse: false,
  unarchievedCourseError: false,
  archievedActivity: false,
  archievedActivityError: false,
  unarchievedActivity: false,
  unarchievedActivityError: false,
  exploreAdvisorCount : [],
  isUpdateViewed : false,
  currentDashboardCourses : [],
  userInviteAdvisor : [],
  userInviteStudent : [],
  currentDashboardActivity : [],
  advisorReview: [],
  inviteStudentError : false,
  detailedProfileStatus : false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOM_ALERT_SHOW: {
		console.log(action.payload,"successss");
		
      return { ...state, customAlertMsg: action.payload.msg, customAlertShow: true, customAlertSuccess: action.payload.success };
    }
    case CUSTOM_ALERT_CLOSE: {
      return { ...state, customAlertMsg: "", customAlertShow: false, customAlertSuccess: true };
    }
    case ALERT_SHOW: {
      return { ...state, customAlertMsg: action.payload.msg, alertShow: true, customAlertSuccess: action.payload.success };
    }
    case ALERT_HIDE: {
      return { ...state, customAlertMsg: "", alertShow: false, customAlertSuccess: true };
    }
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', message: '', loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: '', message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
		console.log(action.payload,"ereraction.payload");
		
      return { ...state, loading: false, error: action.payload.message, message: action.payload.message, errorList: action.payload.errorList ?? {}, statusCode: action.payload.statusCode };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: '', message: '', errorList: {} };
    }
    case SET_COPY_FLAG: {
      return { ...state, copyInviteFlag: action.payload };
    }
    case SET_PAGINATION: {
      return { ...state, pagination: action.payload };
    }
    case GET_USER_ROLES: {
      return { ...state, userRoles: action.payload };
    }
    case GET_UNIVERSITIES: {
      return { ...state, universities: action.payload };
    }
    case GET_SCHOOLS: {
      return { ...state, schools: action.payload };
    }
    case GET_ZIPCODES: {
      return { ...state, zipcodes: action.payload };
    }
    case GET_PROFESSIONS: {
      return { ...state, professions: action.payload };
    }
    case GET_GENDER: {
      return { ...state, gender: action.payload };
    }
    case GET_STATUS: {
      return { ...state, status: action.payload };
    }
    case GET_COUNTRY: {
      return { ...state, country: action.payload };
    }
    case GET_ACTIVITY: {
      return { ...state, activity: action.payload };  // --> Activity Type
    }
    case GET_ACTIVITIES: {
      return { ...state, activityNames: action.payload };
    }
    case GET_COURSE: {
      return { ...state, course: action.payload };  // --> Course Type
    }
    case GET_COURSES: {
      return { ...state, courseNames: action.payload };
    }
    case GET_STUDENT_GRADES: {
      return { ...state, studentGrades: action.payload };
    }
    case FORGET_PASSWORD_STATUS: {
      return { ...state, isNewPassword: action.payload, };
    }
    case GET_PHASE_TIME: {
      return { ...state, 
        getphasetime: action.payload,
        phaseTimeStatus: action?.status
      };
    }
    case SET_PHASE_TIME_STATUS: {
      return { ...state, 
        phaseTimeStatus: action?.payload
      };
    }
    case CLEAR_MESSAGE: {
      return { ...state, message: "" };
    }
    case CLEAR_ERROR_MESSAGE: {
      return { ...state, errorList: "" };
    }
    case GET_GRADUATION_YEAR: {
      return { ...state, graduationyear: action.payload };
    }
    case GET_ADVISOR_RECOMMENDED: {
      return {
        ...state,
        recommendedAdvisor: action.payload
      }
    }
    case GET_ADVISOR_RECOMMENDED_STATUS: {

      return {
        ...state,
        recommendedAdvisorstatus: action.payload
      }
    }

    case GET_RECOMMENDED_STUDENTS: {
      return {
        ...state,
        recommendedStudents: action.payload,
        getRecommendedStatus: true
      }
    }
    case GET_RECOMMENDED_STUDENTS_STATUS: {
      return {
        ...state,
        getRecommendedStatus: action.payload
      }
    }
    case USER_CONNECT_STATUS: {
      return {
        ...state,
        userConnectSuccess: action.payload,
      };
    }
    case USER_CONNECT_ERROR_STATUS: {
      return {
        ...state,
        userConnectError: action.payload,
      }
    }
    case GET_PENDING_REQUESTS: {
      return {
        ...state,
        pendingList: action.payload,
        pendingRequestStatus: action.status
      }
    }
    case GET_PENDING_REQUESTS_STATUS: {
      return {
        ...state,
        pendingRequestStatus: action.payload
      }
    }
    case SET_PENDING_REQUESTS_STATUS: {
      return {
        ...state,
        pendingRequestStatus: action.payload
      }
    }
    case RESET_PENDING_REQUESTS: {
      return {
        ...state,
        pendingList: action.payload
      }
    }

    case GET_CONNECTED_PARENTS: {
      return {
        ...state,
        connectedParents: action.payload,
        connectedParentsStatus: action.status

      }
    }
    case GET_CONNECTED_STUDENTS: {
      return {
        ...state,
        connectedStudents: action.payload,
        connectedStudentsStatus: action.status

      }
    }
    
    case SET_CONNECTED_STUDENTS_STATUS: {
      return {
        ...state,
        connectedStudentsStatus: action.payload

      }
    }
    case GET_CONNECTED_ADVISORS: {
      return {
        ...state,
        connectedAdvisors: action.payload,
        connectedAdvisorsStatus: action.status
      }
    }
    case SET_CONNECTED_ADVISORS_STATUS: {
      return {
        ...state,
        connectedAdvisorsStatus: action.status
      }
    }

    case GET_CONNECTED_ADVISORS_SWITCH: {
      return {
        ...state,
        connectedSwitchAdvisor: action.payload,
        connectedAdvSwitchStatus: action.status
      }
    }
    case GET_CONNECTED_FRIEND_SWITCH: {
      return {
        ...state,
        connectedStudentSwitch: action.payload,
        connectedFriendStatus : action.status
      }
    }
    case SET_CONNECTED_CHILD_STATUS: {
      return {
        ...state,
        connectedFriendStatus: action.payload

      }
    }
    case GET_DETAILED_USER_PROFILE: {
      return {
        ...state,
        detailedUserData: action.payload?.data,
        detailedUserDataSuccess: action.payload.status,
      }
    }
    case SET_DETAILED_USER_PROFILE_STATUS: {
      return {
        ...state,
        detailedUserDataSuccess: action.payload
      }
    }
    case GET_ALL_DATA_PROFILE: {
      console.log(action.payload,"action.payload?.data");
      return {
        ...state,
        detailedProfileStatus : action.payload,
      }
    }
    case GET_RECOMMENDED_STUDENTS_ADVISOR: {
      return {
        ...state,
        getRecommendedData: action.payload
      }
    }
    case GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS: {
      return {
        ...state,
        getRecommendationStatus: action.payload
      }
    }
    case SEND_RESPOND_TO_REQUEST: {
      return {
        ...state,
        isRequestRespond: action.payload
      }
    }
    case GET_CONNECTED_FRIENDS: {
      return {
        ...state,
        connectedFriends: action.payload,
        connectedFriendsStatus: action.status
      }
    }

    case SET_CONNECTED_FRIEND_STATUS: {
      return {
        ...state,
        connectedFriendsStatus: action.payload

      }
    }
    case GET_NOTIFICATION: {
      return {
        ...state,
        notificationdata: action.payload,
        notificationSuccess: action?.status
      }
    }
    case VIEW_NOTIFICATION: {
      return {
        ...state,
        viewednotfication: action.payload
      }
    }
    case BOOK_SESSION: {
      return {
        ...state,
        sessionBooked: action.payload
      }
    }
    case BOOK_PAID_SESSION: {
      return {
        ...state,
        paidSessionBooked: action.payload
      }
    }
    case LIST_SCHEDULED_EVENTS: {
      return {
        ...state,
        listScheduledEventsStatus: action?.payload?.success,
        scheduledEvents: action?.payload?.data
      }
    }
    case LIST_SCHEDULED_EVENTS_STATUS: {
      return {
        ...state,
        listScheduledEventsStatus: action?.payload,
      }
    }

    case  RESCHEDULE_SESSION: {
      return {
        ...state,
        reScheduledEvents: action.payload
      }
    }
    case RESCHEDULE_SESSION_ERROR:{
      return {
        ...state,
        reScheduledError: action.payload
      }
    }
    case  RESCHEDULE_PAID_SESSION: {
      return {
        ...state,
        reSchedulePaidEventStatus: action.payload
      }
    }
    case  CANCEL_SCHEDULE_SESSION: {
      return {
        ...state,
        cancelSession: action.payload
      }
    }
    case CANCEL_SCHEDULE_SESSION_ERROR:{
      return {
        ...state,
        cancelErrorSession: action.payload
      }
    }
    case BOOK_SESSION_ERROR:{
      return {
        ...state,
        booksessionerror: action.payload
      }
    }
    case BOOK_PAID_SESSION_ERROR:{
      return {
        ...state,
        bookPaidSessionError: action.payload
      }
    }
    case SCHEDULED_SESSION_DETAILS:{
      return {
        ...state,
        scheduledSessionDetails: action.payload?.data,
        scheduledSessionDetailStatus: action?.payload?.status
      }
    }
    case SCHEDULED_SESSION_DETAILS_STATUS:{
      return {
        ...state,
        scheduledSessionDetailStatus: action?.payload
      }
    }
    case SCHEDULED_FREESESSION_DETAILS_STATUS:{
      return {
        ...state,
        scheduleupdatestatus: action?.payload
      }
    }
    case GET_CONNECTED_PARENT_SWITCH:{
      return {
        ...state,
        parentconnectedSwitch: action.payload
      }
    }
    case LIST_SCHEDULED_EVENTS_SWITCH: {
      return {
        ...state,
        eventSwitchList: action.payload
      }
    }
    case GET_ADVISOR_SUGGESTED: {
      return {
        ...state,
        suggestedAdvisor: action.payload
      }
    }
    case GET_ADVISOR_SUGGESTED_STATUS: {
      return {
        ...state,
        getSuggestedStatus: action.payload
      }
    }

    case STUDENT_PLANS_INFO: {
      return {
        ...state,
        studentPlanData: action.payload?.data,
        loadStudentPlanDataStatus: action?.payload?.status
      }
    }
    case STUDENT_PLANS_INFO_STATUS: {
      return {
        ...state,
        loadStudentPlanDataStatus: action?.payload
      }
    }
    case STUDENT_PLANS_INFO_RESET: {
      return {
        ...state,
        studentPlanData: action?.payload
      }
    }
    case PARENT_PLANS_DETAIL: {
      return {
        ...state,
        parentPlanData: action.payload?.data,
        loadParentPlanDataStatus: action?.payload?.status
      }
    }
    case PARENT_PLANS_INFO_STATUS: {
      return {
        ...state,
        loadParentPlanDataStatus: action?.payload
      }
    }
    case GET_SENT_REQUESTS: {
      return {
        ...state,
        sendedRequestList: action.payload,
        sendRequestStatus: action.status
      }
    }
    case GET_SENT_REQUESTS_STATUS: {
      return {
        ...state,
        sendRequestStatus: action.status
      }
    }
    case ERROR_CANCEL_REQUEST:{
      return {
        ...state,
        errorcancelrequest: action.payload,
      }
    }
    case CANCEL_REQUEST:{
      return {
        ...state,
        cancelrequest: action.payload,
      }
    }
    case ADD_ADVISOR_STATUS_ERROR:{
      return {
        ...state,
        addadvisoracess: action.payload,
      }
    }
    case ADD_ADVISOR_STATUS:{
      return {
        ...state,
        addadvisoracesserror: action.payload,
      }
    }
    case LOAD_ADVISOR_REVIEW: {
      const oldData = state?.reviewData || [];
      const newData = action?.payload?.data || [];
      const currentUserId = localStorage.getItem('parentViaChild'); // Get the current user's ID from localStorage
    console.log(newData, "newDatanewData")
      // Combine old and new data
      const combinedData = [...oldData, ...newData];
    
      // Create a Map to store unique reviews
      const dataMap = new Map();
      combinedData.forEach(item => dataMap.set(item.advisorReviewId, item));
    
      // Convert Map values to an array
      const uniqueData = Array.from(dataMap.values());
    
      // Separate the reviews posted by the current user
      const currentUserReviews = uniqueData.filter(item => item.userId === currentUserId);
      const otherReviews = uniqueData.filter(item => item.userId !== currentUserId);
    
      // Concatenate the current user's reviews at the top
      const orderedData = [...currentUserReviews, ...otherReviews];
    
      return {
        ...state,
        reviewData: orderedData,
        advisorReview : action?.payload?.data,
      };
    }
    
    
    case RESET_REVIEW :{
      return {
        ...state,
        reviewData: []
      };
    }
    case LOAD_ADVISOR_REVIEW_STATUS:{
      return {
        ...state,
        reviewDataStatus: action.payload,
      }
    }
    case ADVISOR_FEADBACK_SUBMIT : {
      return {
        ...state,
        feadbackSubmit: action.payload,
      }
    }
    case HIDE_CONNECTION_REQUEST : {
      return {
        ...state,
        hideRequestStatus: action.payload,
      }
    }
    case SCHEDULED_FREESESSION_DETAILS : {
      console.log(action.payload, "action.payload")
      return {
        ...state,
        freeSessionStatus: action.payload,
      }
    }
    case INVITATION_REVOKE : {
      return {
        ...state,
        invitationRevoke : action.payload
      }
    }
    case INVITATION_REVOKE_ERROR : {
      return {
        ...state,
        invitationRevokeError : action.payload
      }
    }
    case FREE_SESSION_REMAINING : {
      return {
        ...state,
        getRemainingFreeSession : action.payload
      }
    }
    case ADD_STUDENT_STATUS_ERROR : {
      return {
        ...state,
        addStudentError : action.payload
      }
    }
    case ADD_STUDENT_STATUS : {
      return {
        ...state,
        addStudentStatus : action.payload
      }
    }
    case REMOVE_STUDENT_STATUS_ERROR :{
      return {
        ...state,
        removeStudentError : action.payload
      }
    }
    case REMOVE_STUDENT_STATUS :{
      return {
        ...state,
        removeStudentStatus : action.payload
      }
    }

    case SAVE_USER_PROFILE : {
      return {
        ...state,
        isUserProfileSaved : action.payload
      }
    }
    case ADVISOR_PROFILE_SAVE_ERROR : {
      return {
        ...state,
        savedAdvisorError : action.payload
      }
    }
    case LOAD_SAVED_USER_PROFILE : {
      return {
        ...state,
        loadSavedUserStatus : action.status,
        loadSavedUsers : action.payload
      }
    }
    case LOAD_SAVED_USER_PROFILE_STATUS : {
      return {
        ...state,
        loadSavedUserStatus : action.payload
      }
    }
    case GET_EXPLORE_STUDENT : {
      return {
        ...state,
        loadExploreStudents : action.payload
      }
    }
    case GET_ADVISOR_AVAILABILITY : {
      return {
        ...state,
        advisorTime : action.payload
      }
    }
    case SEND_RESPOND_TO_PARENT_REQUEST : {
      return {
        ...state,
        parentRequest : action.payload
      }
    }
    case GENERIC_SEARCH_ADVISOR : {
      return {
        ...state,
        genericSearchAdvisor : action.payload
      }
    }
    case GENERIC_SEARCH_STUDENT : {
      return {
        ...state,
        genericSearchStudentData : action.payload,
      }
    }
    case GENERIC_SEARCH_STUDENT_STATUS : {
      return {
        ...state,
        genericSearchStudentStatus : action.status
      }
    }
    case UNSUBSCRIBE_ADVISOR : {
      return {
        ...state,
        unsubscribeStatus : action.status
      }
    }
    case GET_EXPLORE_STUDENT_STATUS : {
      return {
        ...state,
        exploreStudentStatus : action.payload
      }
      
    }
    case  EXPLORE_ADVISOR_PAGINATION : {
      return {
        ...state,
        paginationData : action.payload
      }
    }
    case EXPLORE_ADVISOR_PAGINATION_STATUS : {
      return {
        ...state,
        paginationDataStatus : action.payload
      } 
    }
    case GET_USER_COUNT : {
      console.log(action.payload,"totalUserCount");
      
      return {
        ...state,
        totalUserCount : action.payload
      }
    }
    case ADVISOR_FEADBACK_EDIT: {
      return {
        ...state,
        editFeedBack : action.payload
      }
    }
    case ADVISOR_FEADBACK_DELETE: {
      return {
        ...state,
        deleteFeedBack : action.payload
      }
    }
    case GET_NETWORK_USER_COUNT:{
      return {
        ...state,
        networkcount : action.payload
      }
    }
    case GET_NETWORK_USER_COUNT_STATUS : {
      return {
        ...state,
        networkcountstatus : action.payload
      }
    }
    case SET_ARCHIVE_PLAN : {
      return {
        ...state,
        archieveplanstatus : action.payload
      }
    }
    case SET_ARCHIVE_PLAN_ERROR :{
      return {
        ...state,
        archieveplanerror : action.payload
      }
    }
    case SET_UNARCHIVE_PLAN : {
      return {
        ...state,
        unArchivePlanstatus : action.payload
      }
    }
    case TOTAL_ADVISOR_REVIEW :{
      return {
        ...state,
        totalreview : action.payload
      }
    }
    case SET_ARCHIVE_COURSE:{
      return {
        ...state,
        archievedCourse : action.payload
      }
    }
    case SET_ARCHIVE_COURSE_ERROR:{
      return {
        ...state,
        archievedCourseError : action.payload
      }
    }
    case SET_UNARCHIVE_COURSE:{
      return {
        ...state,
        unarchievedCourse : action.payload
      }
    }
    case SET_UNARCHIVE_COURSE_ERROR:{
      return {
        ...state,
        unarchievedCourseError : action.payload
      }
    }
    case SET_ARCHIVE_ACTIVITY:{
      return {
        ...state,
        archievedActivity : action.payload
      }
    }
    case SET_UNARCHIVE_ACTIVITY_ERROR:{
      return {
        ...state,
        archievedActivityError : action.payload
      }
    }
    case SET_UNARCHIVE_ACTIVITY:{
      return {
        ...state,
        unarchievedActivity : action.payload
      }
    }
    case SET_UNARCHIVE_ACTIVITY_ERROR:{
      return {
        ...state,
        unarchievedActivityError : action.payload
      }
    }
    case GET_EXPLORE_ADVISOR_COUNT:{
      return {
        ...state,
        exploreAdvisorCount : action.payload
      }
    }
    case GET_UPDATES_VIEWED_COUNT : {
      return {
        ...state,
        isUpdateViewed : action.payload
      }
    }
    case USER_INVITE_ADVISOR : {
      return {
        ...state,
        userInviteAdvisor : action.payload
      }
    }
    case USER_INVITE_STUDENT : {
      return {
        ...state,
        userInviteStudent : action.payload
      }
    }
    case CURRENT_COURSES_DASHBOARD : {
      console.log(action.payload,"action.payloadaction.payload");
      
      return {
        ...state,
        currentDashboardCourses : action.payload
      }
    }
    case CURRENT_ACTIVITY_DASHBOARD : {
      return {
        ...state,
        currentDashboardActivity : action.payload
      }
    }
    case USER_INVITE_STUDENT_ERROR : {
      return {
        ...state,
        inviteStudentError : action.payload
      }
    }
    default:
      return state;
  }
}
