import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import "../../milestoneDetail.less"

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import TimeLine from "../../../../../../componentsNew/Common/TimeLine";
import AddMilestone from '../../../../../../componentsNew/Common/addMilestone';
import ResourceCard from "../../../../../../componentsNew/Common/ResourceCard";
import AddEditResource from '../../../../../../componentsNew/Common/AddEditResource';
import MileStoneNote from "../../../../../../componentsNew/Common/MileStoneNoteUpdate";

import { GetMilestoneByCourse, DeleteMileStoneStatus, createMilestonforCourseStatus, viewDashboardCount  } from '../../../../../../redux/actions'
import { withRouter } from '../../../../../../redux/store/navigate';
import { connect } from 'react-redux';

function formatDate(date) {
	return new Date(date).toLocaleDateString("en-US", {
		day: "numeric",
		month: "short",
		year: "numeric",
	});
}

const Index = (props) => {
	const [open, setOpen] = useState(false);
    const [milestoneshow, setMilestoneshow] = useState(false);
    const [resourcePopupShow, setResourcePopupShow] = useState(false);
    const [editData, setEditData] = useState(null)
	const {state} = useLocation();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const Closemilestone = () => setMilestoneshow(false);
	
	useEffect(() => {
		let payload = {
            "updateType": "milestone",
            "courseId": state.courseData.id,
            "planId": state.courseData.planId,
			"milestoneId": props?.data?.id
        };
		props?.viewDashboardCount(payload)
	}, [])
	
console.log(props?.data, "sdjhsjdhj")
	useEffect(() => {
        if (props?.isMilestoneDeleted) {
            let payload = {
                "planId": state.courseData.planId,
                "courseId": state.courseData.id
            }
            props?.GetMilestoneByCourse(payload)
            props?.DeleteMileStoneStatus(false)
        }
		if(props?.createCourseMilestoneStatus){
			props?.createMilestonforCourseStatus(false)
			Closemilestone()
		}

    }, [props?.isMilestoneDeleted, props?.createCourseMilestoneStatus])

    const handleClick = () => {
        Closemilestone()
    };

    const closeResourcePopup = () => {
        setEditData(null)
        setResourcePopupShow(false)
    }

    const showResourcePopup = () => {
        setResourcePopupShow(true)
    }

    const handleAddResource = (data) => {       // Add resource to Api
    }

    const handleEditResource = (data) => {
        setEditData(data)
        showResourcePopup()
    }

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const otherDetails = props?.data?.otherDetails;
    const strippedDetails = otherDetails ? stripHTMLTags(otherDetails) : "";

	return (
		<>
			<MileStoneNote data={props?.data} open={open} handleClose={handleClose} />
			{milestoneshow && <AddMilestone show={milestoneshow} onHide={() => Closemilestone()} handleClose={handleClick} milestoneEditData={props.data} />}
			{resourcePopupShow && 
				<AddEditResource
					show={resourcePopupShow}
					onHide={() => closeResourcePopup()}
					handleClick={closeResourcePopup}
					setData={handleAddResource}
					editData={editData || {}}
					mileStoneData={props?.data}
				/>
			}
			<Grid container spacing={5} className='mt-3 milestoneSec__tabContent'>
				<Grid item xs={12} sm={12} md={6} className='milestoneSec__tabContent--left'>
					<div className="leftCard" >
						<p className='leftCard--title'>{props?.data?.milestoneTitle}</p>
						<div className="leftCard__subDetails">
							<p className=''>{props?.data?.milestoneType}</p>
							<p className=''>{formatDate(props?.data?.startingDate) + " - " + formatDate(props?.data?.closingDate)}</p>
						</div>
						{strippedDetails &&
							<div className='leftCard__description'>
								<p className="leftCard__description--title">Other details</p>
								<p className="leftCard__description--subTitle"> {strippedDetails} </p>
							</div>
						}
						<div className='leftCard__resourceSec'>
							<div className="leftCard__resourceSec__topSec">
								<p className="leftCard__resourceSec__topSec--title">Resource</p>
								<Tooltip title="Create Resource" placement="bottom">
									<button className="tab_card__addMileStonebtn" onClick={showResourcePopup}>
										<AddOutlinedIcon sx={{ fontSize: "16px" }} />
									</button>
								</Tooltip>
							</div>
							{props?.data?.resources?.length > 0 ?
								<div className="leftCard__resourceSec__bottomSec">
									{(props?.data?.resources?.map((resource) => (
											<ResourceCard
												key={resource.id}
												description={resource.name}
												postedBy={resource.postedBy}
												link={resource.resourceURL}
												id={{ resourceId: resource.id }}
												editResource={handleEditResource}
												mileStoneData={props?.data}
											/>
									)))}
								</div>
							:
								<div className="leftCard__resourceSec__noDataSec">
									<p>No Resources Found</p>
								</div>
							}
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6} className='milestoneSec__tabContent--right'>
					<div className="rightCard" >
						{ props?.data?.updates?.length > 0 ? (
								<>
									<div className="rightCard__topSec">
										<p className="rightCard__topSec--title"> {props?.data?.updates?.length}  Milestone Updates</p>
										<Tooltip title="Post a update (Max 100 updates)" placement="bottom">
											<button className="tab_card__addMileStonebtn" onClick={handleOpen}>
												<AddOutlinedIcon sx={{ fontSize: "16px" }} />
											</button>
										</Tooltip>
									</div>
									<TimeLine data={props?.data?.updates} mileStoneData={props?.data} />
								</>
							) : (
								<div className="planviewcnttopsec__emptyState">
									<div className="planviewcnttopsec__emptyState__content">
										<div>
											<p className="planviewcnttopsec__emptyState__content__empty-text">
												You have not added any updates for this milestone.
											</p>
											<p className="planviewcnttopsec__emptyState__content__empty-cta" id='postUpdates' onClick={handleOpen} >
												Post Updates
											</p>
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Grid>
			</Grid>
		</>
		
	)
}

const mapStateToProps = ({ studentData, commonData }) => {
    const { milestoneTypes, createCourseMilestoneStatus } = studentData;
    const { errorList } = commonData;
    return { errorList, milestoneTypes, createCourseMilestoneStatus };
};

export default connect(mapStateToProps, { DeleteMileStoneStatus, GetMilestoneByCourse, createMilestonforCourseStatus, viewDashboardCount  })(withRouter(Index));