import React from 'react'
import "./suggestedStudent.less";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../../PillSection"
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
    let {name, country, avatarUrl, school, targetWeighted, targetUnweighted, universities, professions, matchParam,saveProfileIcon, courseAndActivities,userProfile, children} = props
    const gpaText = (targetWeighted, targetUnweighted) => {
        if(targetWeighted && targetUnweighted){
            return `Aspiring to achieve unweighted ${targetUnweighted} and weighted ${targetWeighted} GPA`
        }else if(targetWeighted){
            return `Aspiring to achieve weighted ${targetWeighted} GPA`
        } else if(targetUnweighted) {
            return `Aspiring to achieve unweighted ${targetUnweighted} GPA`
        } else return null;
    }
    const cardStyle = {
        backgroundColor: 'white',
        ...(userProfile === "student" || userProfile === "parent" &&{
          width: '320px',
        }),
        ...(userProfile === "student" || userProfile === "parent" && window.innerWidth <= 400 && {
          width: '280px',
        })
    };
    const imgUrl = useImageUrl(avatarUrl);
    return (
        <>  
            <p className='suggestedStudentCard-matchParam' style={{"minHeight":"40px", }}>{matchParam || ''}</p>
            <div className='suggestedStudentCard' style={cardStyle}>
                <div className="suggestedStudentCard__topSec">
                    {imgUrl ?
                        <img className="suggestedStudentCard__topSec__avatarImage"
                            src={imgUrl} alt="userprofile" loading='lazy'
                        />
                    :
                        <PersonIcon  className="suggestedStudentCard__topSec__userIcon" color="action"/>
                    }
                    <div className='suggestedStudentCard__topSec__userInfo'>
                        <p className='suggestedStudentCard__topSec__userInfo__primaryText'>{name}</p>
                        <p className='suggestedStudentCard__topSec__userInfo__secondaryText'>{country}</p>
                    </div>
                    {saveProfileIcon && <div className='bookmark' style={{marginLeft: 'auto', cursor: "pointer"}} >{saveProfileIcon}</div>}
                </div>
                <div className="suggestedStudentCard__midSec">
                    <div className="suggestedStudentCard__midSec__educationSec">
                        <SchoolOutlinedIcon className='suggestedStudentCard__midSec__educationSec__schoolIcon'/>
                        <p className='suggestedStudentCard__midSec__educationSec__schoolName'>{school}</p>
                    </div>
                    <div className="suggestedStudentCard__midSec__goalsSec">
                        { universities?.length > 0 &&
                            <>
                                <div className="suggestedStudentCard__midSec__goalsSec__targetUniversitySec">
                                    <StarIcon className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                    <p className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to attend </p>
                                </div>
                                <PillSection data={universities || []} maxWidth={200} maxHeight={100} />
                            </>
                        }
                        {professions?.length > 0 &&
                            <>
                                <div className="suggestedStudentCard__midSec__goalsSec__targetUniversitySec">
                                    <StarIcon className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                    <p className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to become  </p>
                                </div>
                                <PillSection data={professions} maxWidth={200} maxHeight={100} />
                            </>
                        }
                        {courseAndActivities?.length > 0 &&
                            <>
                                <div className="suggestedStudentCard__midSec__goalsSec__targetUniversitySec">
                                    <StarIcon className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                    <p className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Courses & activities</p>
                                </div>
                                <PillSection data={courseAndActivities} maxWidth={200} maxHeight={100} />
                            </>
                        }
                        {gpaText(targetWeighted, targetUnweighted) &&
                            <div className="suggestedStudentCard__midSec__goalsSec__targetUniversitySec">
                                <StarIcon className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                <p className='suggestedStudentCard__midSec__goalsSec__targetUniversitySec__label'>{gpaText(targetWeighted, targetUnweighted)}</p>
                            </div>
                        }
                        
                    </div>
                </div>
                <div className="suggestedStudentCard__buttonSec">
                    {children}
                </div>
            </div>
        </>

    )
}

export default Index