import React, { useEffect, useState } from 'react'
import { useNavigate,useLocation } from 'react-router';
import "./exploreStudents.less";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Grid } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Select from 'react-select';
import Search from "../../../../components/Search";
import Typography from '@mui/material/Typography';
import CarouselComponent from './CarouselComponent';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from '../../../../redux/store/navigate';
import { getSavedUserProfiles, advisorSaveStatus, setSavedUserProfileStatus, saveUserProfile, unSaveUser, genericSearchStudents, 
    setGenericSearchStudentStatus, resetGenericStudentsData, exploreStudentsByUniversity, setExploreStudentsByUniversityStatus, 
    exploreStudentsByProfession, setExploreStudentsByProfessionStatus, exploreStudentsByCourse, setExploreStudentsByCourseStatus, 
    exploreStudentsByActivity, setExploreStudentsByActivityStatus, resetExploreStudentsByUniversity, resetExploreStudentsByProfession, 
    resetExploreStudentsByCourse, resetExploreStudentsByActivity, setExploreStudentsBySectionStatus,

    fetchAllData,
    resetData} from '../../../../redux/actions';
import Loader from "../../../../components/Loader";
import useDebounce from '../../../../utils/UseDebounceHook';
import { Controller, useForm } from 'react-hook-form';

const topResponsiveOption  = {
    279: { items: 1 },
    575: { items: 1 },
    720: { items: 2 },
    768: { items: 1 },
    1260: { items: 2 },
    1540: { items: 3 },
    1860: { items: 4 },
    2400: { items: 5 }
}

const Index = (props) => {
    const [filterLoad, setFilterLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ filterData, setFilterData ] = useState ([])
    const [searchTerm, setSearchTerm] = useState('');

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    let debouncedSearchTerm = useDebounce(searchTerm, 400);
    const { loadUsers, data, universityFilterData, professionFilterData, courseFilterData, 
        activityFilterData, genericSearchData } = useSelector(state => state?.networkData);
    const { watch, formState: { }, handleSubmit, control } = useForm({ defaultValues: {} } );
    
    // useEffect(() => {
    //     window.scrollTo(0, 0); 
    //   }, []);

    useEffect(() => {
        let payloads = {
            userRole: "student"
        }
        props?.getSavedUserProfiles(payloads)
        // props?.fetchAllData(payload);

        return () => {
            dispatch(resetData());
        }
    }, [])

    useEffect(() => {
        if(props?.isUserProfileSaved){
            let payloads = {
                userRole: "student"
            }
            props?.getSavedUserProfiles(payloads)
            props?.advisorSaveStatus(false);

            let payload = {
                limit: 10,
                pageNo: 1,
                sectionIndex: 0,
                studentId: "",
                totalSection: 2,
                searchParams:{
                    searchString: debouncedSearchTerm,
                    sectionSearch: []
                }
            }
            props?.fetchAllData(payload);
            setLoading(false);
        };
    }, [props?.isUserProfileSaved])
    
    useEffect(() => {
        let payload = {
            limit: 10,
            pageNo: 1,
            sectionIndex: 0,
            studentId: "",
            totalSection: 2,
            searchParams:{
                searchString: debouncedSearchTerm || "",
                sectionSearch: filterData?.filter(data => data?.values)
            }
        }
        let payloads = {
            "userRole": "student"
        }
        dispatch(resetData());
        setTimeout(() => {
            if(filterData?.filter(data => data?.values)?.length > 0 || debouncedSearchTerm) {
                props?.getSavedUserProfiles(payloads)
                props?.fetchAllData(payload);
            }
            getGenericSearchResult()
        }, 200);
    }, [debouncedSearchTerm, filterData])

    useEffect(() => {
        if (location?.state) {
            scrollToParticularGoals();
        }

    }, [location?.state, loadUsers, loading]);

    const getGenericSearchResult = () => {
        let payload = {
            limit: 10,
            pageNo: 1,
            searchParams: {
                searchString: debouncedSearchTerm || "",
                sectionSearch: filterData?.filter(data => data?.values)
            },
            sectionIndex: 0,
            studentId: "",
            totalSection: 2
        }
        if(debouncedSearchTerm) {
            props?.genericSearchStudents(debouncedSearchTerm, payload)
        } else {
            props?.resetGenericStudentsData([])
            props?.fetchAllData(payload);
        }
    }

    const handleNavigate = (path) => {
        navigate(path)
    }

    const handleSearch = (data) => {
        setSearchTerm(data?.target?.value);
    }
    const bookmarkUserProfile = (status, data) => {
        setLoading(true);
        if(status){
            props?.saveUserProfile(data);
        } else {
            props?.unSaveUser(data);
        }
    }
    const onSubmit = async (data) => {
        if(Object.values(data).some(value => !!value)){
            dispatch(resetData());
            let filterArr = []
            for (const key in data){
                filterArr.push({
                section: key,
                values: data[key]?.map(data => data?.value)
            })}
            setFilterData(filterArr);
        }
	}

    const scrollToParticularGoals = () => {
        const scrollSection = document.getElementById(location.state);
        if(scrollSection){
            scrollSection.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <Grid container spacing={5} className='studentExploreContent'>
            {(loading || loadUsers) && <Loader />}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="studentExploreContent__desktop">
                    <ArrowBackOutlinedIcon className='studentExploreContent__desktop__goBackIcon' onClick={() => handleNavigate(-1)}/>
                    <p className="studentExploreContent__desktop--title">Explore Students</p>
                </div>
                <div className="studentExploreContent__mobile">
                    <p className="studentExploreContent__mobile--title">Explore Students</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={10} className='studentExploreContent__topLeftSec'>
                <div className="studentExploreContent__topLeftSec__recommendationSec">
                    {genericSearchData?.map(data => {
                        return (
                            <>
                                {data?.users?.length > 0 &&
                                    <>
                                        <p id={data[0]?.sectionValue} className='sectionDescription mt-3'>{data?.total + " " + data?.description}</p>
                                        <CarouselComponent 
                                            data={data?.users} 
                                            bookmarkUser={(status, data) => bookmarkUserProfile(status, data)} 
                                            sectionDetails={data}
                                            reponsive={topResponsiveOption}
                                            filterData = {filterData}
                                        />
                                    </>
                                }
                            </>
                        )
                    })}
                </div>
                <div className="studentExploreContent__topLeftSec__infoCard">
                    <p className="studentExploreContent__topLeftSec__infoCard--secondarytext">
                        Based on your {' '}
                        <span className='studentExploreContent__topLeftSec__infoCard--primaryText' onClick={() => navigate('/advisor/myprofile', {state: {tab: "currentStatus"}})}>profile</span>
                        {' '} settings, we have selected these students for you
                    </p>
                </div>
                <div className="studentExploreContent__topLeftSec__recommendationSec">
                    {props?.loadSavedUsers?.length > 0 ? 
                        <>
                            <p className='sectionDescription'>{props?.loadSavedUsers?.length} Saved Users</p>
                            <CarouselComponent 
                                data={ props?.loadSavedUsers} 
                                reponsive={topResponsiveOption}
                                bookmarkUser={(status, data) => bookmarkUserProfile(status, data)}
                            />
                        </>
                    :
                        <>
                            {data[0]?.users?.length > 0 &&
                                <>
                                    <p className='sectionDescription'>{data[0]?.total + ' ' + data[0]?.description}</p>
                                    <CarouselComponent 
                                        data={ data[0]?.users } 
                                        reponsive={topResponsiveOption}
                                        bookmarkUser={(status, data) => bookmarkUserProfile(status, data)}
                                        sectionDetails={data[0]}
                                        filterData = {filterData}
                                    />
                                </>
                            }
                        </>
                    }
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3} xl={2} className='studentExploreContent__topRightSect'>
                <div className="studentExploreContent__topRightSect__searchFilterSec">
                    <Search placeholder="Search" onSearch={handleSearch} value={searchTerm} />
                    <div className="studentExploreContent__topRightSect__searchFilterSec__filterSection">
                        <div className="studentExploreContent__topRightSect__searchFilterSec__filterSection__topSec">
                            <p className='studentExploreContent__topRightSect__searchFilterSec__filterSection__topSec--primaryText'>Filter</p>
                            {/* <p className='studentExploreContent__topRightSect__searchFilterSec__filterSection__topSec--secondaryText' onClick={handleClearFilter}>Clear Filter</p> */}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className='studentExploreContent__topRightSect__searchFilterSec__accordianSec'>
                            <Typography sx={{ flexShrink: 0, marginBottom : '10px'}}>
                                Attending University
                            </Typography>
                            <Controller
                                control={control}
                                name="university"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select isMulti name="university" options={universityFilterData} className="basic-multi-select" classNamePrefix="select" onChange={onChange}/>
                                )}
                            />
                            <Typography sx={{ marginBottom : '10px', marginTop: '10px'}}>
                                Professions
                            </Typography>
                            <Controller
                                control={control}
                                name="profession"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select isMulti name="profession" options={professionFilterData} className="basic-multi-select" classNamePrefix="select" onChange={onChange}/>
                                )}
                            />
                            <Typography sx={{ marginBottom : '10px', marginTop: '10px'}}>
                                Courses
                            </Typography>
                            <Controller
                                control={control}
                                name="course"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select isMulti name="course" options={courseFilterData} className="basic-multi-select" classNamePrefix="select" onChange={onChange}/>
                                )}
                            />
                            <Typography sx={{ marginBottom : '10px', marginTop: '10px'}}>
                                Activity
                            </Typography>
                            <Controller
                                control={control}
                                name="activity"
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <Select isMulti name="activity" options={activityFilterData} className="basic-multi-select" classNamePrefix="select" onChange={onChange}/>
                                )}
                            />
                            <div className="studentExploreContent__topRightSect__searchFilterSec__button-sec" >
                                <button type="submit" className="studentExploreContent__topRightSect__searchFilterSec__button-sec--primary-btn" >
                                    Apply Filter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='studentExploreContent__bottomSec'>
                {props?.loadSavedUsers?.length > 0 ?
                    data?.map((section) => { 
                        return (
                            <span key={section?.section + section?.sectionValue + section?.sectionIndex}>
                                <p className='sectionDescription mt-3'>{section?.total + " " + section?.description}</p>
                                <CarouselComponent 
                                    data={section?.users} 
                                    bookmarkUser={(status, data) => bookmarkUserProfile(status, data)} 
                                    sectionDetails={section}
                                    filterData = {filterData}
                                />
                            </span>
                        )
                    }) :
                    data?.slice(1)?.map((section) => { 
                        return (
                            <span key={section?.section + section?.sectionValue + section?.sectionIndex}
                            id={section?.sectionValue}
                            >
                                <p className='sectionDescription mt-3'>{section?.total + " " + section?.description}</p>
                                <CarouselComponent 
                                    data={section?.users} 
                                    bookmarkUser={(status, data) => bookmarkUserProfile(status, data)} 
                                    sectionDetails={section}
                                    filterData = {filterData}
                                />
                            </span>
                        )
                    }) 
                }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ advisorData, commonData, exploreData }) => {
    const { suggestedStudentDetails, suggestedStudentStatus } = advisorData;
    const { isUserProfileSaved, loadSavedUsers, getSavedAdvisorStatus, loadSavedUserStatus, genericSearchStudentStatus } = commonData;
    const { exploreUniversityStudentData, loadUniversityStudent, exploreProfessionStudentData, loadProfessionStudent, exploreCoursesStudentData, 
        loadCoursesStudent, exploreActivitiesStudentData, loadActivitiesStudent, loadStudentBySection } = exploreData;

    return { suggestedStudentDetails, suggestedStudentStatus, loadSavedUsers, isUserProfileSaved, 
        getSavedAdvisorStatus, loadSavedUserStatus, genericSearchStudentStatus, exploreUniversityStudentData, loadUniversityStudent, 
        exploreProfessionStudentData, loadProfessionStudent, exploreCoursesStudentData, loadCoursesStudent, exploreActivitiesStudentData, loadActivitiesStudent,
        loadStudentBySection
    }
};

export default connect(mapStateToProps, { getSavedUserProfiles, advisorSaveStatus, setSavedUserProfileStatus, saveUserProfile, unSaveUser, 
    genericSearchStudents, setGenericSearchStudentStatus, resetGenericStudentsData, exploreStudentsByUniversity, setExploreStudentsByUniversityStatus, 
    exploreStudentsByProfession, setExploreStudentsByProfessionStatus, exploreStudentsByCourse, setExploreStudentsByCourseStatus,
    exploreStudentsByActivity, setExploreStudentsByActivityStatus, resetExploreStudentsByUniversity, resetExploreStudentsByProfession, 
    resetExploreStudentsByCourse, resetExploreStudentsByActivity, setExploreStudentsBySectionStatus,
    fetchAllData })(withRouter(Index));