import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import Select, { components } from "react-select";
import Loader from "../../../../components/Loader";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { GetStudentPlan, getConnectedAdvisors, getConnectedAdvisorsParents, ResetPlanAccess, GetStudentPlanId, GetStudentPlanOfParent, setConnectedAdvisorStatus, planAcessAdvisors, bookPaidSession, bookSessionErrorStatus, bookPaidSessionStatus, bookPaidSessionErrorStatus, bookSessionStatus, planAcessAdvisorsStatus, GetCourseList, GetActivityList, clearMessage, planAcessAdvisorsError, addAdvisortoplan, addAdvisortoplanStatus, addAdvisortoplanError, removeAdvisortoplan, removeAdvisortoplanStatus, removeAdvisortoplanError, bookSession, getFreeSessionRemaining, getFreeSessionStatus } from "../../../../redux/actions";
import "./manageadvisor.less"
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import BookSessionPopup from "../../../../componentsNew/Common/BookSession/BookSessionPopup";
import BookSessionPaidPopup from "../../../../pages/Student/MyNetwork/BookSessionModal";
import AdvisorProfile from "../../../../componentsNew/Common/advisorProfile";
import FeedBackPopup from "../../../../componentsNew/Common/FeedBackPopup";
import { useImageUrl } from '../../../../utils/UseImageURL';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import CustomAlert from "../../../../components/CustomAlert";

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [advisorOptions, setAdvisorOptions] = useState([])
    const [confirmation, setConfirmation] = useState(false)
    const [advisorData, setAdvisorData] = useState({})
    const [bookshow, setbookshow] = useState(false);
    const [TimeSession, setTimeSession] = useState([]);
    const [advisorid, setAdvisorid] = useState("");
    const [advisorName, setAdvisorName] = useState("");
    const [profileData, setProfileData] = useState({});
    const [profilePopup, setProfiePopup] = useState(false);
    const [feedBack, setFeedBack] = useState(false);
    const [feedBackData, setFeedBackData] = useState(false);
    const [paidSession, setPaidSession] = useState(null);
    const [selectedAdvisor, setSelectedAdvisor] = useState(null);
    const [avatharImage, setAvatharImage] = useState(null);
    const [advisorTime, setAdvisorTime] = useState();
    const [purchaseSubscriptionFlag, setPurchaseSubscriptionFlag] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        setloading(true)
        // let payload = {
        //     "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
        //     "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
        //     "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
        // }
        // props?.planAcessAdvisors(payload)
        let childId = localStorage.getItem("parentViaChild")

        if (childId) {
            props?.getConnectedAdvisorsParents(childId)
        } else {
            props?.getConnectedAdvisors()
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();

        const fetchData = async () => {
            setloading(true);
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
                "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
            }
            try {
                await props?.planAcessAdvisors(payload, { signal: controller.signal });
            } catch (error) {
                if (error.name === "AbortError") {
                    console.log("Fetch aborted");
                } else {
                    console.error(error);
                }
            }
            setloading(false);
        };

        fetchData();

        return () => {
            controller.abort(); // Cancel ongoing requests on unmount
            props?.ResetPlanAccess(); // Clear data
        };
    }, []);


    const profileShow = (data) => {
        setProfileData(data)
        setProfiePopup(true);
        setPurchaseSubscriptionFlag(true)
    }
    const profileHide = () => {
        setPurchaseSubscriptionFlag(false)
        setProfiePopup(false);
    }

    useEffect(() => {
        if (props?.connectedAdvisorsStatus) {
            setConnectedAdvisorStatus(false)
            setIntialvalue()
        }
        if (props?.addAdvisorPlan) {
            setAdvisorOptions({})
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId);
                setIntialvalue()
            } else {
                props?.getConnectedAdvisors()
                setIntialvalue()
            }
            console.log(advisorOptions, "advisorOptions");
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
                "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
            }
            props?.GetStudentPlanId(props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId)
            props?.planAcessAdvisors(payload)
            props?.addAdvisortoplanStatus(false)
            if (props?.data?.planInfo?.id) {
                let value = { "searchString": "", "pageNo": 1, "limit": 10, "status": [], "sortOrder": "latest", "studentId": props?.userProfile?.currentRole === "parent" ? localStorage.getItem("parentViaChild") : null }
                if (props?.userProfile?.currentRole === "parent") {
                    props?.GetStudentPlanOfParent(value)
                } else {
                    props?.GetStudentPlan(value)
                }
            }
            if (props?.data?.courseInfo?.id) {
                let coursepayload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props?.GetCourseList(coursepayload);
            }
            if (props?.data?.activityInfo?.id) {
                let activitypayload = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props.GetActivityList(activitypayload)
            }
            setTimeout(() => {
                setloading(false)
            }, 4000);
            setSelectedAdvisor(null);

        }
        if (props?.addAdvisorPlanError) {
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId)
                setIntialvalue()
            } else {
                props?.getConnectedAdvisors()
                setIntialvalue()
            }
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
                "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
            }
            props?.planAcessAdvisors(payload)
            if (props?.data?.planInfo?.planName) {
                let value = { "searchString": "", "pageNo": 1, "limit": 20, "status": [], "sortOrder": "latest", "studentId": localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                if (props?.userProfile?.currentRole === "parent") {
                    props?.GetStudentPlanOfParent(value)
                } else {
                    props?.GetStudentPlan(value)
                }
                setloading(true)
            }
            if (props?.data?.courseInfo?.courseName) {
                let coursepayload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props?.GetCourseList(coursepayload);
            }
            if (props?.data?.activityInfo?.activityName) {
                let activitypayload = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props.GetActivityList(activitypayload)
            }
            setTimeout(() => {
                setloading(false)
            }, 4000);
            props?.addAdvisortoplanError(false)
        }
        if (props?.removeAdvisorPlan) {
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId)
                setIntialvalue()
            } else {
                props?.getConnectedAdvisors()
                setIntialvalue()
            }
            handleRemoveAcessclose()
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
                "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
            }
            props?.GetStudentPlanId(props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId)
            props?.planAcessAdvisors(payload)
            props?.removeAdvisortoplanStatus(false)
            if (props?.data?.planInfo?.planName) {
                let value = { "searchString": "", "pageNo": 1, "limit": 20, "status": [], "sortOrder": "latest", "studentId": localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                if (props?.userProfile?.currentRole === "parent") {
                    props?.GetStudentPlanOfParent(value)
                } else {
                    props?.GetStudentPlan(value)
                }
            }
            if (props?.data?.courseInfo?.courseName) {
                let coursepayload = { searchString: '', pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props?.GetCourseList(coursepayload);
            }
            if (props?.data?.activityInfo?.activityName) {
                let activitypayload = { searchString: "", pageNo: 1, limit: 40, planIds: [], studentId: localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : null }
                props.GetActivityList(activitypayload)
            }
            setTimeout(() => {
                setloading(false)
            }, 4000);
        }
        if (props?.removeAdvisorPlanError) {
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId)
                setIntialvalue()
            } else {
                props?.getConnectedAdvisors()
                setIntialvalue()
            }
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "courseId": props?.data?.courseName ? props?.data?.id : props?.data?.courseInfo?.id,
                "activityId": props?.data?.activityName ? props?.data?.id : props?.data?.activityInfo?.id,
            }
            props?.planAcessAdvisors(payload)
            props?.removeAdvisortoplanError(false)
            setTimeout(() => {
                setloading(false)
            }, 4000);
        }
        if (props?.accessbleAdvisor) {
            const advisorId = props?.accessbleAdvisor?.map(item => item?.userProfile?.id);
            const studentId = props?.userProfile?.currentRole === "parent" ? localStorage.getItem("parentViaChild") : props?.userProfile?.id
            let payload =
            {
                "advisorIds": advisorId,
                studentId: studentId
            }

            if (props?.accessbleAdvisor?.length > 0) {
                props?.getFreeSessionStatus(payload)
            }
        }

    }, [props?.connectedAdvisors, props?.accessbleAdvisor, props?.connectedAdvisorsStatus, props?.addAdvisorPlan, props?.addAdvisorPlanError, props?.removeAdvisorPlan, props?.removeAdvisorPlanError])

    const setIntialvalue = () => {
        console.log(props?.accessbleAdvisor, "props?.accessbleAdvisor")
        if (props?.connectedAdvisors?.length > 0) {
            const accessibleAdvisorFullNames = props?.accessbleAdvisor?.map(
                (advisor) => advisor?.userProfile?.fullName
            );
            console.log(accessibleAdvisorFullNames, "accessibleAdvisorFullNames")

            const filteredAdvisors = props?.connectedAdvisors?.filter(
                (advisor) => !accessibleAdvisorFullNames?.includes(advisor?.userInfo?.fullName)
            );
            console.log(filteredAdvisors, "filteredAdvisors")
            const options = filteredAdvisors?.filter((advisor) => !props?.globalAdvisor?.some((globalAdv) => globalAdv?.userId === advisor?.userInfo?.id))
                ?.map((advisor) => ({
                    label: advisor?.userInfo?.fullName,
                    value: advisor?.userInfo?.id,
                }));
            setAdvisorOptions(options);
            setTimeout(() => {
                setloading(false)
            }, 4000);
        }
        else {
            setTimeout(() => {
                setloading(false)
            }, 4000);
        }
    }

    const handleAdvisorChange = (selectedOption) => {
        console.log(selectedOption, "selectedOption");

        setSelectedAdvisor(selectedOption)
        let AdvisorId = selectedOption?.value
        if (props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId && AdvisorId) {
            if ((props?.text === "Course" && props?.data?.courseInfo) || (props?.text === "Activity" && props?.data?.activityInfo)) {
                setloading(true)
                let payload = {
                    "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                    "activityIds": props?.data?.activityInfo ? [props?.data?.activityInfo?.id] : [],
                    "courseIds": props?.data?.courseInfo?.id ? [props?.data?.courseInfo?.id] : [],
                    "advisorId": AdvisorId,
                };
                if (props?.userProfile?.currentRole === "parent") {
                    payload["studentId"] = localStorage.getItem("parentViaChild");
                }

                props?.addAdvisortoplan(payload);
            }
            else if ((props?.text === "Course" && props?.data?.courseName) || (props?.text === "Activity" && props?.data?.activityName)) {
                setloading(true)
                let payload = {
                    "planId": props?.data?.planId,
                    "activityIds": (props?.text === "Activity" && props?.data?.id) ? [props?.data?.id] : [],
                    "courseIds": (props?.text === "Course" && props?.data?.id) ? [props?.data?.id] : [],
                    "advisorId": AdvisorId,
                };
                if (props?.userProfile?.currentRole === "parent") {
                    payload["studentId"] = localStorage.getItem("parentViaChild");
                }

                props?.addAdvisortoplan(payload);
            }
            else {
                setloading(true)
                let payload = {
                    "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                    "activityIds": [],
                    "courseIds": [],
                    "advisorId": AdvisorId,
                }
                if (props?.userProfile?.currentRole === "parent") {
                    payload["studentId"] = localStorage.getItem("parentViaChild");
                }
                props?.addAdvisortoplan(payload)
            }

        }
    };

    const handleRemoveAcess = (e, data) => {
        e.preventDefault();
        setAdvisorData(data)
        setConfirmation(true)
    }

    const handleRemoveAcessclose = () => {
        setConfirmation(false)
        setAdvisorData({})
    }

    const handleRemoveSubmit = () => {
        let AdvisorId = advisorData?.userProfile?.id
        if ((props?.text === "Course" && props?.data?.courseName || props?.data?.courseInfo) || (props?.text === "Activity" && props?.data?.activityName || props?.data?.activityInfo)) {
            setloading(true)
            let payload = {
                planId: props?.data?.planInfo?.id || props?.data?.planId,
                advisorId: AdvisorId,
                ...(props?.text === "Activity" && (props?.data?.activityName || props?.data?.activityInfo) && {
                    activityIds: props?.data?.activityInfo ? [props?.data?.activityInfo?.id] : props?.data?.id ? [props?.data?.id] : [],
                    courseIds: []
                }),
                ...(props?.text === "Course" && (props?.data?.courseName || props?.data?.courseInfo) && {
                    courseIds: props?.data?.courseInfo ? [props?.data?.courseInfo?.id] : props?.data?.id ? [props?.data?.id] : [],
                    activityIds: [],
                }),
            };
            if (props?.userProfile?.currentRole === "parent") {
                payload["studentId"] = localStorage.getItem("parentViaChild");
            }
            props?.removeAdvisortoplan(payload)
        }
        else {
            setloading(true)
            let payload = {
                "planId": props?.data?.planInfo?.id ? props?.data?.planInfo?.id : props?.data?.planId,
                "activityIds": [],
                "courseIds": [],
                "advisorId": AdvisorId,
            }
            if (props?.userProfile?.currentRole === "parent") {
                payload["studentId"] = localStorage.getItem("parentViaChild");
            }
            props?.removeAdvisortoplan(payload)
        }
    }

    const OpenBookSession = (e, data, value) => {
        props?.clearMessage()
        e.preventDefault()
        setAdvisorid(data?.userProfile?.id)
        if (value === "Paid") {
            setPaidSession(true ?? null)
        }
        const matchingAdvisor = props?.connectedAdvisors?.find(advisor => advisor.userInfo?.id === data?.userProfile?.id);
        setAdvisorName({ label: data?.userProfile?.fullName, value: data?.userProfile?.id })
        setAdvisorTime([data?.userProfile?.advisorProfile?.advisorWorkingHours])

        if (matchingAdvisor) {
            setTimeSession(matchingAdvisor)
            setbookshow(true)
        }
    }

    const CloseBookSession = () => {
        props?.clearMessage()
        setPaidSession(null)
        setbookshow(false)
    }

    useEffect(() => {
        if (props?.sessionBooked) {
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
        if (props?.booksessionerror) {
            CloseBookSession()
            props?.bookSessionErrorStatus(false)
        }
        if (props?.paidSessionBooked) {
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
        }
        if (props?.bookPaidSessionError) {
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
        }

    }, [props?.sessionBooked, props?.booksessionerror, props?.paidSessionBooked, props?.bookPaidSessionError])

    const SessionBook = (data, value) => {
        const moment = require('moment');
        const SelectHours = value
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        const startTime = SelectHours.split(' - ')[0];
        const formattedDate = moment(`${selectedDate} ${startTime}`, "YYYY-MM-DD HH:mm A").format("YYYY-MM-DD HH:mm:ss");
        const [startTimes, endTime] = SelectHours.split(' - ');
        const parsedStartTime = moment(startTimes, 'h:mm A');
        const parsedEndTime = moment(endTime, 'h:mm A');
        const timeDiffInMillis = parsedEndTime.diff(parsedStartTime);
        const timeDiffInMinutes = moment.duration(timeDiffInMillis).asMinutes();

        let payload = {
            "advisorId": advisorid,
            "eventTime": formattedDate,
            "eventDuration": timeDiffInMinutes,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "studentId": localStorage.getItem("parentViaChild"),
            "password": data?.password
        }
        if (data?.package) {
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            props?.bookSession(payload)

        }
    }
    const showFeedBack = (e, data) => {
        setFeedBackData(data?.userProfile)
        e.preventDefault();
        setFeedBack(true)
    }

    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        if (imageUrl) {
            return <img src={imageUrl} className="userprofile" loading="lazy" />;
        }
    };
    const handleExploreAdvisor = (e) => {
        e.preventDefault();
        if (localStorage?.getItem("parentViaChild")) {
            navigate(`/parent/mynetwork/exploreAdvisor`)
        } else {
            navigate(`/student/mynetwork/exploreAdvisor`)
        }
    }

    console.log(advisorOptions, "advisorOptions")

    return (
        <>
            {bookshow && <BookSessionPopup show={bookshow} userTime={advisorTime} paidSession={paidSession} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />}
            {profilePopup && <AdvisorProfile show={profilePopup} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} purchaseSubscriptionFlag={purchaseSubscriptionFlag} />}
            <FeedBackPopup size={"lg"} dialogClassName="" fullscreen={true} centered={false} showFeedBack={feedBack} advisorDetail={feedBackData} onHide={() => setFeedBack(false)} />
            {confirmation && <ConfirmationPopup show={confirmation} handleSubmit={(e) => handleRemoveSubmit(e)} message={`${advisorData?.userProfile?.fullName} will no longer be able to access your work for this ${props?.data?.courseInfo?.courseName || props?.data?.activityInfo?.activityName || props?.data?.planInfo?.planName || props?.data?.courseName || props?.data?.activityName || props?.data?.planName}`} btnText="confirm" onHide={() => handleRemoveAcessclose()} />}
            <Modal {...props} size={'lg'} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Advisors for {props?.data?.courseInfo?.courseName || props?.data?.activityInfo?.activityName || props?.data?.planInfo?.planName || props?.data?.courseName || props?.data?.activityName || props?.data?.planName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && <Loader />}
                    <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
                    <div>
                        <div className="addConnectionText" >
                            <div className="AccesibleAdvisorList">
                                {props?.accessbleAdvisor?.length > 0 && props?.accessbleAdvisor?.filter(data => props?.text === "plan" ? data?.planAccess?.accessLevel === "global" : data)?.map((data, index) => {
                                    let freeSessionDetails = props?.freeSessionStatus?.length > 0 && props?.freeSessionStatus?.filter(
                                        (freeSessionData) => freeSessionData.advisorId === data?.userProfile?.id
                                    );
                                    let subscriptionData = props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.filter(
                                        (subscribeData) => subscribeData.userInfo?.id === data?.userProfile?.id);
                                    console.log(freeSessionDetails[0]?.freeSessionCompleted, "subscriptionData", subscriptionData)
                                    return (
                                        <>
                                            {!loading &&
                                                <div className="AccesibleAdvisorList__card" key={index}>
                                                    <div className="AccesibleAdvisorList__card__Usersection">
                                                        {data?.userProfile?.avatarPath ? (
                                                            <AvatarWithUrl avatarUrl={data?.userProfile?.avatarPath} />
                                                        ) : (
                                                            <AccountBoxIcon sx={{ fill: 'grey' }} className="userprofile" />
                                                        )}

                                                        <div className="username-container">
                                                            <p className="username">
                                                                <Tooltip title={data?.userProfile?.fullName} arrow placement="top">
                                                                    <span className="username-text" title={data?.userProfile?.fullName}>{data?.userProfile?.fullName}</span>
                                                                </Tooltip>
                                                                <span className="icon">
                                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="9.5" cy="9.5" r="9.5" fill="#F4F5F8" />
                                                                        <path d="M14 5H6C5.45 5 5 5.45 5 6V15L7 13H14C14.55 13 15 12.55 15 12V6C15 5.45 14.55 5 14 5ZM14 12H7L6 13V6H14V12Z" fill="#6B6C6D" />
                                                                    </svg>
                                                                </span>
                                                            </p>
                                                            <div>
                                                                <p>
                                                                    {freeSessionDetails && freeSessionDetails[0]?.freeSessionCompleted ?
                                                                        ((subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions === undefined ||
                                                                            subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions === 0 ||
                                                                            subscriptionData[0]?.subscriptiondetails[0]?.noOfPaidSessions === undefined ||
                                                                            subscriptionData[0]?.subscriptiondetails[0]?.noOfPaidSessions === 0) ?
                                                                            "" :
                                                                            `${Math.abs((subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions - subscriptionData[0]?.subscriptiondetails[0]?.noOfPaidSessions) + 1) ?? 0} of ${subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions ?? 0} session used`
                                                                        ) :
                                                                        `${freeSessionDetails[0]?.remainingCount ?? 0} of ${data?.userProfile?.advisorProfile?.noOfFreeConsultation ?? 0} session used`
                                                                    }

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="AccesibleAdvisorList__card__Btnsection">
                                                        <>
                                                            {subscriptionData && subscriptionData[0]?.subscriptiondetails?.length > 0 ? (
                                                                <>
                                                                    {subscriptionData[0]?.subscriptiondetails[0]?.noOfPaidSessions === 0 ? (
                                                                        <Button className="primary_btn" onClick={(e) => showFeedBack(e, data)} >Give feedback</Button>

                                                                    ) : (
                                                                        <>
                                                                            <Button className="primary_btn" onClick={(e) => OpenBookSession(e, data, "Paid")}>
                                                                                Book Session {Math.abs((subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions - subscriptionData[0]?.subscriptiondetails[0]?.noOfPaidSessions) + 1)} of {subscriptionData[0]?.subscriptiondetails[0]?.availablePaidSessions}
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) :

                                                                freeSessionDetails && freeSessionDetails[0]?.freeSessionCompleted && (
                                                                    <Button className="primary_btn" onClick={(e) => profileShow(subscriptionData[0])}>Purchase Subscription</Button>
                                                                )}
                                                        </>
                                                        {subscriptionData && subscriptionData[0]?.subscriptiondetails?.length === 0 && freeSessionDetails && freeSessionDetails[0]?.remainingCount !== 0 &&
                                                            <Button className="primary_btn" onClick={(e) => OpenBookSession(e, data)}>
                                                                Book Free Session
                                                            </Button>
                                                        }
                                                        <Button className="secondary_btn" onClick={(e) => handleRemoveAcess(e, data)}>Remove Access</Button>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div>
                            {console.log("advisorOptions", advisorOptions)}
                            {console.log("props?.accessbleAdvisor", props?.accessbleAdvisor)}
                            <div className="addConnectionText" style={{ "display": "flex" }}>
                                <div className="input-floating-label">
                                    <Select
                                        options={
                                            (advisorOptions || []).filter(
                                                (option) =>
                                                    !props?.accessbleAdvisor?.some(
                                                        (accessible) => accessible?.userProfile?.id === option?.value
                                                    )
                                            )
                                        }
                                        onChange={handleAdvisorChange}
                                        value={selectedAdvisor}
                                        placeholder={"Add Connected Advisors"}
                                        closeMenuOnSelect={true}
                                        isClearable={false}
                                        isSearchable={false}
                                        classNamePrefix="mySelect"
                                        components={{
                                            ValueContainer: CustomValueContainer,
                                            IndicatorSeparator: () => null,
                                        }}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                cursor: 'pointer',
                                            }),
                                            container: (provided, state) => ({
                                                ...provided,
                                                height: '48px',
                                                overflow: "visible",
                                            }),
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: "visible",
                                                height: '100%',
                                                minHeight: '48px',
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                top: state.hasValue || state.selectProps.inputValue ? -13 : "30%",
                                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                                                background: '#fff',
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block',
                                            }),
                                        }}
                                    />
                                </div>

                            </div>
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "marginBottom": "30px" }}>
                                <div className="myWork-planCard__ctaSection__flat-btn" onClick={(e) => handleExploreAdvisor(e)}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11" cy="11" r="6" stroke="#222222" />
                                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                        </svg>
                                    </span>
                                    Explore Advisors
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { connectedAdvisors, connectedAdvisorsStatus, sessionBooked, freeSessionStatus, detailedAdvisorData, booksessionerror, paidSessionBooked, bookPaidSessionError } = commonData
    const { userProfile } = userProfileData
    const { accessbleAdvisor, accessbleAdvisorStatus, accessbleAdvisorError, addAdvisorPlanError, addAdvisorPlan, removeAdvisorPlan, removeAdvisortoPlanError } = studentData;
    return { connectedAdvisors, connectedAdvisorsStatus, booksessionerror, accessbleAdvisor, accessbleAdvisorStatus, accessbleAdvisorError, addAdvisorPlanError, addAdvisorPlan, removeAdvisorPlan, removeAdvisortoPlanError, sessionBooked, userProfile, freeSessionStatus, detailedAdvisorData, paidSessionBooked, bookPaidSessionError }
};

export default connect(mapStateToProps, { GetStudentPlan, GetStudentPlanOfParent, ResetPlanAccess, getConnectedAdvisors, GetStudentPlanId, getConnectedAdvisorsParents, clearMessage, bookPaidSession, bookSessionStatus, setConnectedAdvisorStatus, planAcessAdvisors, planAcessAdvisorsStatus, planAcessAdvisorsError, addAdvisortoplan, addAdvisortoplanStatus, addAdvisortoplanError, removeAdvisortoplan, removeAdvisortoplanStatus, removeAdvisortoplanError, bookSession, getFreeSessionRemaining, getFreeSessionStatus, GetCourseList, GetActivityList, bookSessionErrorStatus, bookPaidSessionStatus, bookPaidSessionErrorStatus, })(Index);

